import defineComponent from '../../../components/flight/lib/component';
import Utils from "../../../components/flight/lib/utils";
import axios from "axios";
export default defineComponent(Header);

function Header() {

    this.attributes({
        searchUrl: '/search',
        productCategoryMenuItemSelector: '[data-type="product-category-menu-item"]',
        megaMenuSelector: '[data-type="mega-menu"]',
        megaMenuCategoryListSelector: '[data-type="mega-menu"] [data-type="category-list"]',
        megaMenuCategoryTabsSelector: '[data-type="mega-menu"] [data-type="product-list"]',
        allProductsButtonSelector: '[data-type="all-products-btn"]',
        productCategoriesMenuSelector: '[data-type="product-categories-menu"]',
        searchBarSelectorSelector: '[data-type="search-bar"]',
        searchBarContainerSelector: '[data-type="product-search-bar"]',
        searchResultsContainerSelector: '[data-type="search-results"]',
        mobileSearchButtonSelector: '[data-type="mobile-search-btn"]',
        mobileHeaderContainerSelector: '[data-type="mobile-header"]',
        mobileSearchBtnCloseSelector:'[data-type="mobile-search-btn-close"]',
        mobileMenuSelectorSelector: '[data-type="mobile-menu"]',
        searchResultsContainerSelector2: '[data-type="search-results-container"]',
        searchBackgroundSelector: '[data-type="search-background-dark"]',
        searchClearSearchButtonSelector: '[data-type="search-bar-clear-button"]',
        searchSearchButtonSelector: '[data-type="search-bar-search-button"]'
    });

    this.onMenuButtonOrProductCategoryMenuItemClick = function(event) {
        const menuItem = $(event.target);
        const targetCategory = menuItem.data('target-category') === undefined ? 'all-products' : menuItem.data('target-category');
        const currentCategory = this.select('megaMenuCategoryListSelector').find('li.active').data('category');
        const allProductsCategory = this.select('allProductsButtonSelector').data('target-category');

        if (this.isMegaMenuOpen && (targetCategory === currentCategory || targetCategory === allProductsCategory)) {
            this.hideMegaMenu();
        } else {
            this.showMegaMenu(targetCategory);
        }
    };

    this.showMegaMenu = function(targetCategory) {
        const header = this;
        if(!this.isMegaMenuOpen) {
            this.select('megaMenuSelector').collapse('show');
            this.select('allProductsButtonSelector').find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }

        this.select('megaMenuCategoryListSelector').find('li').removeClass('active');
        this.select('megaMenuCategoryListSelector').find(`li[data-category="${targetCategory}"]`).addClass('active');

        this.select('megaMenuCategoryTabsSelector').find('.tab-pane').removeClass('active');
        this.select('megaMenuCategoryTabsSelector').find(`.tab-pane[data-category-tab="${targetCategory}"]`).addClass('active');

        // Listener For Clicking Outside The Menu To Close It
        $(document).one('click', function closeMenu (e){
            if(header.select('megaMenuSelector').has(e.target).length === 0 && header.select('productCategoriesMenuSelector').has(e.target).length === 0 && !header.select('allProductsButtonSelector').is(e.target)) {
                header.hideMegaMenu();
            } else {
                $(document).one('click', closeMenu);
            }
        });
        this.isMegaMenuOpen = true;
    };

    this.hideMegaMenu = function() {
        if(this.isMegaMenuOpen) {
            this.select('megaMenuSelector').collapse('hide');
            this.select('allProductsButtonSelector').find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
        this.isMegaMenuOpen = false;
    };

    this.search = function () {
        const header = this;
        const searchQuery = (this.select('searchBarSelectorSelector').val() || "").trim();
        if (searchQuery && searchQuery.length >= 3) {
            axios.post(`${this.attr.searchUrl}`,  null, {
                params: {
                    searchQuery: searchQuery
                },
            })
            .then(response => {
                header.showSearchResults(response.data);
            })
            .catch(error => {

            });
        } else {
            header.hideSearchResults();
        }
    };

    this.showSearchResults = function (searchResults) {
        const header = this;
        const formattedResults = JSON.stringify(searchResults).replace(/'/g, "&#39;");
        const searchResultsList = searchResults.length === 0 ? `<h5>No Results Found</h5>` : `<search-results data-type="search-results" updatesearchresults='${formattedResults}'></search-results>`;
        this.select('searchResultsContainerSelector2').html(searchResultsList);
        this.select('searchResultsContainerSelector').removeClass('hidden');
        this.select('searchResultsContainerSelector').collapse('show');
        this.select('searchBackgroundSelector').removeClass('hidden');
        this.select('searchClearSearchButtonSelector').removeClass('hidden');
        this.select('searchSearchButtonSelector').addClass('hidden');

        function delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        }

        delay(350).then(() => {
            this.waitForElm('[data-type="search-results"]').then(() => {
                this.enableKeyboardNavigation();
            });
        });

        this.select('searchClearSearchButtonSelector').one('click', function clearSearchInput (e) {
            header.select('searchBarSelectorSelector').val(null);
            header.hideSearchResults();
        });

        $(document).one('click', function closeSearchResults (e){
            if(header.select('searchResultsContainerSelector').has(e.target).length === 0) {
                header.select('searchBarSelectorSelector').val(null);
                header.hideSearchResults();
            } else {
                $(document).one('click', closeSearchResults);
            }
        });
    };

    this.waitForElm = function (selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector).classList.contains("show"));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector).classList.contains("show")) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    };

    this.hideSearchResults = function () {
        this.select('searchResultsContainerSelector').collapse('hide');
        this.select('searchResultsContainerSelector').addClass('hidden');
        this.select('searchBackgroundSelector').addClass('hidden');
        this.select('searchSearchButtonSelector').removeClass('hidden');
        this.select('searchClearSearchButtonSelector').addClass('hidden');
        this.disableKeyboardNavigation();
    };

    this.showMobileSearch = function () {
        this.select('mobileHeaderContainerSelector').hide();
        this.select('searchBarContainerSelector').addClass('in');
    };

    this.closeMobileSearch = function () {
        this.select('mobileHeaderContainerSelector').show();
        this.select('searchBarContainerSelector').removeClass('in');
    };

    this.onMobileSearchBtnClick = function () {
        this.showMobileSearch();
    };

    this.initOverlayTrigger = function () {
        const mobileMenu = this.select('mobileMenuSelectorSelector');
        mobileMenu.on('shown.bs.collapse', function (e) {
            if(e.target.getAttribute('data-type') === 'mobile-menu') {
                document.body.classList.add('overflow-hidden');
            }
        });

        mobileMenu.on('hide.bs.collapse', function (e) {
            if(e.target.getAttribute('data-type') === 'mobile-menu') {
                document.body.classList.remove('overflow-hidden');
            }
        });
    };

    this.enableKeyboardNavigation = function () {
        let active = -1;

        document.addEventListener('keydown', function (e) {
            if (!document.querySelector("search-results")) {
                return;
            }
            const $searchResultsContainer = document.querySelector("search-results");
            const searchModuleRoot = $searchResultsContainer.shadowRoot;
            const searchResultsList = searchModuleRoot.querySelector('[data-type="search-results-list"]');

            const $SearchResultsUl = $(searchResultsList);
            const $allLinks = $SearchResultsUl.find('a');
            if ($allLinks != null) {
                if(e.keyCode === 40) {
                    e.preventDefault();

                    if(active < $allLinks.length - 1) {
                        active++;
                        $allLinks[active].focus();
                    }
                } else if(e.keyCode === 38) {
                    e.preventDefault();
                    if(active > 0) {
                        active--;
                        $allLinks[active].focus();
                    }
                }
            }
        } );
    };

    this.disableKeyboardNavigation = function () {
        if (!document.querySelector("search-results")) {
            return;
        }
        const $searchResultsContainer = document.querySelector("search-results");
        const searchModuleRoot = $searchResultsContainer.shadowRoot;
        const searchResultsList = searchModuleRoot.querySelector('[data-type="search-results-list"]');

        const $SearchResultsUl = $(searchResultsList);
        const $allLinks = $SearchResultsUl.find('a');

        let active = -1;
        document.removeEventListener('keydown', function (e) {
            if ($allLinks != null) {
                if(e.keyCode === 40) {
                    e.preventDefault();
                    if(active < $allLinks.length - 1) {
                        active++;
                        $allLinks[active].focus();
                    }
                } else if(e.keyCode === 38) {
                    e.preventDefault();
                    if(active > 0) {
                        active--;
                        $allLinks[active].focus();
                    }
                }
            }
        } );
    };

    this.after('initialize', function () {
        this.isMegaMenuOpen = false;
        this.on("click", {
            productCategoryMenuItemSelector: this.onMenuButtonOrProductCategoryMenuItemClick,
            allProductsButtonSelector: this.onMenuButtonOrProductCategoryMenuItemClick,
            searchBarSelectorSelector: this.search,
            mobileSearchButtonSelector: this.onMobileSearchBtnClick,
            mobileSearchBtnCloseSelector: this.closeMobileSearch
        });
        this.on('keyup', {
            searchBarSelectorSelector: Utils.debounce((e) => {
                if (!(e.key === "ArrowUp" || e.key === "ArrowDown")) {
                    this.search();
                }
            }, 200)
        });
        this.initOverlayTrigger();
    });
}
