import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default function WithPublicationSchema() {

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: CELL_TYPES.THUMBNAIL,
            data:"front"
        });
        cols.push({
            title: "Publication Title",
            type: "text",
            data:"publicationTitle",
            width: "20rem",
        });
        cols.push({
            title: "Publication Author",
            type: "text",
            data:"publicationAuthors",
            width: "10rem",
        });
        cols.push({
            title: "Groups",
            type: "list",
            data: "customerGroupsList",
            hide: Breakpoints.LG,
            width: '150px'
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"href",
            width: "5rem",
        });
        cols.push({
            title: "Username",
            type: CELL_TYPES.USER,
            data:"user",
            width: "10rem",
            hide: Breakpoints.SM,
        });
        cols.push({
            title: "Modified",
            type: CELL_TYPES.TIMEBOX,
            data:"lastModifiedDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Status",
            type: CELL_TYPES.BADGE,
            data:"statusBadge",
            width: "150px",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Confirmed",
            type: CELL_TYPES.TIMEBOX,
            data:"confirmDate",
            hide: Breakpoints.MD,
        });
        cols.push({
            title: "Product",
            type: "text",
            data:"product",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Markets",
            type: "list",
            data:"verticalMarkets",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Groups",
            type: "list",
            data:"customerGroups",
            width: "10rem",
            hide: Breakpoints.LG,
        });
        cols.push({
            title: "Size",
            type: "text",
            data: "size",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sides",
            type: "number",
            decimal: 0,
            data: "sides",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Pages",
            type: "number",
            data: "calcedPages",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Orientation",
            type: "icon",
            data: "orientation",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Bound",
            type: "icon",
            data: "bound",
            hide: Breakpoints.XXL
        });
        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Sewn",
            type: "boolean",
            data: "isSewn",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Foiled",
            type: "boolean",
            data: "isFoiled",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "Dust Jacket",
            type: "boolean",
            data: "hasDustJacket",
            hide: Breakpoints.XXL,
        });
        cols.push({
            title: "",
            type: CELL_TYPES.BUTTON,
            data: "publicationViewAction",
            width: '175px'
        });

        return cols;
    };

}
