var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { LabourCosts } from "./LabourCosts";
var CostModel;
(function (CostModel) {
    CostModel["SIMPLE"] = "SIMPLE";
    CostModel["DETAILED"] = "DETAILED";
})(CostModel || (CostModel = {}));
export var EditEquipment = function (_a) {
    var equipment = _a.equipment, setEquipment = _a.setEquipment;
    var costModel = equipment.hourlyCost || (!equipment.purchaseCost && !equipment.usefulLifeMonths && !equipment.monthlyRuntimeHours && !equipment.maintenanceRatio)
        ? CostModel.SIMPLE
        : CostModel.DETAILED;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "p-2 d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
            React.createElement("div", { className: "mb-4" },
                React.createElement("label", { htmlFor: "name", className: "form-label" }, "Name"),
                React.createElement("input", { required: true, id: "name", name: "name", type: "text", className: "form-control", value: equipment.name, onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { name: event.target.value })); } })),
            React.createElement("div", { className: "mb-4" },
                React.createElement("label", { htmlFor: "active", className: "form-label" }, "Active"),
                React.createElement("select", { id: "active", name: "active", className: "form-select", defaultValue: equipment.active.toString(), onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { active: event.target.value === 'true' })); } },
                    React.createElement("option", { value: "true" }, "YES"),
                    React.createElement("option", { value: "false" }, "NO"))),
            React.createElement("div", { className: "mb-4" },
                React.createElement("label", { htmlFor: "makeReadyTimeMinutes", className: "form-label" }, "Make Ready Time (minutes)"),
                React.createElement("input", { id: "makeReadyTimeMinutes", name: "makeReadyTimeMinutes", type: "number", className: "form-control", value: equipment.makeReadyTimeMinutes, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { makeReadyTimeMinutes: Number(event.target.value) })); } }))),
        React.createElement("h5", null, "Cost Per Hour: "),
        React.createElement("div", { className: "accordion mb-4", id: "costPerHour" },
            React.createElement("div", { className: "accordion-item" },
                React.createElement("h2", { className: "accordion-header" },
                    React.createElement("button", { className: "accordion-button ".concat(costModel === CostModel.SIMPLE ? '' : 'collapsed'), type: "button", "data-bs-toggle": "collapse", "data-bs-target": "#collapseOne", "aria-expanded": "".concat(costModel === CostModel.SIMPLE ? 'true' : 'false'), "aria-controls": "collapseOne", onClick: function () { return setEquipment(__assign(__assign({}, equipment), { hourlyCost: 0.0 })); } }, "Simple Formula")),
                React.createElement("div", { id: "collapseOne", className: "accordion-collapse collapse ".concat(costModel === CostModel.SIMPLE ? 'show' : ''), "data-bs-parent": "#costPerHour" },
                    React.createElement("div", { className: "accordion-body" },
                        React.createElement("p", null, "With the Simple option, you specify an Hourly Cost directly."),
                        React.createElement("div", { className: "d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
                            React.createElement("div", { className: "mb-4" },
                                React.createElement("label", { htmlFor: "hourlyCost", className: "form-label" }, "Hourly Cost"),
                                React.createElement("input", { id: "hourlyCost", name: "hourlyCost", type: "number", className: "form-control", value: equipment.hourlyCost || 0.00, step: "0.01", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { hourlyCost: Number(event.target.value) })); } })))))),
            React.createElement("div", { className: "accordion-item" },
                React.createElement("h2", { className: "accordion-header" },
                    React.createElement("button", { className: "accordion-button ".concat(costModel === CostModel.SIMPLE ? 'collapsed' : ''), type: "button", "data-bs-toggle": "collapse", "data-bs-target": "#collapseTwo", "aria-expanded": "".concat(costModel === CostModel.SIMPLE ? 'false' : 'true'), "aria-controls": "collapseTwo", onClick: function () { return setEquipment(__assign(__assign({}, equipment), { hourlyCost: undefined })); } }, "Detailed Formula")),
                React.createElement("div", { id: "collapseTwo", className: "accordion-collapse collapse ".concat(costModel === CostModel.SIMPLE ? '' : 'show'), "data-bs-parent": "#costPerHour" },
                    React.createElement("div", { className: "accordion-body" },
                        React.createElement("p", null, "With the Detailed option, we will calculate Hourly Cost based on the following elements."),
                        React.createElement("div", { className: "d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
                            React.createElement("div", { className: "mb-4" },
                                React.createElement("label", { htmlFor: "purchaseCost", className: "form-label" }, "Purchase Cost"),
                                React.createElement("input", { id: "purchaseCost", name: "purchaseCost", type: "number", className: "form-control", value: equipment.purchaseCost, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { purchaseCost: Number(event.target.value) })); } })),
                            React.createElement("div", { className: "mb-4" },
                                React.createElement("label", { htmlFor: "usefulLifeMonths", className: "form-label" }, "Useful Life (months)"),
                                React.createElement("input", { id: "usefulLifeMonths", name: "usefulLifeMonths", type: "number", className: "form-control", value: equipment.usefulLifeMonths, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { usefulLifeMonths: Number(event.target.value) })); } })),
                            React.createElement("div", { className: "mb-4" },
                                React.createElement("label", { htmlFor: "monthlyRuntimeHours", className: "form-label" }, "Monthly Runtime (hours)"),
                                React.createElement("input", { id: "monthlyRuntimeHours", name: "monthlyRuntimeHours", type: "number", className: "form-control", value: equipment.monthlyRuntimeHours, step: "1", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { monthlyRuntimeHours: Number(event.target.value) })); } })),
                            React.createElement("div", { className: "mb-4" },
                                React.createElement("label", { htmlFor: "maintenanceRatio", className: "form-label" }, "Maintenance Ratio"),
                                React.createElement("input", { id: "maintenanceRatio", name: "maintenanceRatio", type: "number", className: "form-control", value: equipment.maintenanceRatio, step: "0.0001", onChange: function (event) { return setEquipment(__assign(__assign({}, equipment), { maintenanceRatio: Number(event.target.value) })); } }))),
                        React.createElement(LabourCosts, { equipment: equipment, setEquipment: setEquipment })))))));
};
