import mixam from '../boot/mixam';
import regional from '../boot/regional';
import labelTypes from "./constants/label-types";
import moment from 'moment/moment';
export default WithNormalizeDispatch;

function WithNormalizeDispatch() {

    this.normalizeDispatch = function (data) {
        var items = this.normalizeItems(data.order.items),
            result = {};

        result.order = data.order;
        result.delivery = data.delivery;

        if (data.member) {
            result.order.member = data.member;
        }

        if (result.order.member) {
            result.order.member = this.normalizeMember(result.order.member);
        }

        items.forEach((item, i) => {
            this.item = item;
            item.itemId = item.id;
            item.orderUrl = `${mixam.applicationDomain}orders`;
            item.nmap = this.normalizeMap(data.order, item);
            item.nmap.pages.length = Math.min(item.nmap.pages.length, 1);
            if (i < 3) {
                item.PRNIT_IN_CAPTION = i + 1;
            }
        });

        result.order.items = items;
        result.order.itemsCount = Math.min(items.length, 3);
        result.order.HAS_MULTIPLE_DELIVERIES = result.order.deliveries.length > 1; // TODO: [MX-511] update!
        result.newShipment = data.shipment;
        result.newShipment.request.collectionDate = moment().format('YYYY-MM-DD');
        if (data.order.isPlainPackaging) {
            result.PLAIN_PACKAGING = true;
        }

        result.suppliers = Object.keys(data.suppliers).sort((a, b) => data.suppliers[a].supplier.name.localeCompare(data.suppliers[b].supplier.name)).map(supplierId => {
            let supplier = data.suppliers[supplierId],
                shipments = data.order.shipments.filter(shipment => shipment.supplierId === supplierId).sort((a, b) => a.createDate - b.createDate).map(shipment => {
                    if(shipment.response) {
                        shipment.response.labels.forEach(label => label.displayName = labelTypes[label.type] || `Label ${label.type || 'LABEL'}`);
                    }
                    return {
                        id: shipment.response && shipment.response.data.shipmentId || "-1",
                        date: mixam.dateToDateString(shipment.createDate),
                        items: this.getShipmentItems(shipment, items),
                        labelHtmlUrl: shipment.response && shipment.response.labelHtmlUrl,
                        htmlLabel: shipment.response && shipment.response.labels[0],
                        labels: shipment.response && shipment.response.labels.slice(1),
                        trackingUrl: shipment.response && shipment.response.trackingUrl
                    };
                }),
                centers = this.getCollectionCenters(supplier.supplier),
                availableItems = this.getAvailableItems(supplier, data, items),
                oSupplier = {
                    id: supplier.supplier.id,
                    name: supplier.supplier.name,
                    email: supplier.supplier.email,
                    logo: supplier.supplier.logo,
                    centers: centers,
                    HAS_CENTERS: centers && centers.length,
                    logoNeg: (supplier.supplier.logo || "").replace(/suppliers\/(.+?)\.jpg$/, "suppliers/n-$1.jpg"),
                    shipments: shipments,
                    hasShipments: shipments.length ? true : null,
                    hasAvailableItems: availableItems.items.length ? true : null,
                    singleAvailableItem: availableItems.items.length === 1 ? true : null,
                    availableItems: availableItems.items,
                    availableWeight: availableItems.weight,
                    contact: supplier.contact,
                    deliveryOption: availableItems.deliveryOption,
                    deliveryId: data.delivery.id
                };
                result.newShipment.request.readyTime = supplier.readyTime;
                result.newShipment.request.closeTime = supplier.closeTime;

                this.getServices(result.order, result.delivery, oSupplier);

            return oSupplier;
        });

        if (mixam.user.hasRole("ROLE_MIXAM")) {
            result.IS_MIXAM = true;
        }

        result.formatNumber = function () {
            return function (val, render) {
                const [text, decimal] = render(val).split('~');

                return parseFloat(text).formatNumber(decimal);
            };
        };

        result.dict = regional().dispatch;

        return result;
    };

    this.getCollectionCenters = function (supplier) {
        if (supplier.collectionCentresEx) {
            const map = supplier.collectionCentresEx.filter(e => e.shopId === mixam.shopId) [0];
            if (map && map.centerIds && map.centerIds.length > 1) {
                return map.centerIds;
            }
        }
    };

    this.getAvailableItems = function (supplier, data, items) {
        const resultItems = [];
        let weight = 0;
        let deliveryOption;

        supplier.items.forEach((itemId, i) => {
            /*
             * Evaluate only shipments associated both with this supplier & (ideally) this delivery.
             *
             * Unfortunately, there are several ways in which we end up with shipment entries that
             * are missing the deliveryId that links them to a specific delivery. We're trying to
             * fix these circumstances, but in this tally it's better to ignore previously-shipped
             * copies that aren't  associated with a delivery.
             */
            const filterFunction = (shipment) => shipment.supplierId === supplier.supplier.id
                && shipment.deliveryId === data.delivery.id;
            const item = this.getItemById(items, itemId),
                used = data.order.shipments.filter(filterFunction).reduce((previousValue, shipment) => {
                    return previousValue + shipment.items.reduce((previousValue, item) => previousValue + (item.itemId === itemId ? item.copies : 0), 0);
                }, 0);

            if (item.query._copies > used) {
                const weightPerItem = item.response._weight / item.query._copies;
                weight += weightPerItem * (item.query._copies - used);
                item.availableCopies = data.delivery.quantitiesOfItems[item.id] - used;
                item.index = i;
                item.response.isDigital = item.response.printType === "DIGITAL";
                item.response.isInkjet = item.response.printType === "INKJET";
                item.response.isWideFormat = item.response.printType === "WIDE_FORMAT";
                item.response.isLitho = item.response.printType === "LITHO";
                resultItems.push(item);
                if (!deliveryOption) {
                    /*
                     * Identify the selected deliveryOption
                     *
                     * NOTE: the following line breaks the dispatch UI when a rate is not available!
                     */
                    const deliveryRate = item.fulfillment.distributionDetails.find(details => details.deliveryId === data.delivery.id).deliveryRate;
                    deliveryOption = deliveryRate.canonicalUri;
                }
            }
        });
        return {
            deliveryOption,
            items: resultItems,
            weight: Math.ceil(weight)
        };
    };

    this.getItemById = function (items, itemId) {
        return items.filter(i => i.id === itemId)[0];
    };

    this.getShipmentItems = function (shipment, items) {
        return shipment.items.map(item => {
            return {
                item: this.getItemById(items, item.itemId),
                copies: item.copies
            };
        });
    };

    this.normalizeMember = function (member) {
        member.dateCreatedText = mixam.dateToMediumDateString(member.dateCreated);
        if (member.reviews) {
            member.reviews.forEach(r => {
                r.sterns = [];
                for (let i = 0; i < r.stars; i++) {
                    r.sterns.push(true);
                }
            });
        }
        return member;
    };

    this.getServices = function (order, delivery, supplier) {
        let url = `/api/shipment/services/${order.id}/${delivery.id}/${supplier.id}`;

        const data = {weight: supplier.availableWeight};
        if (this?.model?.newShipment?.request?.consignment[0]?.collectionDate) { // jshint ignore:line
            data.collectionDate = this.model.newShipment.request.consignment[0].collectionDate;
        }

        $.getJSON(url, data).done(response => {
            if (response.error) {
                this.trigger('showFixedMessage', {type: 'error', body: `${regional().messages[1057].body}: '${response.error.errorMessage.replace(/for URL.+$/, "")}.' <br>Please sort the shipment manually.` , interval: 15000});
                this.setError(response);
            } else {

                supplier.servicesLoaded = function () {
                    return supplier.services && true;
                };
                let deliveryMethod = (this.deliveryMethod || supplier.deliveryOption || '').replace(/-zxa-.+$/, '');

                /*
                 * Recently the most commonly used InterlinkPricelist record was changed in MongoDb
                 * from { serviceName: 'Next Day' } to { serviceName: 'Next business day' } for
                 * cosmetic reasons. The serviceName field, however, is one of the core parts of the
                 * canonicalUri, which we use (here) to identify the delivery service the customer
                 * selected. Delivery rates are obtained from the DPD API each time, and they will
                 * be in the old format. This line makes the new format look like the old format for
                 * the purpose of matching.
                 */
                deliveryMethod = deliveryMethod === 'DPD-DPD_NEXT_BUSINESS_DAY' ? 'DPD-DPD_NEXT_DAY' : deliveryMethod;

                supplier.services = response.data.
                    map(x => {
                        x.network.selected = (x.network.canonicalUri.replace(/-zxa-.+$/, '') === deliveryMethod);
                        return x;
                    });
                this.paint();
            }
        }).fail(response => {
            this.trigger('showFixedMessage', {message: regional().messages[1057]});
            this.setError(response);
        });
    };

}
