var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
var LabourCostRow = function (_a) {
    var labourCost = _a.labourCost, doDelete = _a.doDelete, doUpdate = _a.doUpdate;
    var doUpdateRole = function (event) {
        var entry = __assign(__assign({}, labourCost), { role: event.target.value });
        doUpdate(entry);
    };
    var doUpdateHourlyRate = function (event) {
        var entry = __assign(__assign({}, labourCost), { hourlyRate: Number(event.target.value) });
        doUpdate(entry);
    };
    var doUpdateBenefitsRatio = function (event) {
        var entry = __assign(__assign({}, labourCost), { benefitsRatio: Number(event.target.value) });
        doUpdate(entry);
    };
    return (React.createElement("tr", null,
        React.createElement("td", null,
            React.createElement("input", { name: "role", type: "text", className: "form-control", value: labourCost.role, onChange: doUpdateRole })),
        React.createElement("td", null,
            React.createElement("input", { name: "hourlyRate", type: "number", className: "form-control", value: labourCost.hourlyRate, step: "0.01", onChange: doUpdateHourlyRate })),
        React.createElement("td", null,
            React.createElement("input", { name: "benefitsRatio", type: "number", className: "form-control", value: labourCost.benefitsRatio, step: "0.0001", onChange: doUpdateBenefitsRatio })),
        React.createElement("td", null,
            React.createElement(Button, { variant: "danger", type: "button", onClick: doDelete },
                React.createElement("i", { className: "bi bi-trash" })))));
};
export function LabourCosts(_a) {
    var _b;
    var equipment = _a.equipment, setEquipment = _a.setEquipment;
    var _c = useState(false), showAddNewLabourCostRow = _c[0], setShowAddNewLabourCostRow = _c[1];
    var _d = useState({
        role: '',
        hourlyRate: 0.00,
        benefitsRatio: 0.00
    }), newEntry = _d[0], setNewEntry = _d[1];
    var doAdd = function () {
        var existingLabourCosts = equipment.labourCosts || [];
        setEquipment(__assign(__assign({}, equipment), { labourCosts: __spreadArray(__spreadArray([], existingLabourCosts, true), [newEntry], false) }));
        setShowAddNewLabourCostRow(false);
    };
    var doUpdate = function (index, entry) {
        var labourCosts = __spreadArray([], equipment.labourCosts, true);
        labourCosts.splice(index, 1, entry);
        setEquipment(__assign(__assign({}, equipment), { labourCosts: labourCosts }));
    };
    var doDelete = function (index) {
        var labourCosts = __spreadArray([], equipment.labourCosts, true);
        labourCosts.splice(index, 1);
        setEquipment(__assign(__assign({}, equipment), { labourCosts: labourCosts }));
    };
    return (React.createElement("div", { className: "mt-4 p-2 d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("h4", null, "Labour Costs"),
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Role"),
                        React.createElement("th", null, "Hourly Rate"),
                        React.createElement("th", null, "Benefits Ratio"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, (equipment === null || equipment === void 0 ? void 0 : equipment.labourCosts) && equipment.labourCosts.map(function (entry, index) { return (React.createElement(LabourCostRow, { key: entry.role, labourCost: entry, doDelete: function () { return doDelete(index); }, doUpdate: function (entry) { return doUpdate(index, entry); } })); }))),
            !((_b = equipment === null || equipment === void 0 ? void 0 : equipment.labourCosts) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement("p", null, "No data.")),
            React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                React.createElement("button", { type: "button", className: "align-self-end btn btn-outline-primary", onClick: function () { return setShowAddNewLabourCostRow(!showAddNewLabourCostRow); } },
                    showAddNewLabourCostRow ?
                        React.createElement("i", { className: "bi bi-chevron-up" }) :
                        React.createElement("i", { className: "bi bi-chevron-down" }),
                    "Add Labour Cost")),
            React.createElement(Collapse, { in: showAddNewLabourCostRow },
                React.createElement("div", null,
                    React.createElement("div", { className: "d-grid gap-1 border rounded p-2 m-2", style: { gridTemplateColumns: "repeat(3, 2fr) 1fr" } },
                        React.createElement("div", null,
                            React.createElement("label", { htmlFor: "role", className: "form-label" }, "Role"),
                            React.createElement("input", { id: "role", name: "role", type: "text", className: "form-control", value: newEntry.role, onChange: function (event) { return setNewEntry(__assign(__assign({}, newEntry), { role: event.target.value })); } })),
                        React.createElement("div", null,
                            React.createElement("label", { htmlFor: "hourlyRate", className: "form-label" }, "Hourly Rate"),
                            React.createElement("input", { id: "hourlyRate", name: "hourlyRate", type: "number", className: "form-control", value: newEntry.hourlyRate, step: "0.01", onChange: function (event) { return setNewEntry(__assign(__assign({}, newEntry), { hourlyRate: Number(event.target.value) })); } })),
                        React.createElement("div", null,
                            React.createElement("label", { htmlFor: "benefitsRatio", className: "form-label" }, "Benefits Ratio"),
                            React.createElement("input", { id: "benefitsRatio", name: "benefitsRatio", type: "number", className: "form-control", value: newEntry.benefitsRatio, step: "0.0001", onChange: function (event) { return setNewEntry(__assign(__assign({}, newEntry), { benefitsRatio: Number(event.target.value) })); } })),
                        React.createElement("div", { className: "d-flex justify-content-center align-items-end" },
                            React.createElement(Button, { variant: "primary", onClick: doAdd }, "Add"))))))));
}
