import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithSignDataRequest from '../../with-sign-data-request';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";
import {StompListener, Topics} from "../../../../assets/stomp/stomp-listener";

export default defineComponent(AdminAccountsPayable, WithSignDataRequest);

function AdminAccountsPayable() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments`,
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: '',
            type: CELL_TYPES.THUMBNAIL,
            data:"front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data:"href"
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: '100px'
        });

        cols.push({
            title: "Created",
            type: CELL_TYPES.TIMEBOX,
            data:"time",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Username",
            type: "text",
            width: "9%",
            data: "user",
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            width: "120px",
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "Expected",
            type: "number",
            decimal: 2,
            currency: true,
            data: "expectedCost",
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Actual",
            type: "number",
            data: "actualCost",
            decimal: 2,
            currency: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Packaging Expected",
            type: "number",
            data: "packagingCost",
            decimal: 2,
            currency: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Actual Extra",
            type: "number",
            data: "actualExtra",
            decimal: 2,
            currency: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "In Dispute",
            type: "boolean",
            data: "fulfillmentDispute",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Remark",
            type: "text",
            data: "fulfillmentRemark",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Discrepancy",
            type: "number",
            data: "discrepancy",
            decimal: 2,
            currency: true,
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "",
            type: CELL_TYPES.VIEW_PURCHASE_ORDER_MODAL,
            data: "purchaseOrderNumber",
            width: '100px'
        });

        cols.push({
            title: "",
            type: CELL_TYPES.RECONCILIATION_FORM_MODAL,
            width: '100px'
        });

        return cols;
    };

    this.getData = function () {
        requestAnimationFrame(() => this.signDataRequest(token => this.getReportData(token)));
    };

    /**
     * Report data come from Reporter in the form of a List<ListOrderEntity>
     */
    this.getReportData = function (token) {
        const url = this.isFirstTime ? this.attr.firstTimeUrl : this.attr.url;

        $.ajax({url: url, dataType: 'json', headers: {'Authorization': `Bearer ${token}`}})
            .done(data => {
                this.normalizedData = data.map(listOrderEntity =>
                    this.normalizeFromReporter(listOrderEntity.item, listOrderEntity, listOrderEntity.index)
                );
                this.paint();
                if (this.isFirstTime) {
                    this.isFirstTime = false;
                    setTimeout(() => this.getData(), 1000 * 5);
                }
            })
            .fail(err => this.trigger("log", {message: err}));
    };

    /**
     * @override with-order-items
     */
    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalizedData
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    /**
     * Normalize a single ListOrderItemEntity form the ListOrderEntity returned from reporter
     */
    this.normalizeFromReporter = function (listOrderItemEntity, listOrderEntity, index) {
        const normalizedEntity = {
            id: listOrderEntity.itemId,
            orderId: listOrderEntity.id,
            itemId: listOrderEntity.itemId,
            front: {
                src: listOrderItemEntity.thumbnailUrl,
                href: `/orders/${listOrderEntity.id}/artwork`,
                target: "_blank"
            },
            href: {
                href: `/orders/${listOrderEntity.id}/artwork`,
                caption: listOrderEntity.caseNumber + (listOrderEntity.itemCount > 1 ? "/" + (index + 1) : ""),
                target: "_blank"
            },
            time: listOrderEntity.time,
            status: listOrderEntity.orderStatus,
        };

        normalizedEntity.user = listOrderEntity.member
            ? listOrderEntity.member.firstName + " " + listOrderEntity.member.lastName
            : listOrderEntity.contact.name;

        if (listOrderItemEntity.fulfillment) {
            const fulfillment = listOrderItemEntity.fulfillment;
            const expectedCost = fulfillment.cost || 0;
            const expectedPackaging = listOrderItemEntity.fulfillment.packagingCost || 0;

            normalizedEntity.supplier = fulfillment.supplierName;
            normalizedEntity.expectedCost = expectedCost;
            normalizedEntity.packagingCost = expectedPackaging;

            if (listOrderItemEntity.purchaseOrderExtendedReference) {
                const purchaseOrderExtendedReference = listOrderItemEntity.purchaseOrderExtendedReference;

                normalizedEntity.actualCost = purchaseOrderExtendedReference.actualCost;
                normalizedEntity.actualExtra = purchaseOrderExtendedReference.actualExtra;
                normalizedEntity.fulfillmentRemark = purchaseOrderExtendedReference.referenceNumber;
                normalizedEntity.fulfillmentDispute = purchaseOrderExtendedReference.fulfillmentDispute;
                normalizedEntity.purchaseOrderNumber = purchaseOrderExtendedReference.purchaseOrderNumber;
                normalizedEntity.discrepancy = this.calculateDiscrepancy(expectedCost, purchaseOrderExtendedReference.actualCost, expectedPackaging, purchaseOrderExtendedReference.actualExtra);
            }
        }
        return normalizedEntity;
    };

    this.calculateDiscrepancy = function (expectedCost, actualCost, expectedPackaging, actualExtra) {
        const expectedTotal = expectedCost + expectedPackaging;
        const actualTotal = actualCost + actualExtra;
        return actualTotal - expectedTotal;
    };

    /**
     * Normalize a single ListOrderItem from the ListOrder sent via stomp
     */
    this.normalizeFromStomp = function (listOrderItem, listOrder) {
        const index = listOrder.items.indexOf(listOrderItem);
        const normalizedEntity = {
            id: listOrderItem.id,
            orderId: listOrder.id,
            itemId: listOrderItem.id,
            front: {
                src: listOrderItem.thumbnailUrl,
                href: `/orders/${listOrder.id}/artwork`,
                target: "_blank"
            },
            href: {
                href: `/orders/${listOrder.id}/artwork`,
                caption: listOrder.caseNumber + (listOrder.itemCount > 1 ? "/" + (index + 1) : ""),
                target: "_blank"
            },
            time: listOrder.time,
            status: listOrder.orderStatus,
        };

        normalizedEntity.user = listOrder.member
            ? listOrder.member.firstName + " " + listOrder.member.lastName
            : listOrder.contact.name;

        if (listOrderItem.fulfillment) {
            const fulfillment = listOrderItem.fulfillment;
            const expectedCost = fulfillment.cost || 0;
            const expectedPackaging = listOrderItem.fulfillment.packagingCost || 0;

            normalizedEntity.supplier = fulfillment.supplierName;
            normalizedEntity.expectedCost = expectedCost;
            normalizedEntity.packagingCost = expectedPackaging;

            if (listOrderItem.purchaseOrderExtendedReference) {
                const purchaseOrderExtendedReference = listOrderItem.purchaseOrderExtendedReference;

                normalizedEntity.actualCost = purchaseOrderExtendedReference.actualCost;
                normalizedEntity.actualExtra = purchaseOrderExtendedReference.actualExtra;
                normalizedEntity.fulfillmentRemark = purchaseOrderExtendedReference.referenceNumber;
                normalizedEntity.fulfillmentDispute = purchaseOrderExtendedReference.fulfillmentDispute;
                normalizedEntity.purchaseOrderNumber = purchaseOrderExtendedReference.purchaseOrderNumber;
                normalizedEntity.discrepancy = this.calculateDiscrepancy(expectedCost, purchaseOrderExtendedReference.actualCost, expectedPackaging, purchaseOrderExtendedReference.actualExtra);
            }
        }
        return normalizedEntity;
    };

    this.fulfilmentChange = function (event, data) {
        $.post(`/admin/api/fulfilment/${data.order}/${data.item}/update`, data).done((/*response*/) => true);
    };

    this.handleStompUpdate = function (stompData) {
        if (stompData.category === "FULFIL" && stompData.order.shopId === mixam.shop.id) {
            const item = stompData.order.items.find(item => item.id === stompData.reason);
            const normalisedStompData = this.normalizeFromStomp(item, stompData.order);
            const index = this.normalizedData.map(x => x.id).indexOf(normalisedStompData.id);
            this.normalizedData[index] = normalisedStompData;
            this.paint();
            window.dispatchEvent(new CustomEvent('highlightDataTableRow', {detail: {rowToHighlightId: normalisedStompData.id}}));
        }
    };

    this.after('initialize', function () {
        this.isFirstTime = true;
        DataTable.attachTo(this.select('dataTableSelector'));

        this.on(document, "uiRequestFulfilmentStatusChange", this.fulfilmentChange);
        this.on(document, "uiDataImportComplete", this.getData);

        const channelsArr = [Topics.ALL_QUEUE_TOPIC];
        const stompListener = new StompListener(channelsArr, this.handleStompUpdate.bind(this));
        stompListener.connect();

        setTimeout(() => this.getData(), 1);
    });
}
