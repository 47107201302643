import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import mixam from "../../../boot/mixam";
import axios from "axios";
import WithSignDataRequest from "../../with-sign-data-request";
import bindIcons from "../../constants/binding-icons";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminArticleHistory, WithSignDataRequest);

function AdminArticleHistory() {

    this.attributes({
        dataLoaderSelector: '[data-type="data-loader"]',
        fromDateSelector: '[data-type="date-from"]',
        toDateSelector: '[data-type="date-to"]',
        updateDatesSelector: '[data-type="update-dates"]',
        updateDatesSpinnerSelector: '[data-type="update-dates-spinner"]',
        updateDatesTextSelector: '[data-type="update-dates-text"]',
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "",
            type: CELL_TYPES.THUMBNAIL,
            data: "front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplierName"
        });

        cols.push({
            title: "Fulfilled",
            type: "date",
            data: "fulfilmentDate"
        });

        cols.push({
            title: "Labeled",
            type: "date",
            data: "labeledDate"
        });

        cols.push({
            title: "Days",
            type: "number",
            data: "productionDays"
        });

        cols.push({
            title: "Won By",
            type: "text",
            data: "winner"
        });

        cols.push({
            title: "Sent To",
            type: "text",
            data: "machineName"
        });

        cols.push({
            title: "Copies",
            type: "number",
            data: "copies"
        });

        cols.push({
            title: "Bind",
            type: "icon",
            data: "bind"
        });

        cols.push({
            title: "Sewn",
            type: "boolean",
            data: "sewn"
        });

        cols.push({
            title: "Pages",
            type: "number",
            data: "pages"
        });

        cols.push({
            title: "Dispute",
            type: "boolean",
            data: "disputed"
        });

        cols.push({
            title: "Cost",
            type: "number",
            data: "fulfilmentCost",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });

        cols.push({
            title: "Total",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });

        cols.push({
            title: "Value",
            type: "number",
            data: "value",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix
        });

        return cols;
    };

    this.createProviderSummary = function () {
        const suppliers = this.getSuppliersMap();
        const supplierFulfilmentData = {};

        let total = 0;
        let count = 0;

        // filter by provider
        this.data.forEach(article => {
            if (!supplierFulfilmentData[article.supplierId]) {
                supplierFulfilmentData[article.supplierId] = suppliers[article.supplierId];
            }
            if (supplierFulfilmentData[article.supplierId]) {
                supplierFulfilmentData[article.supplierId].list.push(article);
                supplierFulfilmentData[article.supplierId].cost += article.fulfilmentCost;
                supplierFulfilmentData[article.supplierId].filterUrl = `{"and":{"supplierName":{"cn":"${supplierFulfilmentData[article.supplierId].name}"}},"or":{}}`;
                total += article.fulfilmentCost;
                count += 1;
            }
            article.machine = article.machineName; // The AdminArticlesSummary component wants this info under this name
        });

        // calculate percentage
        Object.keys(supplierFulfilmentData).forEach(p => {
            const supplier = supplierFulfilmentData[p];
            if (supplier) {
                supplier.costPercent = Math.round(supplier.cost / total * 100);
                supplier.countPercent = Math.round(supplier.list.length / count * 100);
            } else {
                delete supplierFulfilmentData[p];
            }
        });

        supplierFulfilmentData.total = total;
        supplierFulfilmentData.count = count;

        this.trigger("articleDataReady", supplierFulfilmentData);
    };

    this.getSuppliersMap = function () {
        const map = {};
        window.getSuppliers().forEach(s => (map[s.id] = s, s.list = [], s.cost = 0));
        return map;
    };

    this.getData = function (token) {

        this.disableDateSelectors();

        const $fromDate = this.select('fromDateSelector');
        const $toDate = this.select('toDateSelector');
        const fromDate = $fromDate.val();
        const toDate = $toDate.val();

        axios.get(
            `${mixam.reporterOrigin}/reporter/admin/api/shops/${mixam.shopId}/fulfilments?fromDate=${fromDate}&toDate=${toDate}`,
            { headers: {"Authorization" : `Bearer ${token}`} }
        ).then(response => {
            this.data = this.normalize(response.data);
            this.paint();
            this.resetDateSelectors();
            this.createProviderSummary();
        }).catch(error => {
            const message = error.response?.data?.message
                ? error.response.data.message
                : 'The system was unable to process your request.';
            this.trigger('showFixedMessage', {type: 'error', body: message, interval: 15000});
            this.resetDateSelectors();
        });

    };

    this.normalize = function (articles) {
        articles.forEach(article => this.normalizeArticle(article));
        return articles;
    };

    this.normalizeArticle = function (article) {

        if (article.thumbnailUrl) {
            article.front = {
                src: article.thumbnailUrl,
                href: `/orders/${article.orderId}/artwork`,
                title: `Order #${article.orderCaseNumber}`,
                target: "_blank"
            };
        }

        article.href = {
            href: `/orders/${article.orderId}/artwork`,
            caption: `${article.orderCaseNumber}${article.itemNumber ? '/' + article.itemNumber : ''}`,
            title: `Order #${article.orderCaseNumber}`,
            target: '_blank'
        };

        if (article.labeledDate) {
            article.productionDays = Math.round((article.labeledDate - article.fulfilmentDate) / (1000 * 60 * 60 * 24));
        }

        if (article.bindingTypeId !== undefined) {
            article.bind = bindIcons[article.bindingTypeId];
        }

    };

    this.paint = function () {
        const options = {
            columns: this.createSchema(),
            fileName: 'article-history',
            response: this.data
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: options }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", options);
        }
    };

    this.disableDateSelectors = function () {
        this.select('fromDateSelector').addClass('disabled');
        this.select('toDateSelector').addClass('disabled');
        this.select('fromDateSelector').prop('disabled', true);
        this.select('toDateSelector').prop('disabled', true);
        this.select('updateDatesSpinnerSelector').removeClass('d-none');
        this.select('updateDatesTextSelector').addClass('d-none');
        this.select('dataLoaderSelector').removeClass('d-none');
    };

    this.resetDateSelectors = function () {
        this.select('fromDateSelector').removeClass('disabled');
        this.select('toDateSelector').removeClass('disabled');
        this.select('fromDateSelector').prop('disabled', false);
        this.select('toDateSelector').prop('disabled', false);
        this.select('updateDatesSpinnerSelector').addClass('d-none');
        this.select('updateDatesTextSelector').removeClass('d-none');
        this.select('dataLoaderSelector').addClass('d-none');
    };

    this.updateDates = function () {
        this.signDataRequest(token => this.getData(token));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.signDataRequest(token => this.getData(token)), 10);
        this.on('click', {
            updateDatesSelector: this.updateDates
        });
    });

}
