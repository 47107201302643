var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { SearchResultType } from "./types";
import { Image } from "react-bootstrap";
import styled from 'styled-components';
import i18nShop, { t } from "../../i18n/i18n";
import { I18nextProvider } from 'react-i18next';
var StyledImage = styled(Image)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 6rem;\n"], ["\n    max-width: 6rem;\n"])));
function sortAndCombineSearchResults(searchResults) {
    var combinedResults = [];
    var productResults = [];
    var supportResults = [];
    var blogResults = [];
    var cmsResults = [];
    var resultTypeProduct = SearchResultType.PRODUCT_PAGE;
    var resultTypeSupport = SearchResultType.KNOWLEDGE_BASE;
    var resultTypeblog = SearchResultType.BLOG_PAGE;
    var resultTypeCms = SearchResultType.CMS_PAGE;
    searchResults.forEach(function (result) {
        switch (result.resultType) {
            case resultTypeProduct:
                result.resultType = i18nShop.t('searchResults.productPage');
                productResults.push(result);
                break;
            case resultTypeSupport:
                result.resultType = i18nShop.t('searchResults.knowledgeBaseArticle');
                supportResults.push(result);
                break;
            case resultTypeblog:
                result.resultType = i18nShop.t('searchResults.blogArticle');
                blogResults.push(result);
                break;
            case resultTypeCms:
                result.resultType = i18nShop.t('searchResults.cmsPage');
                cmsResults.push(result);
                break;
            default:
                break;
        }
    });
    if (productResults.length > 0) {
        combinedResults = combinedResults.concat(productResults);
    }
    if (supportResults.length > 0) {
        combinedResults = combinedResults.concat(supportResults);
    }
    if (blogResults.length > 0) {
        combinedResults = combinedResults.concat(blogResults);
    }
    if (cmsResults.length > 0) {
        combinedResults = combinedResults.concat(cmsResults);
    }
    return combinedResults;
}
function formatSearchResults(searchResults) {
    var groupedResults = sortAndCombineSearchResults(searchResults);
    var formattedResults = [];
    groupedResults.forEach(function (result) {
        result.resultType = result.resultType.replace(/_/g, " ");
        if (result.snippet.length > 140) {
            result.snippet = result.snippet.substring(0, 137);
            result.snippet = result.snippet + "...";
        }
        formattedResults.push(result);
    });
    return formattedResults;
}
export var SearchResults = function (searchResultProps) {
    var formattedResults = formatSearchResults(searchResultProps.updateSearchResults);
    return (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement(React.Fragment, null, formattedResults.length > 0 ? (React.createElement("ul", { style: { listStyleType: "none", paddingLeft: "0" }, "data-type": "search-results-list" }, formattedResults.map(function (searchResult, index) { return (React.createElement("li", { key: searchResult.path + '_' + index },
            index > 0 ? (searchResult.resultType == formattedResults[index - 1].resultType ?
                null
                :
                    React.createElement("h5", { style: { textTransform: 'capitalize' }, className: "fs-6 text-neutral-3 ms-3 mb-3 fw-bold" }, searchResult.resultType)) :
                React.createElement("h5", { style: { textTransform: 'capitalize' }, className: "fs-6 text-neutral-3 ms-3 mb-1 mt-3 fw-bold" }, searchResult.resultType),
            React.createElement("a", { href: searchResult.path, tabIndex: 0, className: "d-block text-decoration-none text-neutral-4 hover-bg-colour-colour-neutral-7 ps-3 pe-3 pt-2 pb-2 cursor-pointer text-decoration-none" },
                React.createElement("div", { className: "d-flex" },
                    React.createElement("div", { className: "flex-grow-0 pe-3 rounded" },
                        React.createElement(StyledImage, { className: "rounded-3", src: searchResult.imgUrl, fluid: true })),
                    React.createElement("div", { className: "flex-grow-1" },
                        React.createElement("h5", { className: "fs-6 text-black" }, searchResult.title),
                        React.createElement("p", { className: "fs-7", dangerouslySetInnerHTML: { __html: searchResult.snippet } })))))); }))) : (React.createElement("h5", { className: "fs-6" }, t('search.noResultsFound'))))));
};
var templateObject_1;
