import regional_en from "./regionalEN_GB";
import regional_us from "./regionalEN_US";
import regional_ca from "./regionalEN_CA";
import regional_au from "./regionalEN_AU";
import regional_de from "./regionalDE_DE";
import regionalEN_DE from "./regionalEN_DE";
import regional_ie from "./regionalEN_IE";
import {Locales} from "@mixam-platform/mixam-types";

let currentLocale;

const regional = {
    en: regional_en,
    us: regional_us,
    ca: regional_ca,
    au: regional_au,
    de: regional_de,
    en_de: regionalEN_DE,
    ie: regional_ie
};

export default function getRegional() {
    if (!currentLocale) {
        const shopLocale = document.getElementById('shopLocale').value;
        switch (shopLocale) {
            case Locales["en-US"].value:
                currentLocale = "us";
                break;
            case Locales["en-CA"].value:
                currentLocale = "ca";
                break;
            case Locales["en-AU"].value:
                currentLocale = "au";
                break;
            case Locales["de-DE"].value:
                currentLocale = "de";
                break;
            case Locales["en-DE"].value:
                currentLocale = "en_de";
                break;
            case Locales["en-IE"].value:
                currentLocale = "ie";
                break;
            default:
                currentLocale = shopLocale.substr(0, 2).toLocaleLowerCase();
                break;
        }
    }
    return regional[currentLocale] || regional.en;
}


