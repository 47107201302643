export var CmsGroupType;
(function (CmsGroupType) {
    CmsGroupType["CMS_PAGE"] = "CMS_PAGE";
    CmsGroupType["FAQ_PAGE"] = "FAQ_PAGE";
    CmsGroupType["KNOWLEDGE_BASE_ARTICLE"] = "KNOWLEDGE_BASE_ARTICLE";
    CmsGroupType["PRODUCT_PAGE"] = "PRODUCT_PAGE";
    CmsGroupType["PRODUCT_CATEGORY"] = "PRODUCT_CATEGORY";
    CmsGroupType["BLOG_CATEGORY"] = "BLOG_CATEGORY";
    CmsGroupType["BLOG_ARTICLE"] = "BLOG_ARTICLE";
})(CmsGroupType || (CmsGroupType = {}));
