import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import deliverySelectSingleTemplate from 'text!../../../appes6/templates/shop/delivery-select1.mustache';
import deliverySelectTemplate from 'text!../../../appes6/templates/shop/delivery-select2.mustache';
import deliveryInvalidTemplate from 'text!../../../appes6/templates/shop/delivery-invalid.mustache';
import Mustache from '../../../components/mustache/mustache';
import regional from '../../boot/regional';

export default defineComponent(OrderGroupDeliveryManager);

function OrderGroupDeliveryManager() {

    this.attributes({
        contentSelector: '[data-type="delivery-group-delivery-service"]',
        deliveryOptionSelector: '[data-type="delivery-option"]',
        estimatedDeliverySelector: '[data-type="estimated-delivery"]',
    });

    this.setOrder = function (event, data) {
        this.order = data;

        const delivery = this.order.deliveries.filter(delivery => delivery.id === this.deliveryId)[0]; // TODO: [MX-511] update!
        this.group = delivery.deliveryGroups.filter(group => group.groupingToken === this.groupingToken)[0];

        if (this.group?.deliveryRates.length) { // jshint ignore:line
            this.select('contentSelector').html(this.render());
        }
    };

    this.render = function () {
        let template = this.group.deliveryRates.length === 1 || this.order.orderStatusInt >= 10 ? deliverySelectSingleTemplate : deliverySelectTemplate;
        if (!this.group.ready) {
            template = deliveryInvalidTemplate;
        }
        return Mustache.render(template, {
            list: this.normalise(this.group.deliveryRates),
            formatNumber: () => mixam.mustachFormatNumber,
            alignment: 'left',
            currency: { prefix: regional().currencyPrefixSymbol, postfix: regional().currencyPostfixSymbol }
        });
    };

    this.normalise = function (list) {
        /** @namespace list[0].canonicalUri */
        const selectedRate = this.group.deliveryOption || list[0].canonicalUri;
        return list.map((r, i) => {
            r.index = i;
            r.deliveryDateString = r.deliveryDate ? mixam.dateToLongString(new Date(r.deliveryDate)) : ' ';
            delete r.selected;
            if (r.rateCategory === 0) {
                r.serviceType = regional().santa.order.weAreSearchingForSuitable;
            }
            /** @namespace r.canonicalUri */
            if (r.canonicalUri === selectedRate) {
                r.selected = true;
                //this.group.deliveryTotal = r.total;
                // this.group.deliveryDate = r.deliveryDate;
            }
            r.label = (regional().deliveryOption['label_'.concat(r.canonicalUri.replaceAll("-", "_"))] ? regional().deliveryOption['label_'.concat(r.canonicalUri.replaceAll("-", "_"))] : r.serviceType);
            return r;
        });
    };

    this.changeDeliveryOption = function (event) {
        const $target = $(event.target).closest('a');

        event.preventDefault();
        this.group.deliveryOption = $target.data('deliveryMethod');
        this.select("estimatedDeliverySelector").html($target.data('deliveryDate'));
        this.select('contentSelector').html(this.render());
        const url = `/api/orders/${this.order.id}/${this.group.id}/delivery/update`;
        $.post(url, {"delivery-option": this.group.deliveryOption});
    };

    this.after('initialize', function () {
        this.deliveryId = this.$node.data('delivery');
        this.groupingToken = this.$node.data('grouping-token');
        this.on(document, "setOrderData", this.setOrder);
        this.on('click', {
            deliveryOptionSelector: this.changeDeliveryOption
        });
    });
}
