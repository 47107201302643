import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import mixam from "../../../../boot/mixam";
import WithSignDataRequest from "../../../with-sign-data-request";
import {CELL_TYPES} from "../../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminDiversionReport, WithSignDataRequest);

function AdminDiversionReport() {

    this.attributes({
        diversionReportUrl: `${mixam.reporterOrigin}/reporter/admin/api/shops/${mixam.shopId}/fulfilments`,
        dataTableSelector: '[data-type="data-table"]',
        fromDateSelector: '[data-type="date-from"]',
        toDateSelector: '[data-type="date-to"]',
        dataLoaderSelector: '[data-type="data-loader"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Thumbnail",
            type: CELL_TYPES.THUMBNAIL,
            data: "front",
            width: "4.5%"
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "href",
            width: "4.5%"
        });

        cols.push({
            title: "Fulfilled By",
            type: "text",
            data: "fulfilmentBy",
            width: "9.2%"
        });

        cols.push({
            title: "Offer Supplier",
            type: "text",
            data: "offerSupplierName",
            width: "5.75%"
        });

        cols.push({
            title: "Offer Days",
            type: "number",
            data: "originalDays",
            width: "5%"
        });

        cols.push({
            title: "Offer Machine",
            type: "text",
            data: "originalMid",
            width: "5.75%"
        });

        cols.push({
            title: "Offer Cost",
            type: "number",
            data: "offerCost",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            width: "4.5%"
        });

        cols.push({
            title: "Offer Price",
            type: "number",
            data: "total",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            width: "4.5%"
        });

        cols.push({
            title: "Fulfilled",
            type: "date",
            data: "fulfilmentDate",
            width: "4.75%",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Actual Supplier",
            type: "text",
            data: "supplierName",
            width: "5.8%"
        });

        cols.push({
            title: "Actual Days",
            type: "number",
            data: "fulfilmentDays",
            width: "5%"
        });

        cols.push({
            title: "Actual Machine",
            type: "text",
            data: "machineName",
            width: "5.75%"
        });

        cols.push({
            title: "Actual Cost",
            type: "number",
            data: "fulfilmentCost",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            width: "5.25%"
        });

        cols.push({
            title: "Cost Difference",
            type: "number",
            data: "costDifference",
            sum: true,
            currency: true,
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            width: "5.5%"
        });

        cols.push({
            title: "Margin %",
            type: "number",
            data: "marginPercentage",
            sum: true,
            decimal: 2,
            width: "4%"
        });

        cols.push({
            title: "Diversion Reasons",
            type: "list",
            data: "diversionReasons",
            width: "10.25%"
         });

        cols.push({
            title: "Diversion Explanation",
            type: "text",
            data: "diversionExplanation",
            width: "10%"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getData = function () {
        this.disableDateSelectors();
        this.signDataRequest(token => this.getDiversionData(token));
    };

    this.getDiversionData = function (token) {
        const fromDate = this.select('fromDateSelector').val();
        const toDate = this.select('toDateSelector').val();

        axios.get(
            this.attr.diversionReportUrl,
            {
                params: {fromDate: fromDate, toDate: toDate, isDiversion: true},
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.data = this.normalize(response.data);
                this.paint();
                this.resetDateSelectors();
            }).catch(error => {
            alert(`Failed To Retrieve Data: ${error}`);
            this.resetDateSelectors();
        });
    };

    this.disableDateSelectors = function () {
        this.select('fromDateSelector').addClass('disabled');
        this.select('toDateSelector').addClass('disabled');
        this.select('fromDateSelector').prop( "disabled", true);
        this.select('toDateSelector').prop( "disabled", true);
        this.select('dataLoaderSelector').removeClass('d-none');
    };

    this.resetDateSelectors = function () {
        this.select('fromDateSelector').removeClass('disabled');
        this.select('toDateSelector').removeClass('disabled');
        this.select('fromDateSelector').prop( "disabled", false);
        this.select('toDateSelector').prop( "disabled", false);
        this.select('dataLoaderSelector').addClass('d-none');
    };

    this.normalize = function (diversions) {
        diversions.forEach(diversion => this.normalizeDiversion(diversion));
        return diversions;
    };

    this.normalizeDiversion = function (diversion) {
        if (diversion.thumbnailUrl) {
            diversion.front = {
                src: diversion.thumbnailUrl,
                href: `/orders/${diversion.orderId}/artwork`,
                title: `Order #${diversion.orderCaseNumber}`,
                target: "_blank"
            };
        }

        diversion.href = {
            href: `/orders/${diversion.orderId}/artwork`,
            caption: `${diversion.orderCaseNumber}${diversion.itemNumber ? '/' + diversion.itemNumber : ''}`,
            title: `Order #${diversion.orderCaseNumber}`,
            target: '_blank'
        };

        if (diversion.supplierDiversion !== undefined) {
            diversion.diversionReasons = diversion.supplierDiversion.reasons;
            diversion.originalMid = diversion.supplierDiversion.originalMid;
            diversion.originalPid = diversion.supplierDiversion.originalPid;
            diversion.originalDays = diversion.supplierDiversion.originalDays;

            if (diversion.supplierDiversion.explanation !== undefined) {
                diversion.diversionExplanation = diversion.supplierDiversion.explanation;
            }
        }

        if (diversion.offerCost !== undefined && diversion.fulfilmentCost !== undefined && diversion.total !== undefined) {
            diversion.costDifference = diversion.offerCost - diversion.fulfilmentCost;
            diversion.margin = diversion.total - diversion.fulfilmentCost;

            if (diversion.margin > 0) {
                diversion.marginPercentage = (diversion.margin / diversion.total) * 100;
            } else {
                diversion.marginPercentage = 0;
            }
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);

        this.on('change', {
            fromDateSelector: this.getData,
            toDateSelector: this.getData
        });
    });
}