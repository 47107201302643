import defineComponent from '../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import { OrderAdminRemarks } from '../react/order/OrderAdminRemarks.tsx';

export default defineComponent(OrderAdminRemarksReact);

function OrderAdminRemarksReact() {
    this.attributes({
        containerSelector: '[data-type="order-admin-remarks-target"]',
    });

    this.initReactComponent = function () {
        const container = this.select('containerSelector').get(0);
        const root = ReactDom.createRoot(container);
        root.render(
            <OrderAdminRemarks
                initialCustomerRemark={this.customerRemark}
            />
        );
    };

    this.after('initialize', function () {
        this.customerRemark = this.$node.data('customer-remark');
        this.initReactComponent();
    });
}