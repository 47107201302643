var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form as BootstrapForm, Modal, Spinner, Table } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { MODE } from './types';
import { Locale } from '@mixam-platform/mixam-types';
var validationSchema = Yup.object().shape({
    defaultMessage: Yup.string().required('Default Message is required'),
    localeMessages: Yup.array()
        .of(Yup.object().shape({
        locale: Yup.string()
            .required('Locale is required')
            .notOneOf(['en-US'], 'The en-US locale is the default and cannot be added as an override.'),
        message: Yup.string()
            .required('Message is required')
            .test('not-default-message', 'Locale-specific messages cannot be identical to the default message.', function (value) {
            var defaultMessage = this.options.context.defaultMessage; // Access the default message
            return value !== defaultMessage; // Ensure the override is not identical to the default
        }),
    }))
        .test('unique-locale', 'Each locale can only be added once.', function (localeMessages) {
        if (!localeMessages)
            return true;
        var localeSet = new Set(localeMessages.map(function (msg) { return msg.locale; }));
        return localeSet.size === localeMessages.length;
    })
        .test('no-duplicate-messages', 'Override messages cannot be identical to the default message.', function (localeMessages) {
        if (!localeMessages)
            return true;
        var defaultMessage = this.options.context.defaultMessage;
        return !localeMessages.some(function (msg) { return msg.message === defaultMessage; });
    }),
});
export var MessageEditorModal = function (_a) {
    var messageKey = _a.messageKey, application = _a.application, rawLocale = _a.locale, show = _a.show, mode = _a.mode, onClose = _a.onClose;
    var locale = rawLocale.replace('-', '_');
    var _b = useState(null), message = _b[0], setMessage = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(show), showModal = _d[0], setShowModal = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var handleCloseModal = function (data) {
        setShowModal(false);
        onClose(data);
    };
    var handleShowModal = function () { return setShowModal(true); };
    var messageGetUrl = "/api/admin/messages/".concat(messageKey, "/applications/").concat(application);
    var messageUpdateUrl = "/api/admin/messages";
    useEffect(function () {
        if (showModal) {
            setLoading(true);
            axios
                .get(messageGetUrl)
                .then(function (response) {
                setMessage(response.data);
                setLoading(false);
            })
                .catch(function () {
                setError('Failed to load the message.');
                setLoading(false);
            });
        }
    }, [showModal]);
    var localeOptions = Object.values(Locale);
    var javaToJsLocale = function (locale) { return locale.replace('_', '-'); };
    var jsToJavaLocale = function (locale) { return locale.replace('-', '_'); };
    var filteredLocaleOptions = localeOptions.filter(function (loc) { return loc !== 'en-US'; }).map(function (loc) { return jsToJavaLocale(loc); });
    return (React.createElement(React.Fragment, null,
        mode === MODE.ADMIN && (React.createElement(Button, { className: "btn btn-primary btn-sm w-100", onClick: handleShowModal }, "Edit")),
        showModal && (React.createElement(Modal, { show: showModal, onHide: function () { handleCloseModal(null); }, size: "lg", centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Edit Message")),
            React.createElement(Modal.Body, null, loading ? (React.createElement("div", { className: "text-center" },
                React.createElement(Spinner, { animation: "border", role: "status", variant: "primary" },
                    React.createElement("span", { className: "visually-hidden" }, "Loading...")),
                React.createElement("p", { className: "mt-3" }, "Loading message data..."))) : (React.createElement(Formik, { initialValues: {
                    key: message.key,
                    application: message.application,
                    defaultMessage: message.defaultMessage,
                    localeMessages: Object.entries(message.localeMessages || {}).map(function (_a) {
                        var locale = _a[0], message = _a[1];
                        return ({
                            locale: locale,
                            message: message,
                        });
                    }),
                }, validationSchema: validationSchema, validateOnChange: true, context: { defaultMessage: message.defaultMessage }, onSubmit: function (values, _a) {
                    var setSubmitting = _a.setSubmitting;
                    var updatedMessage = __assign(__assign({}, message), { defaultMessage: values.defaultMessage, localeMessages: values.localeMessages.reduce(function (acc, _a) {
                            var locale = _a.locale, message = _a.message;
                            acc[jsToJavaLocale(locale)] = message;
                            return acc;
                        }, {}) });
                    axios
                        .post(messageUpdateUrl, updatedMessage)
                        .then(function () {
                        setError(null);
                        var updatedContent = updatedMessage.localeMessages[locale] ||
                            updatedMessage.defaultMessage;
                        handleCloseModal({
                            key: updatedMessage.key,
                            application: updatedMessage.application,
                            content: updatedContent,
                        });
                    })
                        .catch(function (err) {
                        setError('Failed to save the message. Please try again.');
                        console.error('Error saving message:', err);
                    })
                        .finally(function () { return setSubmitting(false); });
                } }, function (_a) {
                var values = _a.values, errors = _a.errors, touched = _a.touched, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue;
                var hasOverride = values.localeMessages.some(function (msg) { return jsToJavaLocale(msg.locale) === jsToJavaLocale(locale); });
                return (React.createElement(Form, null,
                    error && React.createElement(Alert, { variant: "danger" }, error),
                    React.createElement(BootstrapForm.Group, { className: "mb-3" },
                        React.createElement(BootstrapForm.Label, null, "Key"),
                        React.createElement(Field, { type: "text", name: "key", className: "form-control", disabled: true })),
                    React.createElement(BootstrapForm.Group, { className: "mb-3" },
                        React.createElement(BootstrapForm.Label, null, "Default Message"),
                        React.createElement(Field, { as: "textarea", name: "defaultMessage", className: "form-control", rows: 2, disabled: hasOverride }),
                        hasOverride && (React.createElement("small", { className: "text-danger d-block mb-2" },
                            "Editing the default message is disabled because a locale-specific override exists for",
                            ' ',
                            React.createElement("strong", null, locale),
                            ".")),
                        React.createElement(ErrorMessage, { name: "defaultMessage", component: "div", className: "text-danger" })),
                    React.createElement(FieldArray, { name: "localeMessages" }, function (_a) {
                        var remove = _a.remove, push = _a.push;
                        return (React.createElement(React.Fragment, null,
                            React.createElement("h5", null, "Message Overrides"),
                            React.createElement(Table, { bordered: true },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "Locale"),
                                        React.createElement("th", null, "Message"),
                                        React.createElement("th", { style: { width: '1%' } }))),
                                React.createElement("tbody", null, values.localeMessages.map(function (localeMessage, index) { return (React.createElement("tr", { key: index },
                                    React.createElement("td", null,
                                        React.createElement(Field, { as: "select", name: "localeMessages.".concat(index, ".locale"), className: "form-control", disabled: mode === MODE.INLINE }, filteredLocaleOptions.map(function (loc) { return (React.createElement("option", { key: loc, value: loc }, loc)); }))),
                                    React.createElement("td", null,
                                        React.createElement(Field, { as: "textarea", name: "localeMessages.".concat(index, ".message"), className: "form-control", rows: 2 }),
                                        React.createElement(ErrorMessage, { name: "localeMessages.".concat(index, ".message"), component: "div", className: "text-danger" })),
                                    React.createElement("td", { className: "text-end" },
                                        React.createElement(Button, { variant: "danger", size: "sm", onClick: function () {
                                                remove(index);
                                                // Check if removing the override allows editing the default message
                                                if (!values.localeMessages.some(function (msg) {
                                                    return jsToJavaLocale(msg.locale) === jsToJavaLocale(locale);
                                                })) {
                                                    setFieldValue('defaultMessage', message.defaultMessage);
                                                }
                                            } }, "Delete")))); }))),
                            React.createElement(Button, { variant: "primary", disabled: locale === javaToJsLocale(Locale.EN_US), onClick: function () {
                                    if (values.localeMessages.some(function (msg) {
                                        return msg.message === values.defaultMessage;
                                    })) {
                                        setError('Locale-specific messages cannot be identical to the default message.');
                                    }
                                    else {
                                        setError(null);
                                        push({ locale: locale, message: '' });
                                    }
                                } }, "Add Override")));
                    }),
                    React.createElement("div", { className: "mt-3 text-end" },
                        React.createElement(Button, { variant: "secondary", onClick: function () { return handleCloseModal(null); }, disabled: isSubmitting }, "Cancel"),
                        ' ',
                        React.createElement(Button, { type: "submit", variant: "primary", disabled: isSubmitting }, "Save"))));
            })))))));
};
