import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import {CELL_TYPES} from "../table/data-table-net/DataTableConstants.ts";

export default defineComponent(SampleRequetList);

function SampleRequetList() {

    this.attributes({
        url: "/admin/api/samplereq/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data:"name"
        });

        cols.push({
            title: "Email",
            type: "link",
            data:"emailLink"
        });

        cols.push({
            title: "City",
            type: "text",
            data:"city"
        });

        cols.push({
            title: "Phone",
            type: "text",
            data:"phone"
        });

        cols.push({
            title: "Postcode",
            type: "text",
            data:"postcode"
        });

        cols.push({
            title: "Royal Mail",
            type: "boolean",
            data: "hasRoyalMailEntry"
        });

        cols.push({
            title: "Ready",
            type: "boolean",
            data: "isNotDeclined"
        });

        cols.push({
            title: "Date Created",
            type: CELL_TYPES.TIMEBOX,
            data: "dateCreated",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Date Exported",
            type: "datetime",
            data: "dateExported"
        });

        cols.push({
            title: "External Reference",
            type: "text",
            data: "externalId"
        });

        cols.push({
            title: "Package",
            type: "text",
            data: "samplePackType"
        });

        cols.push({
            title: "Details",
            type: CELL_TYPES.SAMPLES_REQUEST_ACTION,
            data: "details"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeSample(x));
    };

    this.normalizeSample = function (sampleRequest_) {
        const sampleRequest = $.extend(true, {}, sampleRequest_);
        sampleRequest.override = sampleRequest.override || {};
        if (sampleRequest.state) {
            sampleRequest.override.tr = 'sample-sent';
        }
        sampleRequest.emailLink = {
            href: `mailto:${sampleRequest_.email}`,
            caption: sampleRequest_.email,
            target: "_blank"
        };
        // noinspection JSUnresolvedVariable
        const {postcode, phone, town, instructions} = sampleRequest_.deliveryAddress;
        sampleRequest.postcode = `${postcode}`;
        sampleRequest.phone = `${phone}`;
        sampleRequest.city = `${town}`;
        sampleRequest.instructions = `${instructions || ''}`;
        sampleRequest.details = '';
        // noinspection JSUnresolvedVariable
        sampleRequest.package = sampleRequest.packageType;
        sampleRequest.externalId = sampleRequest.sentReference ? sampleRequest.sentReference : '';
        // noinspection JSUnresolvedVariable,JSUnresolvedVariable
        sampleRequest.name = `${sampleRequest_.deliveryAddress.firstName} ${sampleRequest_.deliveryAddress.lastName}`;
        sampleRequest.isNotDeclined = sampleRequest_.state === 0 && !sampleRequest_.hasRoyalMailEntry;
        return sampleRequest;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allmergechanel"], title: "SampleRequetList.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "SampleRequetList.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allsampleschanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateJobLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const sampleRequest = data.data;
        if (sampleRequest.shopId !== mixam.shop.id) {
            return;
        }
        if (sampleRequest) {
            const index = this.data.list.map(x => x.id).indexOf(sampleRequest.id);

            if (verb === "UPDATE" || verb === "INSERT") {
                if (index !== -1) {
                    this.data.list[index] = sampleRequest;
                } else {
                    this.data.list.unshift(sampleRequest);
                }

            } else if (verb === "DELETE" && index !== -1) {
                this.data.list.splice(index, 1);
            }

            this.paint();
            requestAnimationFrame(() => $("#PK" + sampleRequest.id).hilightTableLine());
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(this.updateJobLine.bind(this));
    });
}
