export default {
    dateDelimiter: "/",
    monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    monthLongNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayLongNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    validationErrors: {
        '[id=billingAddress.postcode.US][pattern]': 'Please enter <strong>five digit zip code</strong>',
        '[id=deliveryAddress.postcode.US][pattern]': 'Please enter <strong>five digit zip code</strong>',
        '[id=additionalAddress.postcode.US][pattern]': 'Please enter <strong>five digit zip code</strong>',

        '[id=billingAddress.postcode.CA][pattern]': 'Please enter <strong>a valid Canadian post code (A9A 9A9)</strong>',
        '[id=deliveryAddress.postcode.CA][pattern]': 'Please enter <strong>a valid Canadian post code (A9A 9A9)</strong>',
        '[id=additionalAddress.postcode.CA][pattern]': 'Please enter <strong>a valid Canadian post code (A9A 9A9)</strong>',

        '[id=billingAddress.postcode.AU][pattern]': 'Please enter <strong>four digit postcode</strong>',
        '[id=deliveryAddress.postcode.AU][pattern]': 'Please enter <strong>four digit postcode</strong>',
        '[id=additionalAddress.postcode.AU][pattern]': 'Please enter <strong>four digit postcode</strong>',

        '[id=billingAddress.postcode.OTHER][pattern]': 'Please enter <strong>a valid postcode</strong> for your region',
        '[id=deliveryAddress.postcode.OTHER][pattern]': 'Please enter <strong>a valid postcode</strong> for your region',
        '[id=additionalAddress.postcode.OTHER][pattern]': 'Please enter <strong>a valid postcode</strong> for your region',

        '[name=username][required]': 'Please enter <strong>your email address</strong>',
        '[name=password][required]': 'Please enter <strong>your password</strong>',
        '[id=expiryMonth][required]': 'Please enter a valid <strong>Expiry Month</strong>',
        '[id=expiryYear][required]': 'Please enter a valid <strong>Expiry Year</strong>',
        '[name=billingAddress.phone]': 'Please enter a valid <strong>Phone number</strong> consisting of numbers only',
        '[name=additionalAddress.phone]': 'Please enter a valid <strong>Phone number</strong> consisting of numbers only',
        '[data-min-length]': '<strong>{field-label}</strong> must be $1 characters or more',
        '[data-must-match]': 'Password value must be identical to the first password value',
        '[required]': "Please enter a '{field-label}'"
    },
    onDirtyWindowUnload: "You have an unfinished file upload going on. Leaving this page will cancel the upload process.",
    currencySymbol: "£",
    currencyPrefixSymbol: "£",
    currencyPostfixSymbol: "",
    decimalSymbol: ".",
    digitGroupingSymbol: ",",
    weightSymbol: "kg",
    deliveryOption: {
        label_DHL_48_HOUR_TRACKED_zxa_2: "48 Hours Tracked",
    },
    santa: {
        bindChange: "Binding setting was changed to adapt to the number of pages",
        inkChange: "The colour was change to full-colour",
        coverChange: "Cover was added to adapt to 'Perfect Bound' binding",
        laminationChange: "Lamination was set to 'None' because the paper weight is less than 170GSM",
        pagesChange: "The minimal number of self covered pages is 8",
        paperChange: "Paper weight was set to '170GSM' because a lamination option was selected",
        paperWeightForced: "The type of paper ({from}gsm) that you are looking for is currently not available. We suggest using {to}gsm as an alternative.",
        paperWeightUpgrade: "Paper weight was changed to {to} gsm due to pages count change",
        minCopiesSewing: "A minimum number of {minimum} copies is required for sewn books",
        oneProductionDay: "One production day",
        zeroProductionDay: "Same day printing until 10AM",
        deliveryDatesEstimated: "Delivery dates are estimated.",
        totalWeightOfOrder: "Total weight of order:",
        deliveredToYouOn: "Estimated delivery on:",
        deliveredToYouOn2: "Estimated delivery on:",
        tryBeforeYouBuy: "Try before you buy",
        orderFreeTest: "Order Sample Test",
        postageFeesOnly: "Postage & handling fees:",
        bestValue: "Best Value",
        partialResults: "Partial Match",
        notExactly: "Attention! You can save money by printing on",
        notExactly2: "instead of",
        notExactly3: "",
        vatIncluded: "VAT included",
        yourDiscount: 'Your discount',
        fastest: "Priority",
        productionDays: "production days",
        printedOn: "Printed on a",
        digital: "Digital",
        litho: "Litho",
        inkjet: "inkjet",
        wideFormat: "wide format",
        addToCart: "Add to Cart",
        updateOrder: "Change to",
        permanentLink: "A permanent link to this quote",
        itemNotAvailable: "Item Not Available",
        pleaseTryAnother: "Please try another selection or ",
        errorContact: "contact",
        usForAssistance: "us for assistance",
        frontSide: "front",
        backSide: "back",
        outSide: "outside",
        inSide: "inside",
        shipDoorToDoor: "Price includes all shipping costs",
        printedIn: "Printed in",
        customSized: 'custom size',
        shoppingCart: {
            reprintAddedToCart: "Reprint added to cart",
            deliveryIncluded: "Free delivery",
            copies: "copies",
            total: "Total",
            delivery: "Delivery",
            vat: "VAT",
            tax: "Tax",
            zeroRated: "zero rated",
            addVat: "Add VAT",
            info: "Info",
            removeThisItem: "Remove this item",
            spineWidth: "Spine width",
            mm: "mm.",
            sides: "sides",
            pages: "pages",
            size: "size",
            gsm: "gsm",
            paper: "paper",
            kg: 'kg.',
            on: "On",
            in: "In",
            item: "item",
            discount: "Discount",
            discountOn: "Discount on",
            throughout: "Throughout",
            digitallyPrinted: "Digitally printed",
            inkjetPrinted: "Inkjet printed",
            wideFormatPrinted: "wide format printed",
            specialInksOnFront: "Special inks on front",
            specialInksOnBack: "Special inks on back",
            binding: "Binding",
            coatingOnFront: "Coating on front",
            coatingOnBack: "Coating on back",
            onTheFront: "on the front",
            onTheBack: "on the back",
            folded: "Folded",
            hardCover: "Hard Cover",
            dustJacket: "Dust Jacket",
            twoAdditionalPages: "(2 additional pages)",
            fourAdditionalPages: "(4 additional pages)",
            additionalPages: "additional pages",
            oneAdditionalPage: "(one additional page)",
            inLowerCase: "in",
            cover: "Cover",
            envelope: "Envelopes",
            inside: "inside",
            onTheOutside: "on the outside",
            specialInksOnCover: "Special inks on Cover",
            and: "and",
            endPaperColour: "Endpaper Colour",
            ribbonColour: "Ribbon Colour",
            headTailBand: "Head & Tail Band",
            foiling: "Foiling",
            sewing: "Sewing",
            coating: "Coating",
            outside: "(outside)",
            estimatedDeliveryDate: "Estimated delivery date",
            productionDays: "production days",
            fulfiler: "Fulfiler",
            pallets: "pallets",
            weightUnit: "kg.",
            onePallet: "one pallet",
            oneConsignment: "One consignment",
            consignments: "consignments",
            by: "by",
            item2: "Item",
            clearBag: "Clear Bag",
            backCover: "back cover",
            packaging: "Packaging",
            estimatedDeliveryDateOn: "Estimated delivery on",
            flyersAreVat: "Most Leaflets &amp; Flyers are VAT zero-rated although it does depend on the final use of the product.",
            bookletsAreVat: "Brochures &amp; Booklets are VAT zero-rated although it does depend on the final use of the product.",
            standardVatRate: "This item has a standard VAT rate. If you think it is mistakenly marked as such, please leave a remark.",
            vatZeroRated: "VAT: zero rated",
            emptyCart: "Empty cart",
            quoteReference: "Quote Reference"
        },
        offer: {
            producedIn: {
               "GB": 'Produced in the United Kingdom',
               "DE": 'Produced in Germany'
            },
        },
        display: {
            singlePriceText: "Price &  <span class='hidden-sm'>Estimated Delivery </span>date",
            multiPriceText: "Prices & <span class='hidden-sm'>Estimated Delivery </span>dates",
            offerText: "Our offer",
            priceText: "Price",
            turnaround: "delivered within {taroundMin} to {taroundMax} working days"
        },
        order: {
            itemToolbarPreview: "Preview",
            previewJob: "Preview Your Job",
            viewFinalPdf: "View final PDF",
            itemToolbarProof: "Proof",
            itemToolbarLeftToRight: "Left to Right (English, Latin)",
            itemToolbarRightToLeft: "Right to Left (Arabic, Hebrew)",
            itemToolbarShare: "Share",
            itemToolbarViewAsList: "View as list",
            itemToolbarViewAsBook: "View as book",
            downloadOriginals: "Download originals",
            itemToolbarDownload: "Download",
            itemToolbarAutoArrange: "Auto-arrange my artwork",
            itemToolbarClearAll: "Clear all uploaded files",
            showGuideLines: "Show guide lines",
            trimBoundaries: "Trim Lines",
            bleedBoundaries: "Bleed",
            bleedBoundariesAnd: "and",
            mm: "mm.",
            spineGutterBoundaries: "Gutter Margin",
            frontFaceBox: "frame boundary",
            showMore: "Show More",
            showLess: "Show Less",
            yourFileIsBeingProcessed: "Processing can take up to 15 minutes. Once ready, this page will update automatically.",
            thisFileWasCreatedByYou: 'This file was created by you, using our on-line studio. It is just',
            aLowResolutionPreview: 'a low-resolution preview of the original work',
            toUseThePrintQualityVersion: 'To use the print-quality version of the file',
            forAdditional: 'for additional',
            pleaseCheckTheBox: 'please check the box bellow:',
            usePrintQualityVersion: 'Use print-quality version',
            doubleSpread: 'Double-Spread',
            trimboxAdded: 'Trimbox Added',
            upscaleBleedAdded: 'Upscale Bleed Added',
            mirrorBleedAdded: 'Mirror Bleed Added',
            stretchBleedAdded: 'Stretch Bleed Added',
            pixelBleedAdded: 'Pixel bleed Added',
            coverSpread: 'Cover Spread',
            alternativeFileOriginal: 'Original',
            alternativeFileUpScale: 'Up-scaled Bleed',
            alternativeFileMirrored: 'Mirrored Bleed',
            alternativeFileStretched: "Stretch Bleed",
            alternativeFileAlternatives: 'Alternatives:',
            fileRenameOk: 'OK',
            fileRenameCancel: 'Cancel',
            fileRenameRenameFile: 'Rename file',
            aBleedBoxWasAddedAutomatically: 'A bleed-box was added automatically by scaling and allocating 1.5mm as bleed area. Please check all your pages for objects that might have been trimmed.',
            addedAutomaticallyByMirroring: 'A bleed-box was added automatically by mirroring each side and allocating 1.5mm as bleed area. Please check all your pages for objects that might have been trimmed.',
            thisFileContainsACoverSpread: 'This file contains a cover spread',
            fileInfoPages: 'pages',
            originalFileTitle: 'Original file',
            removeThisFile: 'Remove this file',
            preflightReport: 'Preflight report',

            alternativeDatesProductionDays: 'production days',
            alternativeDatesPrintedOn: 'Printed on',
            alternativeDatesPrintedOnDigital: 'Digital',
            alternativeDatesPrintedOnLitho: 'Litho',
            alternativeDatesPrintedOnInkjet: 'Inkjet',
            alternativeDatesYourChoice: 'Your Choice',
            alternativeDatesChange: 'Change',
            alternativeDatesSorry: 'Sorry!',
            alternativeDatesWeDoNotHave: 'We do not have available alternative offers for your kind of work.',
            chooseYourAddress: 'Choose your address',
            countDownHoursLeft: 'around {hours} hours',
            countDownMinutesLeft: '{minutes} minutes',
            insertBlankBefore: 'Insert a blank page before this page',
            insertBlankAfter: 'Insert a blank page after this page',
            rotateClockwise: 'Rotate this page 90 degree clockwise',
            rotateCounterClockwise: 'Rotate this page 90 degree counter clockwise',
            deleteThisPpage: 'Delete this page',
            cropImage: 'Crop image',
            cropCancel: 'Cancel',
            cropSave: 'OK',
            weAreSearchingForSuitable: 'We are searching for suitable delivery options. We will update the order and notify you by email as soon as we have the delivery details for you.',
            yourFileIsBeingDownloadedFrom: 'Your file is being downloaded from',
            pageIsUpdatedAutomaticallyAfterFileIsReady: 'This page will be updated automatically as soon as the file is ready. Processing usually takes 2 to 15 minutes. Your page will update once ready.',
        },
    },
    member: {
        memberStatus: "Status",
        memberOrderNumber: "Order Number",
        memberCreated: "Created",
        memberModified: "Modified",
        memberConfirmed: "Confirmed",
        memberArtwork: "Artwork",
        memberSettled: "Settled",
        memberRemarks: "Remarks",
        memberExpected: "Expected",
        memberSum: "Sum",
        pager: {
            page: "page",
            orders: "orders"
        },
        memberCancel: "Cancel",
        memberReorder: "Reorder",
        memberSelfPublish: "Self-Publish",
        memberProblem: "Report an Issue",
        memberContact: "Contact us to reorder",
        memberView: "View this quote",
        memberYourOrder: "Your Order",
        memberYourShoppingCart: "Your shopping cart",
        memberCheckingAvailability: "Checking Availability...",
        memberGetStarted: "Get Started!",
        addReorderItem: "Add to cart",
        memberVat: "VAT",
        memberDelivery: "Delivery",
        memberTotal: "Total",
        memberAccount: "Account",
        memberTargetAccount: "target account email",
        memberCreateAccount: "Create an Account",
        memberSignin: "Sign-in",
        memberYouDidNot: "you don't have any current or previous orders",
        memberAsSoon: "Once you create an order, it will appear in this area.",
        INIT: "Initiated",
        PENDING: "Waiting for artwork & payment",
        RIPE: "Waiting for customer confirmation",
        ONHOLD: "On Hold (Action Required)",
        CONFIRMED: "Confirmed",
        INPRODUCTION: "Sent to Production",
        ACCEPTED: "In Production",
        DISPATCHED: "Dispatched",
        CLOSED: "Closed",
        CANCELED: "Cancelled",
        problemCategoryType: {
            placeholder: "Choose a category",
            otherIssue: "Other issue",
            packageDidNotArrive: "Package did not arrive",
            missingItems: "Missing items",
            wrongOrder: "I received a wrong order",
            arrivedDamaged: "Damaged or defective item",
            deliveryIssue: "Delivery issue",
            bindingIssue: "Binding issue",
            colourVariance: "The Colours are different than what i expected",
            inkMarks: "Ink marks on the product",
            finishQuality: "Finish quality issues",
            printingQuality: "Print quality issues",
            marks: "Roller marks/Banding/Stripes",
            shortage: "Shortage of copies",
            trimmedIncorrectly: "Trimmed incorrectly",
            incorrectPaper: "Incorrect paper",
            incorrectPageOrder: "Incorrect page order",
            whiteStripsOnEdges: "White strips on page edges",
            unknownContent: "Unknown images or text visible on page",
            differentToWhatIOrdered: "Different to what I ordered",
        },
        problemAction: {
            none: "No action",
            discussion: "Discuss the matter",
            reprint: "Request a re-print",
            discount: "Request a discount",
            refund: "Request a full refund",
        },
        publication: {
            title: "Publication Title",
            orderDate: "Order Date",
            orderStatus: "Order Status",
            totalCost: "Total Cost",
            customerName: "Customer Name",
            mixamCost: "Mixam Cost",
            buyerPrice: "Buyer Price",
            yourRevenue: "Your Revenue"
        }
    },
    dispatch: {
        weightUnit: "kg.",
        paperUnit: "gsm",
        pallets: "Pallets",
        parcels: "Parcels"
    },
    quote: {
        thankYou: "Thank you",
        thankYouForYour: "Thank you for your request.",
        yourRequest: "Your request has been received.",
        ourCustomerService: "Our customer service person will contact you as soon as possible.",
        home: "Home",
        theQuoteWas: "The quote was sent to you.",
    },
    contact: {
        thankYou: "Thank you",
        yourRequest: "Your message has been received.",
        ourCustomerService: "Our team will contact you as soon as possible.",
        home: "Home",
    },
    priceType: {
        SUPERSAVER: "supersaver",
        STANDARD: "",
        EXPRESS: "express"
    },
    datastore: {
        localstorageNotSupported: "Your local storage is not accessible in private browsing mode."
    },
    address: {
        postcodeMinLengthError: '<strong>Postcode</strong> must be 6 characters or more',
        overseasDelivery: 'Please note International orders including EU may include additional customs and excise charges. _shopname_ is not responsible for any customs and excise charges that may occur from the import or export of your goods.'
    },
    shoppingCart: {
        itemDeletedWarning: "Item was deleted. <a id={id} href=#>Undo</a>"
    },
    chat: {
        chatWithUs: "Chat with us",
        wouldYouLike: `Would you like to have a chat with a <strong>_shopname_</strong> customer service team member?<br>Type a message below and hit <em>Enter</em>`
    },
    paper: {
        bodyWeightNoLongerAvailable: 'The selected body paper weight (_weight_gsm) is no longer available',
        coverWeightNoLongerAvailable: 'The selected cover paper weight (_weight_gsm) is no longer available'
    },
    messages: {
        "1025": {
            type: "info",
            body: "Your payment was processed successfully.",
            interval: 10000
        },
        "1026": {
            type: "info",
            body: "A blank page was inserted. <a data-type='undo' href=#>Undo</a>",
            interval: 20000
        },
        "1027": {
            type: "info",
            body: "The page was deleted. <a data-type='undo' href=#>Undo</a>",
            interval: 20000
        },
        "1028": {
            type: "error",
            body: "Our server could not complete the task. Please try again later.",
            interval: 10000
        },
        "1029": {
            type: "info",
            body: "Undo done",
            interval: 1
        },
        "1030": {
            type: "info",
            body: "Page order was saved. <a data-type='undo' href=#>Undo</a>",
            interval: 20000
        },
        "1031": {
            type: "info",
            body: "Page order was reverted. <a data-type='undo' href=#>Undo</a>",
            interval: 20000
        },
        "1032": {
            type: "success",
            body: "The order has been cancelled.",
            interval: 10000
        },
        "1033": {
            type: "failure",
            body: "Error while canceling the order. Please try again later.",
            interval: 20000
        },
        "1034": {
            type: "success",
            body: "Reading order was successfully changed.",
            interval: 20000
        },
        "1035": {
            type: "error",
            body: "Failed to obtain item data from Mixam. Please try later.",
            interval: 20000
        },
        "1036": {
            type: "error",
            body: "Error while loading order.",
            interval: 10000
        },
        "1037": {
            type: "success",
            body: "VAT was not changed",
            interval: 10000
        },
        "1038": {
            type: "normal",
            body: "Generating a proof file. The process might take several minutes.",
            interval: 10000
        },
        "1039": {
            type: "success",
            body: "Data was saved to your google drive",
            interval: 10000
        },
        "1040": {
            type: "error",
            body: "Action canceled. You may not move inner front or back pages",
            interval: 10000
        },
        "1042": {
            type: "success",
            body: "VAT was added",
            interval: 10000
        },
        "1043": {
            type: "success",
            body: "VAT was removed",
            interval: 10000
        },
        "1045": {
            type: "normal",
            body: "Only black print is available on fluorescent coloured paper",
            interval: 10000
        },
        "1046": {
            type: "normal",
            body: "Spot colour is available only on 90gsm paper",
            interval: 10000
        },
        "1047": {
            type: "normal",
            body: "Spot UV is available only on 300gsm  silk paper on one side only",
            interval: 10000
        },
        "1048": {
            type: "normal",
            body: "Spot colour is available only on 170, 250 and 300gsm silk or gloss paper",
            interval: 10000
        },
        "1049": {
            type: "normal",
            body: "Weatherproof Vinyl is not available in this size",
            interval: 10000
        },
        "1050": {
            type: "normal",
            body: "Generating a zip file. The process might take several minutes.",
            interval: 10000
        },
        "1051": {
            type: "normal",
            body: "450gsm business card must be laminated",
            interval: 10000
        },
        "1052": {
            type: "error",
            body: "A3 calendar is available only in portrait orientation",
            interval: 10000
        },
        "1053": {
            type: "error",
            body: "We were unable to locate your postcode. Please verify you have the correct code.",
            interval: 10000
        },
        "1054": {
            type: "error",
            body: "A7 booklets are available only in portrait orientation",
            interval: 10000
        },
        "1055": {
            type: "error",
            body: "You must select at least one shop.",
            interval: 10000
        },
        "1056": {
            type: "normal",
            body: "Please note: Lamination is available only for 'Cover' type paper",
            interval: 10000
        },
        "1057": {
            type: "error",
            body: "Unable to fetch available delivery services list",
            interval: 10000
        },
        "1058": {
            type: "normal",
            body: "Thank you for your question. We will respond as soon as we are able.",
            interval: 10000
        },
        "1059": {
            type: "success",
            body: "Publication settings changed successfully.",
            interval: 10000
        },
    },
    icons: {
        orientation: {
            0: "mx-portrait",
            1: "mx-landscape"
        },
        bind: {
            0: "mx-saddle",
            2: "mx-perfect",
            4: "mx-pur",
            6: "mx-casebind",
            8: "mx-wiro",
            10: "mx-loop",
            16: "mx-wiro"
        }
    },
    ordinal: ['', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh',
        'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth', 'twentieth',
        'twenty-first', 'twenty-second', 'twenty-third', 'twenty-fourth', 'twenty-fifth', 'twenty-sixth', 'twenty-seventh',
        'twenty-eighth', 'twenty-ninth', 'thirtiet', 'thirty-first', 'thirty-second', 'thirty-third', 'thirty-fourth',
        'thirty-fifth', 'thirty-sixth', 'thirty-seventh', 'thirty-eighth', 'thirty-ninth', 'fortieth'],
    admin: {
        'mostViewed': 'Most Viewed',
    },
    dictionary: {
        copies: function (value) {
            return (+value).formatNumber(0);
        },
        packaging: {
            "0": '',
            "1": 'Clear bag',
        },
        backCover: {
            "0": '',
            "1": 'Yes',
        },
        colors: {
            "0": "no printing",
            "1": "black & white",
            "5": "colour"
        },
        colors2: {
            "0": "no printing",
            "1": "black & white",
            "5": "colour"
        },
        coverColors: {
            "0": "no printing",
            "1": "black & white",
            "5": "colour"
        },
        coverColors2: {
            "0": "no printing",
            "1": "black & white",
            "5": "colour"
        },
        dustJacketColors: {
            "0": "no printing",
            "1": "black & white",
            "5": "colour"
        },
        format: {
            "1": "A1",
            "2": "A2",
            "3": "A3",
            "4": "A4",
            "5": "A5",
            "6": "A6",
            "7": "A7"
        },
        secondaryFormat: {
            "1": "Novel (203mm x 127mm)",
            "2": "Standard (197mm x 132mm)",
            "3": "Demy (216mm x 138mm)",
            "4": "US Royal (229mm x 152mm)",
            "5": "Royal (234mm x 156mm)",
            "6": "210mm Square",
            "7": "210mm x 280mm",
            "8": "Dl Small 100",
            "9": "DL 105mm x 210mm",
            "10": "A5 Long (105mm x 297mm)",
            "11": "148mm Square",
            "12": "105mm Square",
            "13": "120mm Square",
            "14": "A1 Long",
            "15": "A2 Long",
            "16": "DL Small",
            "17": "B1",
            "18": "B2",
            "19": "183mm x 273mm",
            "20": "150mm x 265mm",
            "21": "100mm Square",
            "22": "55mm x 85mm",
            "23": "DL",
            "24": "C5",
            "25": "C4",
            "26": "C6",
            "27": "Square 155",
            "28": "Square 170",
            "29": "DL large",
            "30": "50mm x 90mm",
            "31": "54mm x 86mm",
            "32": "55mm Square",
            "33": "B5",
            "34": "B4",
            "35": "98mm Square",
            "36": "A6 Long",
            "37": "A7 Long",
            "38": "B0",
            "39": "170mm x 590mm",
            "40": "Large format",
            "41": "A7 mini",
            "42": "170mm x 240mm",
            "43": "508mm x 762mm",
            "44": "762mm x 1016mm",
            "45": "1016mm x 1524mm",
            "46": '5.5" x 8.5"',
            "47": '8.5" x 11"',
            "48": '8.5" x 22"',
            "49": '4.25" x 5.5"',
            "50": '4" x 6"',
            "51": '5" x 7"',
            "52": '6" x 9"',
            "53": '8" x 10"',
            "54": '6" x 11"',
            "55": '11" x 17"',
            "56": '6" x 6"',
            "57": '12" x 12"',
            "58": '4.75" x 4.75"',
            "59": '12" x 18"',
            "60": '13" x 19"',
            "61": '18" x 24"',
            "62": '19" x 27"',
            "63": '24" x 36"',
            "64": '26" x 39"',
            "65": '8.5" x 14"',
            "66": 'UK Standard (157mm x 240mm)',
            "67": 'US Standard (170mm x 260mm)',
            "68": 'Manga Standard (127mm x 191mm)',
            "69": '5" x 8"',
            "70": '5.06" x 7.81"',
            "71": '5.25" x 8"',
            "72": '6.14" x 9.21"',
            "73": '6.69" x 9.61"',
            "74": '7" x 10"',
            "75": '7.44" x 9.69"',
            "76": '7.5" x 9.25"',
            "77": '6" x 8.25"',
            "78": '8.25" x 8.25"',
            "79": '8.5" x 8.5"',
            "80": '7" x 7"',
            "81": "140mm Square",
            "82": "B6 (125mm x 176mm)",
            "83": 'US Trade 6" x 9"',
            "84": 'Business card 3.5" x 2"',
            "85": 'Postcard 5.5" x 3.5"',
            "86": 'Postcard 4.25" x 6"',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": "B format UK",
            "91": "Pinched Crown Quarto",
            "92": "Crown Quarto",
            "93": "280 Square",
            "94": '7.25" x 9.5"',
            "95": 'Pocket Book 4.25" x 6.87"',
            "96": 'Small Square 7.5"',
            "97": 'Quarto 9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": '254mm x 254mm',
            "120": '300mm x 300mm',
            "121": '89mm x 216mm',
            "122": '108mm x 280mm',
            "123": '216mm x 178mm',
            "124": '216mm x 191mm',
            "125": '229mm x 305mm',
            "126": '229mm x 406mm',
            "127": '216mm x 432mm',
            "128": '292mm x 445mm',
            "129": '432mm x 559mm',
            "130": '279mm x 648mm',
            "131": '203mm x 305mm',
            "132": '254mm x 381mm',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '8.375" x 10.875"',
            "142": '5.375" x 8.375"',
            "143": '5.25" x 8.375"',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },
        secondaryFormatSize: {
            "1": "203mm x 127mm",
            "2": "197mm x 132mm",
            "3": "216mm x 138mm",
            "4": "229mm x 152mm",
            "5": "234mm x 156mm",
            "6": "210mm X 210mm",
            "7": "210mm x 280mm",
            "8": "100mm x 210mm",
            "9": "105mm x 210mm",
            "10": "105mm x 297mm",
            "11": "148mm x 148mm",
            "12": "105mm x 105mm",
            "13": "120mm x 120mm",
            "14": "420mm x 1188mm",
            "15": "297mm x 840mm",
            "16": "99mm x 210mm",
            "17": "707mm x 1000mm",
            "18": "500mm x 707mm",
            "19": "183mm x 273mm",
            "20": "150mm x 265mm",
            "21": "100mm x 100mm",
            "22": "55mm x 85mm",
            "23": "220mm x 110mm",
            "24": "229mm x 162mm",
            "25": "229mm x 324mm",
            "26": "162mm x 114mm",
            "27": "155mm x 155mm",
            "28": "170mm x 170mm",
            "29": "229mm x 114mm",
            "30": "50mm x 90mm",
            "31": "54mm x 86mm",
            "32": "55mm x 55mm",
            "33": "175mm x 245mm",
            "34": "248mm x 346mm",
            "35": "98mm x 98mm",
            "36": "74mm x 210mm",
            "37": "52mm x 149mm",
            "38": "1000mm x 1414mm",
            "39": "170mm x 590mm",
            "40": "1185mm x 1750mm",
            "41": "74mm x 98mm",
            "42": "170mm x 240mm",
            "43": "508mm x 762mm",
            "44": "762mm x 1016mm",
            "45": "1016mm x 1524mm",
            "46": '140mm x 216mm"',
            "47": '216mm x 279mm',
            "48": '559mm x 216mm',
            "49": '108mm x 140mm',
            "50": '102mm x 152mm',
            "51": '127mm x 178mm',
            "52": '152mm x 229mm',
            "53": '203mm x 254mm',
            "54": '152mm x 279mm',
            "55": '279mm x 432mm',
            "56": '152mm x 152mm',
            "57": '305mm x 305mm',
            "58": '121mm x 121mm',
            "59": '305mm x 457mm',
            "60": '330mm x 483mm',
            "61": '457mm x 610mm',
            "62": '483mm x 686mm',
            "63": '610mm x 914mm',
            "64": '660mm x 991mm',
            "65": '216mm x 356mm',
            "66": '157mm x 240mm',
            "67": '170mm x 260mm',
            "68": '127mm x 191mm',
            "69": '127mm x 203mm',
            "70": '129mm x 198mm',
            "71": '133mm x 203mm',
            "72": '156mm x 234mm',
            "73": '170mm x 244mm',
            "74": '178mm x 254mm',
            "75": '189mm x 246mm',
            "76": '191mm x 235mm',
            "77": '210mm x 152mm',
            "78": '210mm x 210mm',
            "79": '216mm x 216mm',
            "80": '178mm x 178mm',
            "81": '140mm x 140mm',
            "82": "125mm x 176mm",
            "83": '6" x 9"',
            "84": '3.5" x 2"',
            "85": '5.5" x 3.5"',
            "86": '4.25" x 6"',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": '12" x 12"',
            "91": '246mm x 171mm',
            "92": '246mm x 189mm',
            "93": '280mm x 280mm',
            "94": '7.25" x 9.5"',
            "95": '4.25" x 6.87"',
            "96": '7.5" x 7.5"',
            "97": '9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": '254mm x 254mm',
            "120": '300mm x 300mm',
            "121": '89mm x 216mm',
            "122": '108mm x 280mm',
            "123": '216mm x 178mm',
            "124": '216mm x 191mm',
            "125": '229mm x 305mm',
            "126": '229mm x 406mm',
            "127": '216mm x 432mm',
            "128": '292mm x 445mm',
            "129": '432mm x 559mm',
            "130": '279mm x 648mm',
            "131": '203mm x 305mm',
            "132": '254mm x 381mm',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '213mm x 276mm',
            "142": '137mm x 213mm',
            "143": '133mm x 213mm',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },

        secondaryFormatName: {
            "1": "Novel",
            "2": "Standard",
            "3": "Demy",
            "4": "US Royal",
            "5": "Royal",
            "6": "Square",
            "7": "",
            "8": "DL Small 100",
            "9": "DL",
            "10": "A5 Long",
            "11": "Square",
            "12": "Square",
            "13": "Square",
            "14": "A1 Long",
            "15": "A2 Long",
            "16": "DL Small",
            "17": "B1",
            "18": "B2",
            "19": "",
            "20": "",
            "21": "Square",
            "22": "",
            "23": "DL",
            "24": "C5",
            "25": "C4",
            "26": "C6",
            "27": "Square",
            "28": "Square",
            "29": "DL large",
            "30": "",
            "31": "",
            "32": "Square",
            "33": "B5",
            "34": "B4",
            "35": "Square",
            "36": "A6 Long",
            "37": "A7 Long",
            "38": "B0",
            "39": "",
            "40": "Large format",
            "41": "A7 mini",
            "42": "170mm x 240mm",
            "43": "20in x 30in",
            "44": "30in x 40in",
            "45": "40in x 60in",
            "46": '5.5" x 8.5"',
            "47": '8.5" x 11"',
            "48": '8.5" x 22"',
            "49": '4.25" x 5.5"',
            "50": '4" x 6"',
            "51": '5" x 7"',
            "52": '6" x 9"',
            "53": '8" x 10"',
            "54": '6" x 11"',
            "55": '11" x 17"',
            "56": "Square",
            "57": "Square",
            "58": "Square",
            "59": '12" x 18"',
            "60": '13" x 19"',
            "61": '18" x 24"',
            "62": '19" x 27"',
            "63": '24" x 36"',
            "64": '26" x 39"',
            "65": '8.5" x 14"',
            "66": 'UK Standard',
            "67": 'US Standard',
            "68": 'Manga Standard',
            "69": '5" x 8"',
            "70": '5.06" x 7.81"',
            "71": '5.25" x 8"',
            "72": '6.14" x 9.21"',
            "73": '6.69" x 9.61"',
            "74": '7" x 10"',
            "75": '7.44" x 9.69"',
            "76": '7.5" x 9.25"',
            "77": '6" x 8.25"',
            "78": "Square",
            "79": "Square",
            "80": "Square",
            "81": "Square",
            "82": "B6",
            "83": 'US Trade',
            "84": 'Business card',
            "85": 'Postcard',
            "86": 'Postcard',
            "87": '8" x 11"',
            "88": '11" x 14"',
            "89": '8" x 8"',
            "90": "B format UK",
            "91": "Pinched Crown Quarto",
            "92": "Crown Quarto",
            "93": "280 Square",
            "94": '7.25" x 9.5"',
            "95": 'Pocket Book 4.25" x 6.87"',
            "96": 'Small Square 7.5"',
            "97": 'Quarto 9.5" × 12"',
            "98": '7" × 9"',
            "99": '200mm x 200mm',
            "100": '200mm x 250mm',
            "101": '200mm x 300mm',
            "102": '200mm x 600mm',
            "103": '300mm x 300mm',
            "104": '300mm x 400mm',
            "105": '300mm x 600mm',
            "106": '300mm x 1000mm',
            "107": '400mm x 400mm',
            "108": '400mm x 500mm',
            "109": '400mm x 600mm',
            "110": '500mm x 500mm',
            "111": '500mm x 600mm',
            "112": '500mm x 700mm',
            "113": '500mm x 750mm',
            "114": '500mm x 1000mm',
            "115": '600mm x 600mm',
            "116": '600mm x 750mm',
            "117": '600mm x 800mm',
            "118": '750mm x 1000mm',
            "119": 'Square',
            "120": 'Square',
            "121": '3.5" x 8.5"',
            "122": '4.25" x 11"',
            "123": '7" x 8.5"',
            "124": '7.5" x 8.5"',
            "125": '9" x 12"',
            "126": '9" x 16"',
            "127": '8.5" x 17"',
            "128": '11.5" x 17.5"',
            "129": '17" x 22"',
            "130": '11" x 25.5"',
            "131": '8" x 12"',
            "132": '10" x 15"',
            "133": '5.5" x 8.5"',
            "134": '3.5" x 8.5"',
            "135": '4" x 9"',
            "136": '2" x 8"',
            "137": '3.66" x 4.25"',
            "138": '3" x 4"',
            "139": '4" x 4"',
            "140": '9.25" x 5.8"',
            "141": '8.375" x 10.875"',
            "142": '5.375" x 8.375"',
            "143": '5.25" x 8.375"',
            "144": '8.27" x 11.69"',
            "145": '6.63" x 10.25"',
            "146": '20mm x 20mm',
            "147": '21mm x 21mm',
            "148": '25mm x 25mm',
            "149": '10mm x 30mm',
            "150": '30mm x 30mm',
            "151": '15mm x 35mm',
            "152": '35mm x 35mm',
            "153": '35mm x 105mm',
            "154": '35mm x 210mm',
            "155": '35mm x 316mm',
            "156": '20mm x 40mm',
            "157": '40mm x 40mm',
            "158": '15mm x 45mm',
            "159": '45mm x 45mm',
            "160": '48mm x 70mm',
            "161": '20mm x 50mm',
            "162": '25mm x 50mm',
            "163": '40mm x 50mm',
            "164": '50mm x 50mm',
            "165": '51mm x 298mm',
            "166": '51mm x 420mm',
            "167": '55mm x 85mm',
            "168": '60mm x 60mm',
            "169": '21mm x 68mm',
            "170": '70mm x 70mm',
            "171": '71mm x 96mm',
            "172": '80mm x 80mm',
            "173": '45mm x 95mm',
            "174": '95mm x 95mm',
            "175": '95mm x 145mm',
            "176": '98mm x 420mm',
            "177": '120mm x 125mm',
            "178": '140mm x 297mm',
            "179": '124mm x 140mm',
            "180": '98mm x 210mm',
            "181": '6.875" x 10.25"',
            "182": '7.375" x 10.25"',
            "183": '8.75" x 11"',
            "184": '6.63" x 10.25"'
        },
        paper: {
            "0": "90",
            "1": "105",
            "2": "115",
            "3": "130",
            "4": "150",
            "5": "170",
            "6": "225",
            "7": "250",
            "8": "300",
            "9": "350",
            "13": "400",
            "14": "200",
            "15": "450",
            "16": "240",
            "50": "80",
            "100": "90",
            "101": "105",
            "102": "115",
            "103": "130",
            "104": "150",
            "105": "170",
            "106": "225",
            "107": "250",
            "108": "300",
            "109": "350",
            "110": "80",
            "111": "100",
            "112": "120",
            "113": "200",
            "114": "74",
            "115": "104",
            "116": "80",
            "120": "70",
            "5001": "135",
            "6001": "120",
            "4001": "270",
            "4002": "350",
            "5002": "400",
            "6002": "300",
            "7001": "80",
            "7002": "170",
            "7003": "250",
            "7004": "350",
            "7005": "300",
            "7006": "100",
            "7007": "120",
            "7008": "135",
            "7009": "150",
            "7010": "190",
            "8001": "80",
            "8002": "170",
            "8003": "250",
            "8004": "350",
            "8005": "300",
            "8006": "120",
            "8007": "100",
            "8008": "135",
            "9001": "80",
            "10001": "115",
            "11001": "280",
            "11002": "350",
            "32001": "520",
            "45001": "80",
            "45002": "90",
            "45003": "100",
            "45004": "120",
            "45005": "135",
            "45006": "150",
            "45007": "170",
            "45008": "190",
            "45009": "250",
            "45010": "300",
            "45011": "350",
            "55000": "260",
            "60000": "74",
            "60001": "80",
            "61000": "67",
            "62000": "74",
            "63000": "80"
        },

        paperFinish: {
            "1": "Silk",
            "2": "Gloss",
            "3": "Uncoated",
            "4": "Colorplan",
            "5": "Heaven 42",
            "6": "Original Gmund",
            "7": "Recycled Uncoated",
            "8": "Recycled Natural",
            "9": "Fluorescent",
            "10": "Blueback (poster paper)",
            "11": "Postcard Board",
            "12": "Linen",
            "13": "Transparent",
            "14": "Coloured Offset",
            "15": "SBS Board",
            "16": "CHROMOLUX Pearl",
            "17": "Silverstar",
            "18": "Board with Metallic Effect",
            "19": "Board with Peelable White Sticker",
            "20": "Polypropylene Translucent",
            "21": "Wood Pulp Board",
            "22": "White Multilayer",
            "23": "Plastic vinyl",
            "24": "600µm PVC card Silk",
            "25": "600µm PVC card Gloss",
            "26": "gold paper",
            "27": "50µm Stafix® white electrostatic self-adhesive film",
            "28": "210µm backlit film",
            "29": "Glossy photo paper",
            "30": "Polypropylene twin-wall sheet, 2.5mm",
            "31": "Cardboard with film coating",
            "32": "Weatherproof Vinyl",
            "33": "Lux",
            "34": "Tearproof",
            "35": "Cream",
            "36": "Tintoretto Gesso",
            "37": "River Dot",
            "38": "Rives Tweed",
            "39": "Kraft",
            "40": "Magnecote",
            "41": "Ice Gold",
            "42": "Fresco Gesso",
            "43": "Rives Shetland",
            "44": "Conqueror Gold",
            "45": "Recycled Silk",
            "56": "Polyester",
            "57": "Créme",
            "58": "Groundwood",
            "59": "Matt",
            "60": "Uncoated Natural"
        },

        coverType: {
            "0": "No Cover (Self Cover)",
            "3": "130",
            "4": "150",
            "5": "170",
            "6": "225",
            "7": "250",
            "8": "300",
            "9": "350",
            "11": "74",
            "12": "104",
            "13": "400",
            "14": "200",
            "16": "240",
            "104": "150",
            "105": "170",
            "106": "225",
            "107": "250",
            "108": "300",
            "109": "350",
            "110": "80",
            "111": "100",
            "112": "120",
            "113": "200",
            "114": "74",
            "4001": "270",
            "4002": "350",
            "5002": "400",
            "6002": "300",
            "7001": "80",
            "7002": "170",
            "7003": "250",
            "7004": "350",
            "7005": "300",
            "7006": "100",
            "7007": "120",
            "7008": "135",
            "7009": "150",
            "7010": "190",
            "8001": "80",
            "8002": "170",
            "8003": "250",
            "8004": "350",
            "8005": "300",
            "8006": "120",
            "8007": "100",
            "8008": "135",
            "9001": "80",
            "10001": "115",
            "11001": "280",
            "11002": "350",
            "32001": "520",
            "45001": "80",
            "45002": "90",
            "45003": "100",
            "45004": "120",
            "45005": "135",
            "45006": "150",
            "45007": "170",
            "45008": "190",
            "45009": "250",
            "45010": "300",
            "45011": "350",
            "62000": "74",
            "62001": "104"
        },
        orientation: {
            "0": "portrait",
            "1": "landscape"

        },
        coverPaperFinish: {
            "1": "Silk",
            "2": "Gloss",
            "3": "Uncoated",
            "4": "Colorplan",
            "5": "Heaven 42",
            "6": "Original Gmund",
            "7": "Recycled Uncoated",
            "8": "Recycled Natural",
            "9": "Fluorescent",
            "10": "Blueback (poster paper)",
            "11": "Postcard Board",
            "12": "Linen",
            "13": "Transparent",
            "14": "Coloured Offset",
            "45": "Recycled Silk",
        },
        coat0: {
            "0": "None",
            "5": "Matt lamination",
            "4": "Gloss lamination",
            "6": "Soft Touch lamination",
            "7": "UV Matt",
            "8": "UV Gloss",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Matt anti-scuff"
        },
        coat1: {
            "0": "None",
            "5": "Matt lamination",
            "4": "Gloss lamination",
            "6": "Soft Touch lamination",
            "7": "UV Matt",
            "8": "UV Gloss",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Matt anti-scuff"
        },
        coat2: {
            "0": "None",
            "5": "Matt lamination",
            "4": "Gloss lamination",
            "6": "Soft Touch lamination",
            "7": "UV Matt",
            "8": "UV Gloss",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Matt anti-scuff"
        },
        coat3: {
            "0": "None",
            "5": "Matt lamination",
            "4": "Gloss lamination",
            "6": "Soft Touch lamination",
            "7": "UV Matt",
            "8": "UV Gloss",
            "10": "Spot UV",
            "12": "Spot 3D UV",
            "14": "Matt lamination with Spot UV",
            "15": "Matt anti-scuff"
        },
        bind: {
            "0": "saddle stitched",
            "2": "perfect bound",
            "4": "perfect bound PUR Binding",
            "6": "Case Binding",
            "8": "Wiro Binding",
            "10": "Loop Binding",
            "12": "Calendar Wiro Binding",
            "16": "Spiral Binding"
        },
        bindColor: {
            "0": "Black",
            "1": "White",
            "2": "Silver"
        },
        bindLoops: {
            "0": "Two loops",
            "1": "Four loops"
        },
        endPaperColor: {
            "0": "Stock White",
            "1": "Stock Black",
            "10": "Adriatic",
            "11": "Amethyst",
            "12": "Azure Blue",
            "13": "Brown",
            "14": "Bitter Chocolate",
            "15": "Bright Red",
            "16": "Bright White",
            "17": "Candy Pink",
            "18": "China White",
            "19": "Citrine",
            "20": "Claret",
            "21": "Cobalt",
            "22": "Cool Blue",
            "23": "Cool Grey",
            "24": "Dark Grey",
            "25": "Ebony",
            "26": "Emerald",
            "27": "Factory Yellow",
            "28": "Forest",
            "29": "Fuschia Pink",
            "30": "Harvest",
            "31": "Hot Pink",
            "32": "Ice White",
            "33": "Imperial Blue",
            "34": "Lavender",
            "35": "Lockwood Green",
            "36": "Mandarin",
            "37": "Marrs Green",
            "38": "Mid Green",
            "39": "Mist",
            "40": "Natural",
            "41": "New Blue",
            "42": "Nubuck Brown",
            "43": "Pale Grey",
            "44": "Park Green",
            "45": "Pistachio",
            "46": "Powder Green",
            "47": "Pristine White",
            "48": "Purple",
            "49": "Racing Green",
            "50": "Real Grey",
            "51": "Royal Blue",
            "52": "Sapphire",
            "53": "Scarlet",
            "54": "Smoke",
            "55": "Sorbet Yellow",
            "56": "Stone",
            "57": "Tabriz Blue",
            "58": "Turquoise",
            "59": "Vellum White",
            "60": "Vermillion",
            "61": "White Frost",
        },
        ribbonColor: {
            "0": "None",
            "1": "White",
            "2": "Ivory",
            "3": "Gold",
            "4": "Orange",
            "5": "Bright Red",
            "6": "Maroon",
            "7": "Baby Pink",
            "8": "Purple",
            "9": "Light Blue",
            "10": "Baby Blue",
            "11": "Navy Blue",
            "12": "Fresh Green",
            "13": "Dark Green",
            "14": "Grey",
            "15": "Black"
        },
        headTailBand: {
            "0": "None",
            "1": "Navy Blue & White",
            "2": "Black & Green",
            "3": "Red & Black",
            "4": "Purple & White",
            "5": "Red & Grey",
            "6": "Red & White",
            "7": "Yellow & Brown",
            "8": "Dark Brown & White",
            "9": "Blue & Yellow",
            "10": "Red & Green",
            "11": "Yellow & Green",
            "12": "Blue & White",
            "13": "Dark Blue & White",
            "14": "Red & Yellow",
            "15": "Maroon & White",
            "16": "Yellow & Black",
            "17": "Green & White",
            "18": "Black & White",
            "19": "Dark Green & Black",
            "20": "Dark Green & White",
            "21": "Red",
            "22": "Black",
            "23": "Grey",
            "24": "White",
            "25": "Dark Blue",
            "26": "Yellow"
        },
        sewing: {
            "0": "No",
            "1": "Yes",
        },
        simpleFold: {
            "1": "Half fold",
            "2": "Roll fold",
            "3": "Z fold",
            "4": "Gate fold",
            "5": "Cross fold",
            "6": "Double parallel fold"
        },
        delivery: {
            "0": "folded",
            "1": "flat"
        },
        paperFinishColor: {
            "0": "",
            "4001": "Bitter Chocolate",
            "4002": "Bright Red",
            "4003": "Claret",
            "4004": "Dark Grey",
            "4005": "Ebony",
            "4006": "Factory Yellow",
            "4007": "Imperial Blue",
            "4008": "Racing Green",
            "4009": "White Frost",
            "5001": "Heaven 42",
            "6001": "10% Cotton Blanc Tactile",
            "9001": "Yellow",
            "9002": "Green",
            "9003": "Red",
            "14001": "Cherry Red",
            "14002": "Cobalt Blue",
            "14003": "May Green",
            "14004": "Sun Yellow"
        },

        coverPaperFinishColor: {
            "0": "",
            "4001": "Bitter Chocolate",
            "4002": "Bright Red",
            "4003": "Claret",
            "4004": "Dark Grey",
            "4005": "Ebony",
            "4006": "Factory Yellow",
            "4007": "Imperial Blue",
            "4008": "Racing Green",
            "4009": "White Frost",
            "5001": "Heaven 42",
            "6001": "10% Cotton Blanc Tactile",
            "9001": "Yellow",
            "9002": "Green",
            "9003": "Red",
            "14001": "Cherry Red",
            "14002": "Cobalt Blue",
            "14003": "May Green",
            "14004": "Sun Yellow",
        },
        envelopeType: {
            "111": "100",
            "112": "120"
        },
        envelopePaperFinish: {
            "3": "uncoated",
        },
        "roundCorners": {
            "0": "Square corners",
            "1": "Rounded corners"
        },
        "paymentMethod": {
            0: "Credit",
            1: "Credit/Debit Card",
            2: "Bank Transfer",
            3: "PayPal",
            4: "Credit/Debit Card",
            5: "Stripe",
            6: "Phone",
            7: "Amazon pay",
            8: "Digital wallet (via stripe)",
            9: "Paypal (manual)",
            10: "Customer credit",
            11: "Clearpay",
            12: "Volt",
            13: "Credit Key",
            14: "Proforma Invoice",
            15: "Plaid ACH Transfer",
            16: "Mondu"
        }
    }
};
