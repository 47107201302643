import defineComponent from '../../../../components/flight/lib/component';
import AdminImageUpload from '../admin-image-upload';

export default defineComponent(ProductPriceCalculatorEditor);

function ProductPriceCalculatorEditor() {

    this.attributes({
        imageUploadContainerSelector: '[data-type="admin-image-upload"]',
        productOptionsTableBodySelector: '[data-type="product-options"]',
        saveProductOptionsButtonSelector: '[data-type="save-product-options-btn"]',
        productOptionsFormSelector: '[data-type="product-options-form"]',
        deleteProductOptionsButtonSelector: '[data-type="delete-product-option-btn"]',
        productMetaDataSelect: '[data-type="hidden-product-meta-data-select"]'
    });

    this.imageUploadCallback = function(event, data) {
        this.addProductOptionTableRow(data);
    };

    this.imageDeleteCallback = function(event, data) {
        let tableRows = this.select('productOptionsTableBodySelector').children('tr');
        tableRows.each(function(){
            let imageUrl = $(this).find('[data-field="image-url"]').attr('src');
            if(imageUrl === data.imageUpload.url) {
                $(this).remove();
            }
        });
    };

    this.addProductOptionTableRow = function(imageData) {
        const productMetaDataSelect = this.select('productMetaDataSelect').html();
        const tableRow = `
        <tr>
            <td width="150px">
                <img class="img-thumbnail rounded img-fluid" data-field="image-url" src="${imageData.url}"/>
            </td>
            <td>
                <input class="form-control" type="text" data-field="option-title" required />
            </td>
            <td>
                ${productMetaDataSelect}
            </td>
            <td class="text-end">
                <button class="btn btn-danger" data-type="delete-product-option-btn">Delete</button>
            </td>
        </tr>`;

        this.select('productOptionsTableBodySelector').append(tableRow);
    };

    this.saveOptions = function(event) {
        const saveUrl = `/api/admin/product/pages/${this.pageId}/price-calculator`;

        let editor = this;

        if(editor.select('productOptionsFormSelector').get(0).checkValidity()) {

            editor.disableSaveBtn();

            let tableRows = this.select('productOptionsTableBodySelector').children('tr');

            let calculatorOptions = [];
            tableRows.each(function(){
                let imageElement = $(this).find('[data-field="image-url"]');
                let selectedProductMetadata = $(this).find('[data-field="option-product"] :selected');
                calculatorOptions.push({
                    productId: selectedProductMetadata.data('product-id'),
                    subProductId: selectedProductMetadata.data('sub-product-id'),
                    title: $(this).find('[data-field="option-title"]').val(),
                    imageUrl: imageElement.attr('src'),
                });
            });

            $.ajax({
                url: saveUrl,
                type: 'PUT',
                data: JSON.stringify(calculatorOptions),
                success: function(data) {
                    editor.resetSaveBtn();
                },
                error: function(error) {
                    editor.resetSaveBtn();
                    alert('Error Saving Data');
                }
            });

            event.preventDefault();
        } else {
            editor.select('productOptionsFormSelector').addClass('was-validated');
        }
    };

    this.deleteProductOption = function(event) {
        let tableRow = $(event.target).closest('tr');
        tableRow.remove();
        event.preventDefault();
    };

    this.disableSaveBtn = function(event) {
        let saveBtn = this.select('saveProductOptionsButtonSelector');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function() {
        let saveBtn = this.select('saveProductOptionsButtonSelector');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.pageId = $(this.node).data('page-id');
        AdminImageUpload.attachTo(this.select('imageUploadContainerSelector'));
        this.on('imageUploadSuccess', this.imageUploadCallback);
        this.on('imageDeleteSuccess', this.imageDeleteCallback);
        this.on('click', {
            saveProductOptionsButtonSelector: this.saveOptions,
            deleteProductOptionsButtonSelector: this.deleteProductOption,
        });
    });
}