import defineComponent from '../../../../components/flight/lib/component';
import ReactDom from "react-dom/client";
import React from "react";
import {MonduPaymentManager} from "../../react/mondu/index.tsx";
import mixam from "../../../boot/mixam";

export default defineComponent(MonduManager);

function MonduManager() {

    this.attributes({
        containerSelector: '[data-type="mondu-container"]',
    });

    this.initMondu = function () {
        const orderId = this.orderId;
        const amountToPay = this.amountToPay;
        const root = ReactDom.createRoot(this.select('containerSelector').get(0));
        root.render(
            React.createElement(MonduPaymentManager, {
                orderId: orderId,
                amount: amountToPay,
                locale: mixam.shop.locale.replace('_', '-')
            })
        );
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order-id');
        this.amountToPay = this.$node.data('amount');
        this.initMondu();
    });
}
