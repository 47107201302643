import React from 'react';
import { OffsetPresses } from "./OffsetPresses";
import { FoldingMachines } from "./FoldingMachines";
import { BindingMachines } from "./BindingMachines";
import { DigitalPresses } from "./DigitalPresses";
import { LayflatBinders } from "./LayflatBinders";
import { PunchingMachines } from "./PunchingMachines";
import { CrimpingMachines } from "./CrimpingMachines";
export var AvailableEquipment = function (_a) {
    var configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    return (React.createElement("div", null,
        React.createElement(OffsetPresses, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(DigitalPresses, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(FoldingMachines, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(BindingMachines, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(LayflatBinders, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(PunchingMachines, { configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(CrimpingMachines, { configuration: configuration, doGetConfiguration: doGetConfiguration })));
};
