import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import SectionContent from './SectionContent';
var ContentSectionEditor = function (_a) {
    var index = _a.index, contentSection = _a.contentSection, handleDeleteContentSection = _a.handleDeleteContentSection;
    var _b = useState(!contentSection), isEdit = _b[0], setIsEdit = _b[1];
    var _c = useState(contentSection.title), title = _c[0], setTitle = _c[1];
    var _d = useState(contentSection.body), body = _d[0], setBody = _d[1];
    var handleSave = function () {
        if (body.length < 1 || title.length < 1) {
            alert("Please enter text");
            return;
        }
        contentSection.body = body;
        contentSection.title = title;
        setIsEdit(false);
    };
    return (React.createElement(Card, { className: "mt-3" }, !isEdit ?
        React.createElement("div", null,
            React.createElement(Card.Body, null,
                React.createElement(Card.Title, null,
                    React.createElement("div", { className: "mb-2" },
                        "Title: ",
                        React.createElement("span", { className: "text-primary" }, contentSection.title))),
                React.createElement(Card.Text, { dangerouslySetInnerHTML: { __html: body } })),
            React.createElement(Card.Footer, null,
                React.createElement(Button, { variant: "primary", className: "me-2", onClick: function () { return setIsEdit(true); } }, "Edit"),
                React.createElement(Button, { variant: "danger", onClick: function () { return handleDeleteContentSection(contentSection); } }, "Delete")))
        : React.createElement("div", null,
            React.createElement(Card.Body, null,
                React.createElement("div", { className: "mb-3" },
                    React.createElement("div", { className: "flex-grow-1 me-2" },
                        React.createElement("label", { htmlFor: "content-title-".concat(index), className: "form-label" }, "Title"),
                        React.createElement("input", { type: "text", className: "form-control", id: "content-title-".concat(index), name: "content-title-".concat(index), required: true, value: title, onChange: function (e) { return setTitle(e.target.value); } }),
                        React.createElement("div", { id: "content-title-".concat(index, "-help"), className: "form-text" }, "Enter a title for this Content Section"))),
                React.createElement(SectionContent, { index: index, data: body, handleChange: setBody })),
            React.createElement(Card.Footer, null,
                React.createElement(Button, { onClick: handleSave, className: "me-2" }, "Save"),
                React.createElement(Button, { variant: "danger", onClick: function () { return handleDeleteContentSection(contentSection); } }, "Cancel")))));
};
export default ContentSectionEditor;
