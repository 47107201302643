import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithPayments from '../payments/with-payments';
import {Breakpoints} from "../../table/data-table-net/StyledDataTableNet.tsx";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminCreditManager, WithPayments);

function AdminCreditManager() {

    this.attributes({
        url: "/admin/api/credit/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "",
            type: CELL_TYPES.THUMBNAIL,
            data:"front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"order"
        });

        cols.push({
            title: "Type",
            type: "text",
            data:"transactionType"
        });

        cols.push({
            title: "Date",
            type: CELL_TYPES.TIMEBOX,
            data:"date"
        });

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Email",
            type: "text",
            data:"customerEmail",
            width: '250px',
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Method",
            type: "text",
            data:"paymentMethod",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Currency",
            type: "text",
            data:"currency",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Net",
            type: "number",
            decimal: 2,
            data:"net",
            sum: true,
            currency: true,
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Vat",
            type: "number",
            decimal: 2,
            data:"vat",
            sum: true,
            currency: true,
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Amount",
            type: "number",
            decimal: 2,
            data:"amount",
            sum: true,
            currency: true,
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Status",
            type: "text",
            data:"status",
            hide: Breakpoints.MD
        });

        cols.push({
            title: "",
            type: CELL_TYPES.CREDIT_COMMAND,
            data:"status",
            hide: Breakpoints.LG
        });

        return cols;
    };


    this.updatePaymentLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const payment = data.data;

        if (payment.shopId !== mixam.shop.id) {
            return;
        }
        const index = this.data.list.map(x => x.id).indexOf(payment.id);
        if (payment.status !== "unverified") {
            verb = "DELETE";
        }

        if (verb === "UPDATE") {
            if (index !== -1) {
                this.data.list[index] = payment;
            } else {
                this.data.list.unshift(payment);
            }
        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }
        this.paint();
        requestAnimationFrame(() => $("#PK" + payment.id).hilightTableLine());
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);

        try {
            this.subscribe(this.updatePaymentLine.bind(this));
        } catch (e) {

        }
    });
}
