import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import MemberAddressManager from '../member/member-address-manager';
import Artwork from './order-artwork-manager';
import DatesEditor from './order-dates-editor';
import ConfirmManager from './confirm-manager';
import ChargesEditor from './order-charges-editor';
import JobTitleEditor from './order-job-title-editor';
import Fulfilment from './order-fulfilment';
import WithNormalizeMemberOrders from '../with-normalize-member-orders';
import WithFancyDate from '../with-fancy-time';

import ReprintManager from '../share/reprint-manager';
import AnimatedCheckbox from '../animated/animated-checkbox';
import DeliveryDateUpdater from './delivery-date-updater';
import regional from '../../boot/regional';
import WithFolderUrl from '../with-get-folder-url';

import * as reactEvent from '../constants/react-events';

import * as OrderAdminWebComponents from "@mixam-platform/order-admin";

import SupplierFulfilments from "./supplier-fulfilments";
import { initialiseOrderPageCarouselWebComponent } from '@mixam-platform/mixam-checkout';

export default defineComponent(OrderSummary, WithNormalizeMemberOrders, WithFancyDate, WithFolderUrl);

/** @namespace this.emailTimer */
/** @namespace window.ga */

/** @namespace this.order.artworkReady */
/** @namespace this.order.settled */
function OrderSummary() {

    this.attributes({
        addressFormSelector: '#addressForm',
        addressSigninFormSelector: '[data-type="address-signin-form"]',
        registerFormSelector: '#registerForm',
        paypalExecFormSelector: 'form.paypal-mixam-button',
        postcodeSelector: '[data-type=postcode]',
        emailSigninSelector: '[data-id="username"]',
        emailAddressSelector: '[data-type=email]',
        cardBoxSelector: '[data-type="creditcard-box"]',
        paypalBoxSelector: '[data-type="paypal-box"]',
        amazonPayBoxSelector: '[data-type="amazon-box"]',
        voltPayBoxSelector: '[data-type="volt-box"]',
        digitalWalletPayBoxSelector: '[data-type="digital-wallet-box"]',
        afterpayBoxSelector: '[data-type="afterpay-box"]',
        creditkeyBoxSelector: '[data-type="creditkey-box"]',
        monduBoxSelector: '[data-type="mondu-box"]',
        proformaBoxSelector:  '[data-type="proforma-box"]',
        plaidBoxSelector:  '[data-type="plaid-box"]',
        paymentMethodSelector: '[data-type="payment-method"]',
        laterFormSelector: '[data-type="later-box"]',
        transferFormSelector: '[data-type="bank-transfer-box"]',
        paypalManualBoxSelector: '[data-type="paypal-manual-box"]',
        payByPhoneSelector: '[data-type="pay-by-phone-box"]',
        paymentTabSelector: '[data-target="#payment"]',
        gotoPaymentTabSelector: '[data-type="goto-payment"]',
        saveBtnSelector: '[type=submit]',
        tabsSelector: '[data-toggle=tab]',
        expiryMonthSelector: '#expiryMonth',
        expiryYearSelector: '#expiryYear',
        payMethodsSelector: '.payment-method-form',
        currentYearOptionSelector: 'option[data-type="current-year"]',
        confirmationContainerSelector: '.confirmation-container.first',
        confirmationSelector: '.confirmation.first',
        confirmationDlgSelector: '[data-type="confirm-dlg"]',
        carouselSelector: '#carousel-order-help',
        readinessSelector: '[data-type="order-readiness-dialog"]',
        remarkFormSelector: 'form[data-type="order-remark-form"]',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        confBtnSelector: '.confirmation button[type="submit"]',
        artItemSelector: '[data-type="art-item"].art-item',
        summaryFormSelector: '[data-form-type="summary"]',
        createAddressFormSelector: '[data-form-type="create"]',
        editAddressBtnSelector: '[data-type="edit-address"]',
        addressManagerSelector: '[data-type="address-manager"]',
        orderStatusSelector: '[data-type="order-status"]',
        onHoldRemarkSelector: '[data-type="on-hold-remark"]',
        onHoldRemarkTextSelector: '[data-type="on-hold-remark"] textarea',
        orderEmailSelector: '[data-type="confirm-email"]',
        cancelAddressBtnSelector: '[data-type="cancel-address"]',
        chargeEditorSelector: '[data-type="shop-cart-item"]',
        emailInputSelector: '[data-type="email"]',
        dismissSigninSelector: '[data-dismiss="in-page"]',
        signinSelector: '[data-type="sign-in"]',
        memberFormSelector: '[data-type="sign-in-form"]',
        activateFormSelector: '[data-type="activate-call4action"]',
        unknownUserSelector: '[data-type="unknown-user"]',
        activateSpanSelector: '#activationEmail',
        customerIdSelector: '#customerId',
        historySelector: '[data-target="#customer-history"]',
        customerSelector: '[data-target="#customer"]',
        fulfilmentSelector: '[data-target="#fulfilment"]',
        shipmentSelector: '[data-target="#shipments"]',
        invoiceRefundsSelector: '[data-target="#invoice-refunds"]',
        jobTitleSelector: '.job-title-display',
        fulfilmentTabSelector: '[data-type="fulfilment-item"]',
        fulfilmentsSummarySelector: '[data-type="fulfilment-limit-summary"]',
        fulfilmentFormSelector: '[data-type="admin-fulfilment-form"]',
        alternativeDatesSelector: '[data-type="alternative-dates"]',
        reprintSelector: '[data-type="reprint-santa-editor"]',
        vatToggleSelector: '[data-action="change-vat"]',
        proofTypeOptionSelector: '[data-type="proof-type-option"]',
        deliveryAggregationSubtotalSelector: '[data-type="delivery-aggregation-subtotal"]',
        confirmReadySelector: '[data-type="confirm-ready"]',
        confirmReadyLinkSelector: '[data-type="confirm-ready"] a',
        // Selectors for new Split Delivery edit form
        editAddressFormSelector: '[data-form-type="edit"]',
        secondaryAddressFormSelector: '[data-form-type="edit"] form',
        deliveryIdSelector: '[data-type="delivery-id"]',
        editAddressAdditionalTitleSelector: '[data-type="edit-address-additional"]',
        editAddressBillingTitleSelector: '[data-type="edit-address-billing"]',
        editAddressProofTitleSelector: '[data-type="edit-address-proof"]',
        editAddressDeliveryTitleSelector: '[data-type="edit-address-delivery"]',
        deliveryCopiesContainerSelector: '[data-type="delivery-copies-container"]',
        deliveryCopiesDropdownSelector: '[data-type="delivery-copies-dropdown"]',
        deliveryCopiesDropdownItemSelector: '[data-type="delivery-copies-dropdown-item"]',
        deliveryItemCopiesSelector: '[data-type="delivery-item-copies"]',
        deliveryItemCopiesRemoveSelector: '[data-type="delivery-item-remove"]',
        additionalDeliveryAddressTitleSelector: '[data-type="additional-address-title"]',
        deliveryLocationTypeSelector: '[data-type="additional-address-manager"] [data-type="location-type"]',
        deliveryPlainPackagingSelector: '[data-type="edit-plain-packaging"]',
        deliveryInstructionsSelector: '[data-type="delivery-instructions"]',
        addDeliveryBtnSelector: '[data-type="add-delivery"]',
        cancelAddressEditSelector: '[data-type="cancel-address-edit"]',
        additionalAddressManagerSelector: '[data-type="additional-address-manager"]',
        editDeliveryBtnSelector: '[data-type="edit-delivery"]',
        removeDeliveryBtnSelector: '[data-type="remove-delivery"]',
        removeDeliveryAddressSelector: '[data-type="remove-delivery-address"]',
        removeDeliveryConfirmBtnSelector: '[data-type="remove-delivery-confirm"]',
        deliveryControlsSelector: '.delivery-controls button',
        // Selectors for Proof Management
        toggleVirtualProofReleaseSelector: '[data-type="toggle-virtual-release"]',
        deleteVirtualProofSelector: '[data-type="delete-virtual"]',
        deleteVirtualProofModalSelector: '[data-type="delete-proof-modal"]',
        rejectProofModalSelector: '[data-type="reject-proof-modal"]',
        rejectProofReasonSelector: '[data-type="reject-proof-reason"]',
        saveFulfilmentSubmitSelector: '[data-type="save-fulfilment"]',
        activeFulfilmentPaneSelector: '#fulfilment.active',
        confirmFulfilmentModalSelector: '#confirmFulfilmentModalDialog',
        confirmLossOnItemsModalSelector: '#confirmLossOnItemsModalDialog',
        isSameAsBillingAddressSelector: '[data-type="is-same-as-billing-address"]',
        supplierDiversionContainerValidationSelector: '[data-type="supplier-diversion"]',
        diversionSelectElementValidationSelector: '[data-type="diversion-selection"]',
        diversionExplanationElementValidationSelector: '[data-type="diversion-explanation"]',
        diversionSelectionErrorValidationSelector: '[data-type="diversion-selection-error"]',
        diversionExplanationErrorValidationSelector: '[data-type="diversion-explanation-error"]'
    });

    function Order(response) {
        $.extend(this, response);
    }

    Order.prototype.getItem = function (id) {
        return this.items.filter(item => item.id === id)[0];
    };


    this.handleSubmit = function (event) {
        if (!event.isDefaultPrevented()) {
            $(event.target).find(this.attr.saveBtnSelector).attr('data-loading', '*').attr('disabled', true);
        }
    };

    this.handleRegistrationSubmit = function (event) {
        const $form = $(event.target);

        if (!event.isDefaultPrevented()) {
            $.post($form.attr("action"), $form.serialize(), null, "json").done(response => this.handleRegistrationResponse(response, $form)).fail(response => this.handleRegistrationResponse(response, $form));
        }
        event.preventDefault();
    };

    this.handleRegistrationResponse = function (response, $form) {

        if (response.status === "success") {
            this.select('unknownUserSelector').hide("fast");
            this.select('activateFormSelector').show("fast");
            this.select('activateSpanSelector').text(response.itemId);
            this.trigger('showFixedMessage', {type: 'success', body: "An email message was sent to your email address", interval: 10000});
        } else {
            this.trigger('showFixedMessage', {type: 'error', body: response.reason, interval: 20000});
        }
        $form.find(this.attr.saveBtnSelector).removeAttr('data-loading').removeAttr('disabled');
    };


    this.handleCardBoxClick = function (event) {
        const value = event.target.value;
        const selectors = [
            "laterFormSelector",            // PAYMENT_METHOD_ACCOUNT
            null,                           // PAYMENT_METHOD_CARD_SAGE
            "transferFormSelector",         // PAYMENT_METHOD_BANK
            "paypalBoxSelector",            // PAYMENT_METHOD_PAYPAL
            "cardBoxSelector",              // PAYMENT_METHOD_CARD_DIBS
            "cardBoxSelector",              // PAYMENT_METHOD_CARD_STRIPE
            "payByPhoneSelector",           // PAYMENT_METHOD_PAY_BY_PHONE
            "amazonPayBoxSelector",         // PAYMENT_METHOD_AMAZON_PAY
            "digitalWalletPayBoxSelector",  // PAYMENT_METHOD_DIGITAL_WALLET
            "paypalManualBoxSelector",      // PAYMENT_METHOD_PAYPAL_MANUAL
            null,                           // PAYMENT_METHOD_CUSTOMER_CREDIT
            "afterpayBoxSelector",          // PAYMENT_METHOD_AFTERPAY
            "voltPayBoxSelector",           // PAYMENT_METHOD_VOLT
            "creditkeyBoxSelector",         // PAYMENT_METHOD_CREDIT_KEY
            "proformaBoxSelector",          // PAYMENT_METHOD_PROFORMA,
            "plaidBoxSelector",             // PAYMENT_METHOD_PLAID
            "monduBoxSelector"              // PAYMENT_METHOD_MONDU
        ];
        const updateUrl = mixam.springUrl(`/api/orders/${this.order.id}/update/payment-method`);

        this.select('payMethodsSelector').removeClass('in').addClass('hidden');
        this.select(selectors[+value]).removeClass('hidden').addClass('in');

        this.getAction(null, {url: updateUrl, method: value});
    };


    this.invalidate = function (event, data) {
        this.select('addressFormSelector').data("validator").invalidate([
            {
                input: this.$node.find("#" + data.id + " " + this.attr.postcodeSelector),
                messages: [regional().address.postcodeMinLengthError]
            }
        ], data.event);
    };

    this.resetForm = function () {
        this.select('addressFormSelector').data("validator").reset();
    };


    // make sure we do not pick a past date
    this.handleMonthChange = function (event) {
        let month = event.target.value;

        if (month) {
            month = +month - 1;
            if (month < (new Date()).getMonth()) {
                this.select("currentYearOptionSelector").attr("disabled", true);
                return;
            }
        }
        this.select("currentYearOptionSelector").attr("disabled", null);
    };

    this.afterTabSelect = function (event) {
        const $target = $(event.target),
            hrefString = $target.data('href');
        let href = hrefString && hrefString.replace(/.*(?=#[^\s]*$)/, '');

        if (!href || this.routing) {
            return;
        }
        setImmediate(() => {
            href = (href.charAt(0) !== '/' ? "/" : "") + href;
            window.history.pushState({}, '', mixam.applicationDomain + "orders/" + this.orderId + href);
            dataLayer.push({event: 'pageview'});
        });
    };

    this.getOrder = function (event, data) {
        const url = `${mixam.springUrl("/api/orders/")}${this.orderId}`,
            promise = data && data.url ? $.post(data.url, data, null, "json") : $.getJSON(url);

        promise.done(response => {
            this.order = new Order(response);
            if (data) {
                this.order.itemId = this.order.itemId || data.itemId;
            }
            this.trigger("setOrderData", this.order);
            this.updateDeliveryAggregationSubtotals();
            this.handleReadiness();
            this.topic = "/topic/" + this.order.id;
            // check / uncheck the Artwork tab according the state of the item.map
            // we can subscribe only after we see the order for the first time
            // make sure we do it only once
            if (!this.stompSubscribed) {

                this.subscribe();
                this.stompSubscribed = true;
            }
        });
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function () {
        this.trigger("log", {message: [`Subscribe to stomp channel ${this.topic}`], title: "Artwork.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: args, title: "Artwork.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe(this.topic, (d) => {
                const p = JSON.parse(d.body);

                // if we have a complete order (success) notification lets ask for the updated order
                // if we only got partial update (files are added but not ready yet) lets ask for an update on the file list
                if (p.status === "success") {
                    switch (p.reason) {
                        case 'after/stripe':
                            this.trigger("uiRequestPaymentSubmit");
                            break;
                        case 'after/phone':
                            this.trigger("uiRequestPageReload", {target: p.type});
                            break;
                        default:
                            this.trigger("uiRequestOrder", {itemId: p.itemId});
                    }
                } else {
                    this.trigger("uiRequestOrderFiles", p);
                }
            });
        }, (err) => this.trigger("log", err), '/');
    };


    this.send = function (data) {
        this.stomp.send(this.topic, {}, data);
    };

    this.getOrderFiles = function (event, data) {
        const url = `${mixam.springUrl("/api/orders/")}${this.orderId}`;

        $.getJSON(url).done(response => {
            this.order = new Order(response);
            if (data) {
                this.order.itemId = this.order.itemId || data.itemId;
            }
            this.order.processStatus = data.status;
            this.trigger("setOrderData", this.order);
        });
    };

    this.updateDeliveryAggregationSubtotals = function () {
        const $aggregationSubtotals = this.select('deliveryAggregationSubtotalSelector'),
            currencyPrefix = regional().currencyPrefixSymbol || "",
            currencyPostfix = regional().currencyPostfixSymbol || "";

        $aggregationSubtotals.each((index, element) => {
            const $element = $(element),
                groupingToken = $element.data('token'),
                itemIds = $element.data('items');
            const goodsTotal = this.order.items
                .filter((item) => {
                    return itemIds.includes(item.id);
                })
                .reduce((accumulator, item) => {
                    return accumulator + item.total + item.proofTotal;
                }, 0);
            const deliveryTotal = this.order.deliveries // TODO: [MX-511] update!
                .map((delivery) => delivery.deliveryGroups)
                .reduce((acc, val) => acc.concat(val), [])
                .filter((group) => group.groupingToken === groupingToken)
                .reduce((accumulator, group) => accumulator + group.total, 0);
            const aggregationTotal = goodsTotal + deliveryTotal;
            $element.html(`${currencyPrefix}${mixam.formatNumberWithLocale(aggregationTotal)}${currencyPostfix}`);
        });
    };

    this.handleReadiness = function () {
        if (this.order.artworkReady && this.order.settled && this.order.publicationConfigReady && !this.order.pendingProofOutcome) {
            this.select('carouselSelector').addClass("hidden");
            this.select('readinessSelector').addClass("hidden");
            this.select('confirmationContainerSelector').removeClass("hidden");
            this.select('confirmationSelector').removeClass("silent");
        } else {
            this.select('carouselSelector').removeClass("hidden");
            this.select('readinessSelector').removeClass("hidden");
            this.select('confirmationContainerSelector').addClass("hidden");
            this.select('confirmationSelector').addClass("silent");
        }

        this.reconfigureTab(
            $(".order-tab.order-tab-artwork").find(".order-step-icon"),
            1,
            this.order.artworkReady ? 2 : 0
        );
        /** @namespace this.order.isDetailsAndDeliveryReady */
        this.reconfigureTab(
            $(".order-tab.order-tab-details").find(".order-step-icon"),
            2,
            this.order.isDetailsAndDeliveryReady ? 2 : 0
        );
        this.reconfigureTab(
            $(".order-tab.order-tab-proof").find(".order-step-icon"),
            4,
            this.order.proofReady ? 2 : 0
        );
        this.reconfigureTab(
            $(".order-tab.order-tab-publication").find(".order-step-icon"), // NOTE: non-publication orders do not have an order-step-icon
            4,
            this.order.publicationConfigReady ? 2 : 0
        );

        if (this.showConfirmReadyBanner()) {
            this.select('confirmReadySelector').removeClass('hidden');
        } else {
            this.select('confirmReadySelector').addClass('hidden');
        }
        if (this.order.isDetailsAndDeliveryReady) {
            //this.select("paymentTabSelector").closest('li').tooltip('destroy');
            this.select("paymentTabSelector").closest('li').removeClass('disabled');
        } else {
            //this.select("paymentTabSelector").closest('li').tooltip();
        }
    };

    this.showConfirmReadyBanner = function() {
        const isRipe = this.order.artworkReady && this.order.isDetailsAndDeliveryReady && this.order.settled && this.order.publicationConfigReady;
        return isRipe && this.order.orderStatusInt < 10 && !this.order.pendingProofOutcome;
    };

    this.reconfigureTab = function ($tab, step, state) {
        const icons = ['', 'minus', 'check', 'plus'];
        const iconText = icons[state] ? `<i class="fa fa-${icons[state]}" aria-hidden="true"></i>` : '';
        const innerText = `<span>${step}</span>${iconText}`;
        $tab.removeClass('state-0 state-1 state-2 state-3').addClass(`state-${state}`).html(innerText);
    };

    /**
     * Generic way to call the (old) Orders API.
     * Pass data for POST calls otherwise GET will be used.
     * POST calls will pass the data param as the POST data.
     * An "uiAfterAction" event will be raised containing the repose after the call completes.
     *
     * data my contain a "url" to allow us to call any endpoint
     * data my contain an "action" property will be present also in the "uiAfterAction" event.
     */
    this.getAction = function (event, data) {
        const url = mixam.springUrl("/api/orders/") + this.orderId,
            promise = data && data.url ? $.post(data.url, data, null, "json") : $.getJSON(url);

        promise.done(response => {
            if (data.action) {
                response.action = data.action;
            }
            this.trigger("uiAfterAction", response);
        });
    };

    this.startAddressEdit = function (event) {
        event.preventDefault();
        this.select("summaryFormSelector").hide();
        this.select("createAddressFormSelector").show("slow");
    };

    this.endAddressEdit = function (event) {
        event.preventDefault();
        this.select("createAddressFormSelector").hide();
        $('[data-type="santa-head"]').scrollToView(() => this.select("summaryFormSelector").show("slow"));
    };

    // Avoids some repetitive code
    this.updateEditAddressFormForNextUse = function (selector) {
        // First hide all titles...
        this.select('editAddressAdditionalTitleSelector').hide();
        this.select('editAddressBillingTitleSelector').hide();
        this.select('editAddressDeliveryTitleSelector').hide();
        // Then show the selected title...
        this.select(selector).show();
        // Adjust visibility of quantities as appropriate...
        this.select('deliveryCopiesContainerSelector').toggleClass('hidden', selector !== 'editAddressAdditionalTitleSelector');
        // Adjust visibility of the extra (mid-form) 'Delivery Address' header as appropriate...
        this.select('additionalDeliveryAddressTitleSelector').toggleClass('hidden', selector !== 'editAddressAdditionalTitleSelector');
        // Adjust visibility of the 'Plain packaging' checkbox as appropriate (only BILLING)...
        this.select('deliveryPlainPackagingSelector').toggleClass('hidden', selector !== 'editAddressBillingTitleSelector');
        // Adjust visibility of the 'Instructions' text area as appropriate (every sort except BILLING)...
        this.select('deliveryInstructionsSelector').toggleClass('hidden', selector === 'editAddressBillingTitleSelector');
    };

    this.startAddDelivery = function (event) {
        event.preventDefault(); // necessary?
        this.select('editAddressFormSelector').find('form').trigger('reset');  // Clear in case of previous use
        this.select('deliveryIdSelector').val(''); // Also clear this hidden input (b/c hidden fields not cleared by reset()!)
        this.updateEditAddressFormForNextUse('editAddressAdditionalTitleSelector'); // Display the proper title & (optionally) the quantity select UI
        this.select('deliveryItemCopiesSelector').find('li').removeClass('hidden'); // And also re-enable all items in the dropdown
        this.select('deliveryItemCopiesSelector').find('> [data-item].dynamic').remove(); // Also remove previous quantity inputs that were dynamically-added
        this.select('deliveryItemCopiesSelector').find('> [data-item]').each(function (index, element) { // Also set the correct 'max' attribute on quantity inputs that were _not_ dynamically-added
            const $element = $(element),
                primaryCopies = $element.data('primary-copies');
            $element.find('[data-type="delivery-item-quantity"]').attr('max', primaryCopies);
        });
        this.select('deliveryControlsSelector').prop('disabled', true);
        this.select('addDeliveryBtnSelector').closest('.form-group').addClass('hidden');
        this.select('editAddressFormSelector').show('slow').scrollToView();
    };

    this.cancelAddressEditForm = function (event) {
        /*
         * NOTE: This function applies to any circumstance when the user cancels the form matching
         * editAddressFormSelector: both create & update, both billing & delivery.
         */
        event.preventDefault();
        this.select('deliveryControlsSelector').prop('disabled', false);
        this.select('addDeliveryBtnSelector').closest('.form-group').removeClass('hidden');
        this.select('editAddressFormSelector').hide();
        $('[data-type="santa-head"]').scrollToView(() => this.select('summaryFormSelector'));
        this.trigger("uiCancelAddressEdit");
    };

    this.startEditDelivery = function (event) {
        const $target = $(event.target).closest('[data-delivery-id]');

        const deliveryId = $target.data('delivery-id');
        this.select('deliveryIdSelector').val(deliveryId);

        let titleSelector; // default
        switch (deliveryId) {
            case 'BILLING':
                titleSelector = 'editAddressBillingTitleSelector';
                break;
            case 'PROOF':
                titleSelector = 'editAddressProofTitleSelector';
                break;
            case 'PRIMARY':
                titleSelector = 'editAddressDeliveryTitleSelector';
                // Populate locationType
                this.select('deliveryLocationTypeSelector').val($target.data('address-locationtype'));
                break;
            default:
                titleSelector = 'editAddressAdditionalTitleSelector';
                // Populate locationType
                this.select('deliveryLocationTypeSelector').val($target.data('address-locationtype'));
                break;
        }

        this.updateEditAddressFormForNextUse(titleSelector); // Display the proper title &c.

        if (deliveryId === 'BILLING') {
            // Populate plainPackaging...
            this.select('deliveryPlainPackagingSelector')
                .find('input')
                .prop('checked', $target.data('plain-packaging'));
        } else {
            // Populate instructions...
            this.select('deliveryInstructionsSelector')
                .find('textarea')
                .val($target.data('address-instructions'));
        }

        const data = {
            firstName: $target.data('address-firstname'),
            lastName: $target.data('address-lastname'),
            company: $target.data('address-company'),
            phone: $target.data('address-phone'),
            street1: $target.data('address-line1'),
            street2: $target.data('address-line2'),
            town: $target.data('address-town'),
            county: $target.data('address-county'),
            postcode: $target.data('address-postcode'),
            country: $target.data('address-country')
        };
        this.trigger('uiPopulateAdditionalAddress', data);

        this.select('deliveryControlsSelector').prop('disabled', true);
        this.select('addDeliveryBtnSelector').closest('.form-group').addClass('hidden');
        this.select('editAddressFormSelector').show('slow', () => {
            // Reset the item copies area to defaults (in case it's been used & canceled)...
            this.select('deliveryItemCopiesSelector').find('li').removeClass('hidden'); // Re-enable all item options
            this.select('deliveryItemCopiesSelector').find('> [data-item].dynamic').remove(); // Remove previous quantity inputs that were dynamically-added

            if (deliveryId !== 'BILLING' && deliveryId !== 'PRIMARY') {
                // Populate previously-selected item quantities
                $target.find('[data-item-id]').each((index, element) => {
                    const $element = $(element),
                        itemId = $element.data('item-id'),
                        copies = $element.data('quantity');

                    if (this.select('editAddressFormSelector').hasClass('item-count-1')) {
                        // This is a single-item order (no dropdown)
                        const $deliveryItemCopies = this.select('deliveryItemCopiesSelector'),
                            $itemQuantityContainer = $deliveryItemCopies.find(`> [data-item="${itemId}"]`),
                            primaryCopies = parseInt($itemQuantityContainer.data('primary-copies'), 10);
                        $itemQuantityContainer.find('[data-type="delivery-item-quantity"]')
                            .attr('max', primaryCopies + parseInt(copies, 10))
                            .val(copies);
                    } else {
                        // This is a multi-item order w/ dropdown
                        const $li = this.select('deliveryItemCopiesSelector').find(`ul li[data-item="${itemId}"]`);
                        this.addItemToQuantityList($li, copies);
                    }
                });
            }
        }).scrollToView();
    };

    this.startRemoveDelivery = function (event) {
        // Don't preventDefault(); need the Bootstrap modal behaviour
        const $target = $(event.target).closest('[data-delivery-id]'),
            orderId = $target.data('order-id'),
            deliveryId = $target.data('delivery-id'),
            deliveryAddress = `${$target.data('address-line1')} ${$target.data('address-line2')} ${$target.data('address-town')}, ${$target.data('address-county')}, ${$target.data('address-country')}`;

        this.select('removeDeliveryAddressSelector').text(deliveryAddress);
        this.select('removeDeliveryConfirmBtnSelector').data('href', `/orders/${orderId}/addressedit/${deliveryId}/remove`);
    };

    this.endRemoveDelivery = function (event) {
        const href = $(event.currentTarget).data('href');
        const $form = this.select('summaryFormSelector');
        $form.attr('action', href).submit();
    };

    this.doItemDropdownSelect = function (event) {
        const $li = $(event.target).closest('li');
        this.addItemToQuantityList($li, '');
    };

    this.addItemToQuantityList = function ($li, copies) {
        const $itemQuantities = this.select('deliveryItemCopiesSelector'),
            itemId = $li.data('item');

        if ($itemQuantities.find(`> [data-item="${itemId}"]`).size() === 0) { // Add each item once at most
            const itemQuantityHtml = $li.find('.item-quantity-template').html(),
                $itemQuantityHtml = $(itemQuantityHtml),
                primaryCopies = parseInt($itemQuantityHtml.data('primary-copies'), 10),
                currentCopies = parseInt(copies, 10);

            $itemQuantityHtml
                .addClass('dynamic') // Helps us know to remove it in some circumstances
                .find('[data-type="delivery-item-quantity"]')
                .prop('disabled', false)
                .attr('max', primaryCopies + (currentCopies ? currentCopies : 0))
                .val(copies);
            $itemQuantities.append($itemQuantityHtml);

            $li.addClass('hidden');
        }
    };

    this.removeItemQuantityFromList = function (event) {
        const $itemQuantityContainer = $(event.target).closest('.item-quantity-container'),
            itemId = $itemQuantityContainer.data('item');
        this.select('deliveryItemCopiesSelector').find(`> [data-item="${itemId}"]`).remove();
        this.select('deliveryCopiesDropdownSelector').find(`li[data-item="${itemId}"]`).removeClass('hidden');
    };

    this.updateUiState = function () {
        if (this.select('orderStatusSelector').val() === 'CONFIRMED') {
            this.select('orderEmailSelector').show('slow');
        } else {
            this.select('orderEmailSelector').hide('slow');
        }
        if(this.select('orderStatusSelector').val() === 'ONHOLD') {
            this.select('onHoldRemarkTextSelector').attr('required', true);
            this.select('onHoldRemarkSelector').show('slow');
        } else {
            this.select('onHoldRemarkTextSelector').attr('required', false);
            this.select('onHoldRemarkSelector').hide('slow');
        }
    };

    this.emailChange = function (event) {
        const $target = $(event.target),
            url = mixam.springUrl("/api/findmember");
        let promise;

        // if not already logged in
        if (!mixam.user.hasRole("ROLE_USER")) {
            if (!$target.is(':invalid')) {
                if (this.emailTimer) {
                    clearTimeout(this.emailTimer);
                }
                setTimeout(() => {
                    promise = $.post(url, {email: $target.val()}, null, "json");
                    promise.done(data => {
                        if (data.found) {
                            this.openSigninDialog();
                        }
                    });
                }, 200);
            }
        }
    };

    this.openSigninDialog = function () {
        this.select("emailSigninSelector").val(this.select("emailAddressSelector").val());
        this.select("addressFormSelector").hide();
        this.select("addressSigninFormSelector").show("normal");
    };

    this.closeSigninDialog = function () {
        this.select("addressFormSelector").show("normal");
        this.select("addressSigninFormSelector").hide();
    };

    this.showSigninForm = function (event) {
        const $target = $(event.target);

        this.select("memberFormSelector").show(300, () => this.select('carouselSelector').carousel('next'));  // workaround chrome leaving horizontal lines on screen
        $target.scrollToView().closest(".morph-button").addClass("open");
    };

    this.onUploadInProcessChange = function (event, data) {
        this.isDirty = data.count;
    };

    this.scrollToConfirm = function (event) {
        event.preventDefault();
        const $confirmDialog = this.select('confirmationContainerSelector');
        $confirmDialog.scrollToView($confirmDialog.hilightElement());
    };

    this.vatToggle = function (event) {
        const $target = $(event.target),
            itemId = $target.data('item'),
            updateUrl = mixam.springUrl(`/api/orders/${this.order.id}/${itemId}/vat`);

        this.getAction(null, {url: updateUrl, action: 'vat', vat: $target.prop('checked')});
    };

    this.changeProofType = function (event) {
        const $target = $(event.target).closest('a'),
                itemId = $target.data('item'),
                proofType = $target.data('proof'),
                url = `/orders/${this.order.id}/${itemId}/proof/update`,
                $form = $(`<form method="POST" action="${url}"><input type="hidden" name="proofType" value="${proofType}"></form>`);
        $('body').append($form);
        $form.submit();
    };

    this.handleGotoPaymentClick = function (event) {
        event.preventDefault();
        this.select('paymentTabSelector').tab('show');
    };

    this.reloadPage = function (event, data) {
        window.location.href = data.target;
    };

    this.toggleVirtualProofRelease = function (event) {
        const $button = $(event.currentTarget),
            orderId = $button.data('order'),
            itemId = $button.data('item'),
            url = `/api/proof/${orderId}/${itemId}/togglerelease`;

        $.post(url, null, null, 'json')
            .done(() => {
                $button.closest('.virtual-proof-actions').toggleClass('released');
                $button.blur();
            })
            .fail((err) => this.trigger("log", { message: err }));
    };

    this.startDeleteVirtualProof = function (event) {
        const $button = $(event.currentTarget),
            caseNumber = $button.data('caseNumber'),
            itemId = $button.data('item'),
            proofCycle = $button.data('proofCycle'),
            $modal = this.select('deleteVirtualProofModalSelector');

        $modal.find('.proof-case-number').html(caseNumber);
        $modal.find('input[name="itemId"]').val(itemId);
        $modal.find('input[name="proofCycle"]').val(proofCycle);


    };

    this.notifyInternationalDelivery = function (countryChangeEvent, data) {
        const deliveryIdValue = this.select('deliveryIdSelector').val();
        const scope = $(countryChangeEvent.target).attr('data-address');
        const isSameAsBillingAddressSelected = this.select('isSameAsBillingAddressSelector').prop('checked');
        let showDeliveryNotification = false;

        switch(scope) {
            case 'delivery':
                showDeliveryNotification = true;
                break;
            case 'billing':
                if(isSameAsBillingAddressSelected) {
                    showDeliveryNotification = true;
                }
                break;
            case 'additional':
                if(deliveryIdValue !== 'BILLING') {
                    showDeliveryNotification = true;
                }
                break;
        }

        if(!this.internationalDeliveryNotificationVisible && showDeliveryNotification) {
            //check if international
            if(!mixam.shop.shipToCountries.includes(data.countryCode)) {
                this.trigger('showFixedMessage', {
                    type: 'success',
                    body: regional().address.overseasDelivery.replace(/_shopname_/, mixam.shop.title),
                    interval: 15000
                });
                this.internationalDeliveryNotificationVisible = true;
                setTimeout(() => {
                    this.internationalDeliveryNotificationVisible = false;
                }, 15000);
            }
        }
    };

    /**
     * Displays one or more confirmation dialogs if/when appropriate.
     */
    this.confirmAdminToolbarSubmit = function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.validateFulfilmentForm()) {
            const $adminForm = this.select('fulfilmentFormSelector');

            // Chain confirmation dialogs inside-out...
            const submitForm = () => $adminForm.submit(),
                confirmLossOnItems = () => this.confirmFulfilmentLossOnItems(submitForm),
                confirmUnverifiedPayment = () => this.confirmFulfilmentUnverifiedPayment(confirmLossOnItems);

            confirmUnverifiedPayment();
        }
    };

    this.validateFulfilmentForm = function () {
        const supplierDiversionContainer = this.select('supplierDiversionContainerValidationSelector');
        const diversionSelect = this.select('diversionSelectElementValidationSelector');
        const diversionTextArea = this.select('diversionExplanationElementValidationSelector');
        const diversionSelectError = this.select('diversionSelectionErrorValidationSelector');
        const diversionExplanationError = this.select('diversionExplanationErrorValidationSelector');

        if (supplierDiversionContainer.length !== 0 && supplierDiversionContainer.isVisible()) {
            // sets to hidden on repeated runs
            diversionSelectError.hide();
            diversionExplanationError.hide();
            const diversionSelectValues = Array.from($(diversionSelect).find('option:selected')).map(selectedOption => selectedOption.value);

            // Check that a selection has been made
            if (!diversionSelectValues || diversionSelectValues.length < 1) {
                diversionSelectError.show();
                return false;
            }
            // Check that the selected value is Other and check whether an explanation has been entered into the text box
            if (diversionSelectValues.includes("OTHER") && (!diversionTextArea.val() || diversionTextArea.val().length < 1)) {
                diversionExplanationError.show();
                return false;
            }
            return true;
        }
        return true;
    };

    this.confirmFulfilmentUnverifiedPayment = function(callback) {
        const $activeFulfilmentPane = this.select('activeFulfilmentPaneSelector'),
                $confirmDialog = this.select('confirmFulfilmentModalSelector');

        if ($activeFulfilmentPane.length && $confirmDialog.length) { // Confirm only if (1) it's fulfilment data & (2) payment is unverified
            new Promise(function(resolve) {
                $confirmDialog.modal('show');
                $confirmDialog.find('[data-type="confirm-fulfilment-yes"]').click(() => resolve(true));
                $confirmDialog.find('[data-type="confirm-fulfilment-no"]').click(() => resolve(false));
            }).then((confirmed) => {
                $confirmDialog.modal('hide');
                if (confirmed) {
                    callback();
                }
            });
        } else {
            callback();
        }
    };

    this.confirmFulfilmentLossOnItems = function(callback) {
        const $fulfilmentItems = this.select('fulfilmentTabSelector'),
                $confirmLossDialog = this.select('confirmLossOnItemsModalSelector');

        let lossOnItem = false;
        $fulfilmentItems.each((index, element) => {
            const $element = $(element);
            if ($element.data('lossOnItem')) {
                lossOnItem = true;
            }
        });
        if (lossOnItem && mixam.shop.showWarningOnLoss) {
            new Promise(function(resolve) {
                $confirmLossDialog.modal('show');
                $confirmLossDialog.find('[data-type="confirm-loss-on-items-yes"]').click(() => resolve(true));
                $confirmLossDialog.find('[data-type="confirm-loss-on-items-no"]').click(() => resolve(false));
            }).then((confirmed) => {
                $confirmLossDialog.modal('hide');
                if (confirmed) {
                    callback();
                }
            });
        } else {
            callback();
        }
    };

    this.handleShowSpinner = (order, showText) => {
        const params = new URLSearchParams(window.location.search);
        const showSpinner = params.get('showSpinner');
        if (!showSpinner) {
            return;
        }

        const firstUpload = order?.items?.[0]?.uploads?.[0];
        if (!firstUpload) {
            return;
        }

        const spinner = $(document.getElementById('fullPageSpinner'));
        if (firstUpload.status === 'pending') {
            spinner.removeClass('hidden');
        } else if (firstUpload.status === 'ready') {
            spinner.addClass('hidden');
        }

        if (showText) {
            const spinnerText = $(document.getElementById('fullPageSpinnerText'));
            spinnerText.removeClass('hidden');
        }
    };

    this.initialiseOrderAdminWebComponents = function() {
        const components = [
            { init: OrderAdminWebComponents.initialiseOrderAdminOrder },
            { init: OrderAdminWebComponents.initialiseOrderAdminFulfilment, selector: 'fulfilmentSelector' },
            { init: OrderAdminWebComponents.initialiseOrderAdminCustomer, selector: 'customerSelector' },
            { init: OrderAdminWebComponents.initialiseOrderAdminShipment, selector: 'shipmentSelector' },
            { init: OrderAdminWebComponents.initialiseOrderAdminInvoiceRefund, selector: 'invoiceRefundsSelector' },
            { init: OrderAdminWebComponents.initialiseOrderAdminHistory, selector: 'historySelector' }
        ];

        components.forEach(({ init, selector }) => {
            if (selector) {
                this.on('click', {
                    [selector]: () => init()
                });
            } else {
                init();
            }
        });
    };

    this.after('initialize', function () {
        this.on(document, "setOrderData", (event, order) => {
            this.handleShowSpinner(order, true);
        });

        initialiseOrderPageCarouselWebComponent();
        this.initialiseOrderAdminWebComponents();

        this.orderId = $("#orderId").val();

        if (!this.orderId) {
            this.trigger("log", {message: "order-summary -> Order id is empty, early return"});
            return;
        }

        this.routing = false;
        this.isDirty = 0;
        this.getOrder();
        this.page = 0;
        this.sort = {key: "time", dir: "DESC"};
        this.orderMemberId = this.select('customerIdSelector').val();
        this.internationalDeliveryNotificationVisible = false;

        // setup validators
        ['addressFormSelector', 'secondaryAddressFormSelector', 'remarkFormSelector',
            'addressSigninFormSelector', 'registerFormSelector'].forEach(selector => {
            this.select(selector).validator({
                effect: 'mixam',
                errorClass: 'validation-error',
                errorInputEvent: 'keyup',
                formEvent: 'submit',
                inputEvent: null,
                grouped: true
            });
        });

        this.on('shown.bs.tab', {
            'tabsSelector': this.afterTabSelect
        });

        this.on('shown.bs.modal', {
            'rejectProofModalSelector': () => this.select('rejectProofReasonSelector').focus()
        });

        // attach sub components
        SupplierFulfilments.attachTo(this.select('fulfilmentsSummarySelector'));
        Artwork.attachTo(this.attr.artItemSelector);
        ChargesEditor.attachTo(this.attr.chargeEditorSelector);
        JobTitleEditor.attachTo(this.attr.jobTitleSelector);
        DatesEditor.attachTo(this.attr.alternativeDatesSelector);
        AnimatedCheckbox.attachTo(this.attr.animatedCheckboxSelector);
        DeliveryDateUpdater.attachTo(this.attr.confirmationContainerSelector);

        // addressManagerSelector usually finds 2 (billing and delivery) therefore Flight will create 2 instances
        MemberAddressManager.attachTo(this.select('addressManagerSelector'), {
            hideMethod: "hidden"
        });
        MemberAddressManager.attachTo(this.select('additionalAddressManagerSelector'), {
            hideMethod: "hidden",
            populateOnEvent: 'uiPopulateAdditionalAddress'
        });
        Fulfilment.attachTo(this.select('fulfilmentTabSelector'));
        ConfirmManager.attachTo(this.select('confirmationDlgSelector'), {
            //selectors
            scenesXSelector: '.inline-confirmation > section',
            scenesYSelector: '.inline-confirmation > section.present > section'
        });

        $('[data-toggle="popover"]').popover({
            html: true,
            delay: {"show": 100, "hide": 100},
            trigger: 'focus'
        });

        this.on('submit', this.handleSubmit);
        this.on('submit', {
            paypalExecFormSelector: this.handleSubmit,
            registerFormSelector: this.handleRegistrationSubmit
        });
        this.on('click', {
            confirmReadyLinkSelector: this.scrollToConfirm
        });
        this.on('invalidateOrderForm', this.invalidate);
        this.on('resetOrderForm', this.resetForm);

        this.on('uiRequestOrder', this.getOrder);
        this.on('uiRequestOrder', reactEvent.propagateReactEvent);

        this.on('setOrderData', reactEvent.propagateReactEvent);

        this.on('uiRequestOrderFiles', this.getOrderFiles);
        this.on('uiRequestAction', this.getAction);

        this.on('click', {
            gotoPaymentTabSelector: this.handleGotoPaymentClick,
            paymentMethodSelector: this.handleCardBoxClick,
            proofTypeOptionSelector: this.changeProofType,
            cancelAddressBtnSelector: this.endAddressEdit,
            cancelAddressEditSelector: this.cancelAddressEditForm,
            editAddressBtnSelector: this.startAddressEdit,
            addDeliveryBtnSelector: this.startAddDelivery,
            dismissSigninSelector: this.closeSigninDialog,
            signinSelector: this.showSigninForm,
            editDeliveryBtnSelector: this.startEditDelivery,
            removeDeliveryBtnSelector: this.startRemoveDelivery,
            removeDeliveryConfirmBtnSelector: this.endRemoveDelivery,
            deliveryItemCopiesRemoveSelector: this.removeItemQuantityFromList,
            deliveryCopiesDropdownItemSelector: this.doItemDropdownSelect,
            toggleVirtualProofReleaseSelector: this.toggleVirtualProofRelease,
            deleteVirtualProofSelector: this.startDeleteVirtualProof,
            saveFulfilmentSubmitSelector: this.confirmAdminToolbarSubmit
        });
        this.on('change', {
            expiryMonthSelector: this.handleMonthChange,
            orderStatusSelector: this.updateUiState,
            vatToggleSelector: this.vatToggle,
            emailInputSelector: this.emailChange
        });

        this.on(document, "uploadInProcessChange", this.onUploadInProcessChange);
        this.on(document, "uiRequestPageReload", this.reloadPage);
        //this.on('slide.bs.carousel', this.carouselChange);

        $('#app-order-tabs').removeClass('nav-disabled');

        $(window).on('beforeunload', () => {
            if (this.isDirty) {
                return regional().onDirtyWindowUnload;
            }
        });

        this.on(this.attr.addressManagerSelector,'uiAddressEditCountryChange', this.notifyInternationalDelivery);
        this.on(this.attr.additionalAddressManagerSelector,'uiAddressEditCountryChange', this.notifyInternationalDelivery);
    });

}
