var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { setupInitialValues } from '../util';
import mixam from '../../../../../boot/mixam.js';
import { initDefaultModalFooterButtons } from "../FormikTableModal/FormikTableModal";
import { submitApproval } from "./api/InvoiceRefunds";
import regional from "../../../../../boot/regional.js";
var InvoiceRefundApproval = function (_a) {
    var invoiceRefund = _a.invoiceRefund;
    // @ts-ignore
    var isAllowed = mixam.user.hasRole('ROLE_MIXAM');
    var comparisonLines = invoiceRefund.lineItems.map(function (lineItem, index) {
        var originalInvoiceLineItem = invoiceRefund.originalInvoiceLineItems[index];
        return __assign(__assign({}, lineItem), { originalQuantity: originalInvoiceLineItem.quantity, originalDescription: originalInvoiceLineItem.description, originalAmount: originalInvoiceLineItem.unitAmount, originalTaxAmount: originalInvoiceLineItem.taxAmount });
    });
    var initialValues = setupInitialValues({ refundMethod: 'refundMethod', notes: 'notes' }, invoiceRefund);
    initialValues.approvalStatus = {
        "PENDING_APPROVAL": "PENDING_APPROVAL",
        "CANCELLED": "REJECTED"
    }[invoiceRefund.status] || "APPROVED";
    var validateApprovalStatus = function (target) {
        return !["APPROVED", "REJECTED"].includes(target) ? "Please select an option" : null;
    };
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, submitApproval(invoiceRefund.invoiceNumber, invoiceRefund.id, values.approvalStatus, values.refundMethod, values.notes)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var _b, _c;
        var submitForm = _a.submitForm, values = _a.values, errors = _a.errors;
        var modalFooterButtons = initDefaultModalFooterButtons(isAllowed, submitForm);
        return (React.createElement(FormikTableModal, { headerText: 'Requested Refund', footerButtons: modalFooterButtons, modalSize: 'xl' },
            React.createElement("p", null,
                invoiceRefund.raisedBy,
                " has requested a ",
                invoiceRefund.wholeInvoice ? 'full' : 'partial',
                " refund of ", "".concat(regional().currencySymbol).concat((_c = (_b = (invoiceRefund.refundAmount + invoiceRefund.refundTaxAmount)) === null || _b === void 0 ? void 0 : _b.toFixed(2)) !== null && _c !== void 0 ? _c : 0),
                invoiceRefund.refundTaxAmount && " (plus ".concat(regional().currencySymbol).concat(invoiceRefund.refundTaxAmount.toFixed(2), " tax) "),
                "on invoice ",
                React.createElement("strong", null, invoiceRefund.invoiceNumber)),
            comparisonLines &&
                React.createElement("table", { className: "table table-striped" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Quantity"),
                            React.createElement("th", null, "Description"),
                            React.createElement("th", null, "Original Amount"),
                            React.createElement("th", null, "Original Tax"),
                            React.createElement("th", null, "Refund Amount"),
                            React.createElement("th", null, "Refund Tax"))),
                    React.createElement("tbody", null, comparisonLines.map(function (lineItem, index) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        return (React.createElement("tr", { key: index },
                            React.createElement("td", null, lineItem.originalQuantity),
                            React.createElement("td", null, lineItem.originalDescription),
                            React.createElement("td", null, (_b = (_a = lineItem.originalAmount) === null || _a === void 0 ? void 0 : _a.toFixed(2)) !== null && _b !== void 0 ? _b : 0),
                            React.createElement("td", null, (_d = (_c = lineItem.originalTaxAmount) === null || _c === void 0 ? void 0 : _c.toFixed(2)) !== null && _d !== void 0 ? _d : 0),
                            React.createElement("td", null, (_f = (_e = lineItem.refundAmount) === null || _e === void 0 ? void 0 : _e.toFixed(2)) !== null && _f !== void 0 ? _f : 0),
                            React.createElement("td", null, (_h = (_g = lineItem.refundTaxAmount) === null || _g === void 0 ? void 0 : _g.toFixed(2)) !== null && _h !== void 0 ? _h : 0)));
                    }))),
            values.refundMethod === 'ORIGINAL_PAYMENT_METHOD' ? (React.createElement("div", { className: "alert alert-warning", role: "alert" },
                "Manual step required to refund via ",
                invoiceRefund.originalPaymentMethodType)) : (React.createElement("div", { className: "alert alert-info", role: "alert" }, "A Store Credit will be applied to the customer's account")),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "approvalStatus" }, "Mark Payment As"),
                React.createElement(Field, { className: "form-select", as: "select", name: "approvalStatus", disabled: !isAllowed, validate: validateApprovalStatus },
                    React.createElement("option", { value: "PENDING_APPROVAL" }, "Choose..."),
                    React.createElement("option", { value: "APPROVED" }, "Approved"),
                    React.createElement("option", { value: "REJECTED" }, "Rejected")),
                errors.approvalStatus && React.createElement("div", null, errors.approvalStatus)),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "refundMethod" }, "Refund method"),
                React.createElement(Field, { className: "form-select", as: "select", name: "refundMethod", disabled: !isAllowed },
                    React.createElement("option", { value: "ORIGINAL_PAYMENT_METHOD" }, "Original payment method"),
                    React.createElement("option", { value: "STORE_CREDIT" }, "Store credit"))),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "notes" }, "Notes"),
                React.createElement(Field, { className: "form-control", as: "textarea", name: 'notes' }),
                errors.notes && React.createElement("div", null, errors.notes))));
    }));
};
export default InvoiceRefundApproval;
