var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { submit } from '../api';
import { convertDate } from "../util";
import mixam from "../../../../../boot/mixam.js";
var ResolveDispute = function (_a) {
    var line = _a.line;
    // @ts-ignore
    var isAllowed = mixam.user.hasRole('ROLE_MIXAM');
    return (React.createElement(Formik, { initialValues: {
            problemCategory: line.disputeCloseCategory,
            problemCulprit: line.culprit,
            problemResolution: line.resolvedActionType,
            problemRemark: line.remarks || '',
            problemReprintCaseNumber: line.reprintCaseNumber || '',
            problemMixamRefundAmount: line.mixamRefundAmount || '',
            problemMixamReprintCost: line.mixamReprintCost || '',
            problemSupplierRefundAmount: line.supplierRefundAmount || '',
            problemSupplierReprintCost: line.supplierReprintCost || '',
            problemLogisticsCost: line.logisticsCost || '',
        }, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, submit("/api/admin/dispute/".concat(line.id, "/update"), values)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var setFieldValue = _a.setFieldValue, values = _a.values, submitForm = _a.submitForm;
        var footerModalButtons = [
            {
                label: 'Close',
                enabled: true,
                className: 'btn-secondary',
                closeAfterCallback: true,
                validateForm: false
            },
            {
                label: 'Save',
                enabled: isAllowed,
                className: 'btn-primary',
                closeAfterCallback: true,
                validateForm: false,
                onClickEvent: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        submitForm();
                        return [2 /*return*/];
                    });
                }); }
            },
            {
                label: 'Save & Resolve',
                enabled: isAllowed,
                className: 'btn-primary',
                closeAfterCallback: true,
                validateForm: true,
                onClickEvent: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, submit("/api/admin/dispute/".concat(line.id, "/resolve"), values)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }
            }
        ];
        return (React.createElement(FormikTableModal, { modalSize: "xl", headerText: "Resolve Dispute", openBtnLabel: "Resolve", openBtnClassName: "btn-success", scrollable: true, footerButtons: footerModalButtons },
            React.createElement("div", null,
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h4", { className: "mb-3" }, "Details"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "customerName", className: "form-label h6" }, "Customer Name"),
                                React.createElement("p", { id: "customerName" }, line.memberName)),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "category", className: "form-label h6" }, "Category"),
                                React.createElement("p", { id: "categoryLabel" }, line.problemCategoryText))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "openedDate", className: "form-label h6" }, "Opened Date"),
                                React.createElement("p", { id: "openedDate" }, convertDate(line.dateCreated))),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "askingFor", className: "form-label h6" }, "Asking For"),
                                React.createElement("p", { id: "askingFor" }, line.disputeRequestCategory))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "itemCost", className: "form-label h6" }, "Supplier Item Cost"),
                                React.createElement("p", { id: "itemCost" }, Number.parseFloat(line.itemSupplierCost.toString()).toFixed(2) || '')),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "itemValue", className: "form-label h6" }, "Item Value"),
                                React.createElement("p", { id: "itemValue" }, Number.parseFloat(line.itemValue.toString()).toFixed(2) || ''))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "copiesAffected", className: "form-label h6" }, "Copies Affected"),
                                React.createElement("p", { id: "copiesAffected" }, line.copiesAffected))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "description", className: "form-label h6" }, "Description"),
                                React.createElement("p", { id: "description" }, line.problemDetails))),
                        line.attachments.length > 0 &&
                            React.createElement("div", { className: "row" },
                                React.createElement("label", { htmlFor: "imageCarousel", className: "form-label h6" }, "Attachments"),
                                React.createElement("div", { id: "imageCarousel", className: "carousel slide", "data-bs-ride": "carousel" },
                                    React.createElement("div", { className: "carousel-indicators" }, line.attachments.map(function (attachment, index) {
                                        return (React.createElement("button", { type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide-to": index, className: index == 0 ? 'active' : '', "aria-label": "Image ".concat(index) }));
                                    })),
                                    React.createElement("div", { className: "carousel-inner" }, line.attachments.map(function (attachment, index) {
                                        return (React.createElement("div", { className: index == 0 ? 'active carousel-item' : 'carousel-item' },
                                            React.createElement("img", { key: attachment.url, src: attachment.url, className: "d-block w-100" })));
                                    })),
                                    React.createElement("button", { className: "carousel-control-prev", type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide": "prev" },
                                        React.createElement("span", { className: "carousel-control-prev-icon", "aria-hidden": "true" }),
                                        React.createElement("span", { className: "visually-hidden" }, "Previous")),
                                    React.createElement("button", { className: "carousel-control-next", type: "button", "data-bs-target": "#imageCarousel", "data-bs-slide": "next" },
                                        React.createElement("span", { className: "carousel-control-next-icon", "aria-hidden": "true" }),
                                        React.createElement("span", { className: "visually-hidden" }, "Next"))),
                                React.createElement("div", { className: "row mt-3" }, line.attachments.map(function (attachment, index) {
                                    return (React.createElement("div", { className: "col-2" },
                                        React.createElement("img", { src: attachment.url, "data-bs-target": "#imageCarousel", "data-bs-slide-to": index, className: index == 0 ? 'active img-fluid img-thumbnail mb-2' : 'img-fluid img-thumbnail mb-2' })));
                                })),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col" },
                                        React.createElement("a", { className: "btn btn-primary btn-sm", href: "/api/admin/dispute/".concat(line.id, "/download-images"), role: "button" }, "Download Attachments"))))),
                    React.createElement("div", { className: "col border-start" },
                        React.createElement("h4", { className: "mb-3" }, "Resolution"),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemCategory", className: "form-label h6" }, "* Problem"),
                                React.createElement(Field, { className: "form-select", as: "select", name: "problemCategory", disabled: !isAllowed, onChange: function (e) {
                                        setFieldValue('problemCategory', e.target.value);
                                    } },
                                    React.createElement("option", { className: "a-prompt", value: "" }, "Choose a category"),
                                    React.createElement("optgroup", { label: "Damages" },
                                        React.createElement("option", { value: "101" }, "Box Damaged / Split"),
                                        React.createElement("option", { value: "102" }, "Product Damaged"),
                                        React.createElement("option", { value: "103" }, "Damaged Corners"),
                                        React.createElement("option", { value: "104" }, "Poster Packing Damage"),
                                        React.createElement("option", { value: "105" }, "Water Damage"),
                                        React.createElement("option", { value: "106" }, "Poor Packaging"),
                                        React.createElement("option", { value: "107" }, "Wavy Pages")),
                                    React.createElement("optgroup", { label: "Print Quality" },
                                        React.createElement("option", { value: "201" }, "Colour Variance"),
                                        React.createElement("option", { value: "202" }, "Colours Weak And Faded"),
                                        React.createElement("option", { value: "203" }, "Print Quality Generally Poor"),
                                        React.createElement("option", { value: "204" }, "Misregistration"),
                                        React.createElement("option", { value: "205" }, "Banding"),
                                        React.createElement("option", { value: "206" }, "Colour Quality And Dot Gain"),
                                        React.createElement("option", { value: "207" }, "Colours Dark"),
                                        React.createElement("option", { value: "208" }, "One Colour Too Strong (e.g. Heavy Magenta)"),
                                        React.createElement("option", { value: "209" }, "Print Quality Varies"),
                                        React.createElement("option", { value: "210" }, "Set Off"),
                                        React.createElement("option", { value: "211" }, "Canon Vs. Indigo"),
                                        React.createElement("option", { value: "212" }, "Imposition Error")),
                                    React.createElement("optgroup", { label: "Orientation" },
                                        React.createElement("option", { value: "301" }, "Artwork Wrong Way Round"),
                                        React.createElement("option", { value: "302" }, "Backup Proof Misleading"),
                                        React.createElement("option", { value: "303" }, "Reverse Side Print Is Upside Down"),
                                        React.createElement("option", { value: "304" }, "Wrong Orientation/Pages Upside Down"),
                                        React.createElement("option", { value: "305" }, "Bound On Wrong Edge")),
                                    React.createElement("optgroup", { label: "Finishing Quality" },
                                        React.createElement("option", { value: "401" }, "Creased Pages"),
                                        React.createElement("option", { value: "402" }, "Finishing Quality Generally Poor"),
                                        React.createElement("option", { value: "403" }, "Poor Binding"),
                                        React.createElement("option", { value: "404" }, "Poor PUR - Excess Glue"),
                                        React.createElement("option", { value: "405" }, "Stitched Off Centre"),
                                        React.createElement("option", { value: "406" }, "Trim/Fold Misaligned"),
                                        React.createElement("option", { value: "407" }, "Trimmed Beyond Bleed"),
                                        React.createElement("option", { value: "408" }, "Trimmed Off Centre"),
                                        React.createElement("option", { value: "409" }, "Trimmed Too Small"),
                                        React.createElement("option", { value: "410" }, "Binding Poor"),
                                        React.createElement("option", { value: "411" }, "Not Trimmed To Size"),
                                        React.createElement("option", { value: "412" }, "Pages Wrong Order"),
                                        React.createElement("option", { value: "413" }, "Laminate Peeling"),
                                        React.createElement("option", { value: "414" }, "Lamination Missing"),
                                        React.createElement("option", { value: "415" }, "Bowing Hardcovers"),
                                        React.createElement("option", { value: "416" }, "Wrong Colour, Size, Shape, Wiro"),
                                        React.createElement("option", { value: "417" }, "Laminate Bubbling"),
                                        React.createElement("option", { value: "418" }, "Curling Covers"),
                                        React.createElement("option", { value: "419" }, "Spine Movement"),
                                        React.createElement("option", { value: "420" }, "Binding Movement"),
                                        React.createElement("option", { value: "421" }, "Laminate Stock Variation"),
                                        React.createElement("option", { value: "422" }, "Wrong Size")),
                                    React.createElement("optgroup", { label: "Shipping" },
                                        React.createElement("option", { value: "501" }, "Customer Out / DPD Failed"),
                                        React.createElement("option", { value: "502" }, "Delivered To The Wrong Address - Address Not Updated"),
                                        React.createElement("option", { value: "503" }, "Delivered But Not Received"),
                                        React.createElement("option", { value: "504" }, "Delivery Upgrade Missed"),
                                        React.createElement("option", { value: "505" }, "Delayed & Estimated Date Missed"),
                                        React.createElement("option", { value: "506" }, "Split Address Missed"),
                                        React.createElement("option", { value: "507" }, "Wrong Order Received"),
                                        React.createElement("option", { value: "508" }, "Tracking Not Updated By Courier"),
                                        React.createElement("option", { value: "509" }, "Lost In Transit")),
                                    React.createElement("optgroup", { label: "Other" },
                                        React.createElement("option", { value: "601" }, "Duplicated Pages"),
                                        React.createElement("option", { value: "602" }, "Declined Laminate & Covers Scuffed"),
                                        React.createElement("option", { value: "603" }, "Expectation Not Realistic"),
                                        React.createElement("option", { value: "604" }, "Not Shrink Wrapped"),
                                        React.createElement("option", { value: "605" }, "Pages/Covers Missing"),
                                        React.createElement("option", { value: "606" }, "Production Days Incorrect"),
                                        React.createElement("option", { value: "607" }, "Production Delays"),
                                        React.createElement("option", { value: "608" }, "Shortage - Delivered Under Quantity Ordered"),
                                        React.createElement("option", { value: "609" }, "Wrong Pagination"),
                                        React.createElement("option", { value: "610" }, "Wrong Quantity Split"),
                                        React.createElement("option", { value: "611" }, "Wrong Stock"),
                                        React.createElement("option", { value: "612" }, "End Paper Colour Error"),
                                        React.createElement("option", { value: "613" }, "Delivered Flat Not Folded"),
                                        React.createElement("option", { value: "614" }, "Paper Quality Varies"),
                                        React.createElement("option", { value: "615" }, "Test vs Main Order Variances"),
                                        React.createElement("option", { value: "616" }, "Wrong Cover Finish"),
                                        React.createElement("option", { value: "617" }, "Within Binding Tolerance"),
                                        React.createElement("option", { value: "618" }, "Within Trim Tolerance"),
                                        React.createElement("option", { value: "619" }, "Within Colour Tolerance"),
                                        React.createElement("option", { value: "620" }, "Within Folding Tolerance"),
                                        React.createElement("option", { value: "621" }, "Within Spine Size Variance"),
                                        React.createElement("option", { value: "622" }, "Duplicate"),
                                        React.createElement("option", { value: "623" }, "Logged on Wrong Order"),
                                        React.createElement("option", { value: "624" }, "Mixam System Issue")),
                                    React.createElement("optgroup", { label: "Marking" },
                                        React.createElement("option", { value: "701" }, "Ink Marks"),
                                        React.createElement("option", { value: "702" }, "Scuffing/Scratches"),
                                        React.createElement("option", { value: "703" }, "Roller/Other Marks"),
                                        React.createElement("option", { value: "704" }, "Ink Spots And Streaks"),
                                        React.createElement("option", { value: "705" }, "Memory Marks"),
                                        React.createElement("option", { value: "706" }, "White Spots/Lines")),
                                    React.createElement("optgroup", { label: "File" },
                                        React.createElement("option", { value: "801" }, "Mirror Bleed Not Checked/White Lines"),
                                        React.createElement("option", { value: "802" }, "Spine Set Up Incorrectly"),
                                        React.createElement("option", { value: "803" }, "Text Too Close To Trim Edge"),
                                        React.createElement("option", { value: "804" }, "Font/Glyph Error"),
                                        React.createElement("option", { value: "805" }, "Single Colour Black Printed Not Rich Black"),
                                        React.createElement("option", { value: "806" }, "Spine File Too Big"),
                                        React.createElement("option", { value: "807" }, "Layers Transparency"),
                                        React.createElement("option", { value: "808" }, "Pixelation Noise"),
                                        React.createElement("option", { value: "809" }, "Moire"),
                                        React.createElement("option", { value: "810" }, "Too Many Pages"),
                                        React.createElement("option", { value: "811" }, "Not Enough Bleed"),
                                        React.createElement("option", { value: "812" }, "Key Lines"))))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemCulprit", className: "form-label h6" }, "* Responsibility"),
                                React.createElement(Field, { className: "form-select", as: "select", name: "problemCulprit", disabled: !isAllowed, onChange: function (e) {
                                        setFieldValue('problemCulprit', e.target.value);
                                    } },
                                    React.createElement("option", { className: "a-prompt", value: "" }, "Choose a culprit"),
                                    React.createElement("option", { value: "0" }, "Customer"),
                                    React.createElement("option", { value: "1" }, "Supplier"),
                                    React.createElement("option", { value: "2" }, "Carrier"),
                                    React.createElement("option", { value: "3" }, "Mixam")))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemResolution", className: "form-label h6" }, "* Resolution"),
                                React.createElement(Field, { className: "form-select", as: "select", name: "problemResolution", disabled: !isAllowed, onChange: function (e) {
                                        setFieldValue('problemResolution', e.target.value);
                                    } },
                                    React.createElement("option", { className: "a-prompt", value: "" }, "Choose a resolved action"),
                                    React.createElement("option", { value: "0" }, "None"),
                                    React.createElement("option", { value: "1" }, "Full Refund"),
                                    React.createElement("option", { value: "2" }, "Partial Refund"),
                                    React.createElement("option", { value: "3" }, "Reprint")))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemRemark", className: "form-label h6" }, "Comments"),
                                React.createElement(Field, { className: "form-control", as: "textarea", name: "problemRemark", disabled: !isAllowed, rows: "5" }))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemReprintCaseNumber", className: "form-label h6" }, "Reprint Case Number"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemReprintCaseNumber", disabled: !isAllowed, pattern: "\\d*" }))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemMixamRefundAmount", className: "form-label h6" }, "Mixam Refund Amount"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemMixamRefundAmount", disabled: !isAllowed })),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemMixamReprintCost", className: "form-label h6" }, "Mixam Reprint Cost"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemMixamReprintCost", disabled: !isAllowed }))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemSupplierRefundAmount", className: "form-label h6" }, "Supplier Refund Amount"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemSupplierRefundAmount", disabled: !isAllowed })),
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemSupplierReprintCost", className: "form-label h6" }, "Supplier Reprint Cost"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemSupplierReprintCost", disabled: !isAllowed }))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement("label", { htmlFor: "problemLogisticsCost", className: "form-label h6" }, "Logistics Cost"),
                                React.createElement(Field, { className: "form-control", type: "number", name: "problemLogisticsCost", disabled: !isAllowed })),
                            React.createElement("div", { className: "col" })))))));
    }));
};
export default ResolveDispute;
