import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import Mustache from '../../../components/mustache/mustache';
import templateTotals from 'text!../../../appes6/templates/order/totals.mustache';
import regional from '../../boot/regional';

/** @namespace this.order.leftToPay */
/** @namespace order.totalAfterDiscount */

export default defineComponent(OrderVatUpdater);

function OrderVatUpdater() {

    this.attributes({
        totalsContainerSelector: '[data-type="items-total-table"]',
        leftToPaySelector: '[data-type="left-to-pay"]',
        refundSelector: '[data-type="refund-dialog"]',
        paymentDlgSelector: '[data-type="payment-dialog" ]',
    });

    this.renderTotals = function () {
        return Mustache.render(templateTotals, this.normalize(this.order));
    };

    this.normalize = function (order) {

        const originalTotal = order.total;
        const discount = order.discountSum || 0;

        return {
            order: order,
            hasMultipleItems: order.items.length > 1,
            hasVat: order.vatSum > 0,
            deliveries: order.deliveries, // TODO: [MX-511] update!
            dict: this.createDict(regional().santa),
            originalTotal: originalTotal,
            discount: discount ? discount : null,
            discountedTotal: discount ? originalTotal - discount : null,
            currencyPrefix: regional().currencyPrefixSymbol,
            currencyPostfix: regional().currencyPostfixSymbol,
            HAS_DELIVERY:  order.delivery ? true : null,
            format: () => mixam.mustachFormatNumber
        };
    };

    this.createDict = function (dict) {
        return {
            vat: dict.shoppingCart.vat,
            tax: dict.shoppingCart.tax,
            pallets: dict.shoppingCart.pallets,
            delivery: dict.shoppingCart.delivery,
            weightUnit: dict.shoppingCart.weightUnit,
            total: dict.shoppingCart.total
        };
    };

    this.setOrder = function (event, order) {
        this.order = order;

        order.items.forEach(item => {
            $(`[data-action="change-vat"][data-item="${item.id}"]`).prop('checked', item.isVatable).prop('disabled',
                !mixam.user.hasRole("ROLE_MIXAM") && item.isForceVatable).trigger('draw');
        });

        this.select('totalsContainerSelector').html(this.renderTotals());

        this.select('leftToPaySelector').text(Math.abs(this.order.leftToPay || 0).formatNumber(2));
        if (this.order.leftToPay > 0) {
            this.select('paymentDlgSelector').removeClass('hidden');
        } else {
            this.select('paymentDlgSelector').addClass('hidden');
        }

        if (this.order.leftToPay < 0) {
            this.select('refundSelector').removeClass('hidden');
        } else {
            this.select('refundSelector').addClass('hidden');
        }
        this.reconfigureTab($(".order-tab.order-tab-payment").find(".order-step-icon"), 3, this.getPaymentTabState(order));

        if (this.order.artworkReady && this.order.isDetailsAndDeliveryReady && this.order.settled && this.order.orderStatusInt < 10) {
            $(this.attr.confirmReadySelector).removeClass('hidden');
        } else {
            $(this.attr.confirmReadySelector).addClass('hidden');
        }
    };

    this.reconfigureTab = function ($tab, step, state) {
        const icons = ['', 'minus', 'check', 'plus'];
        const iconText = icons[state] ?  `<i class="fa fa-${icons[state]}" aria-hidden="true"></i>` : '';
        const innerText = `<span>${step}</span>${iconText}`;
        $tab.removeClass('state-0 state-1 state-2 state-3').addClass(`state-${state}`).html(innerText);
    };

    this.getPaymentTabState = function (order) {
        let state = 0;

        if (order.leftToPay < 0) {
            state = 3;
        } else
        if (order.settled) {
            state = 2;
        } else if (order.totalAfterDiscount - order.leftToPay > 0.05) {
            state = 1;
        }
        return state;
    };


    this.after('initialize', function () {
        this.on(document, "setOrderData", this.setOrder);
    });
}
