import defineComponent from '../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import { OrderInfoCard } from '../react/order/OrderInfoCard.tsx';

export default defineComponent(OrderInfoCardReact);

function OrderInfoCardReact() {
    this.attributes({
        containerSelector: '[data-type="order-info-card-target"]',
    });

    this.initReactComponent = function () {
        const container = this.select('containerSelector').get(0);
        const root = ReactDom.createRoot(container);
        root.render(
            <OrderInfoCard
                statusTitle={this.statusTitle}
                statusLabel={this.statusLabel}
                statusSubtitle={this.statusSubtitle}
                date={this.date}
                orderStatus={this.orderStatus}
                qrcode={this.qrcode}
                name={this.name}
                phone={this.phone}
                email={this.email}
                locale={this.locale.replace('_',"-")}
                timeZone={this.timeZone}
            />
        );
    };

    this.after('initialize', function () {
        this.statusTitle = this.$node.data('status-title');
        this.statusLabel = this.$node.data('status-label');
        this.statusSubtitle = this.$node.data('status-subtitle');
        this.date = this.$node.data('date');
        this.orderStatus = this.$node.data('order-status');
        this.qrcode = this.$node.data('qrcode');
        this.name = this.$node.data('name');
        this.phone = this.$node.data('phone');
        this.email = this.$node.data('email');
        this.locale = this.$node.data('locale');
        this.timeZone = this.$node.data('timezone');

        this.initReactComponent();
    });
}