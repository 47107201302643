import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import WithSubmitAnimation from './../with-submit-zoom-animation';
import regional from '../../boot/regional';
import DataTable from "../table/data-table-net/data-table-net.tsx";
import {CELL_TYPES} from "../table/data-table-net/DataTableConstants.ts";

export default defineComponent(OrdersExporter, WithSubmitAnimation);

function OrdersExporter() {

    this.attributes({
        url: "/admin/api/order-export/list",
        dataTableSelector: '[data-type="data-table"]',
        formSelector: 'form',
        updatesSelector: '[data-action="updates"]',
        saveBtnSelector: '[type="submit"]',
        allShopsSelector: '#requested-shops-all',
        animatedCheckboxSelector: '[data-toggle="animated-checkbox"]',
        shopCheckboxInputSelector: '[data-toggle="shop-checkbox"] input[type="checkbox"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Created",
            type: "datetime",
            data: "dateCreated",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Shops",
            type: "text",
            data: "shops",
        });

        cols.push({
            title: "Month",
            type: "text",
            data: "monthName"
        });

        cols.push({
            title: "Year",
            type: "number",
            data: "year"
        });

        cols.push({
            title: "Report Type",
            type: "text",
            data: "orderExportType",
        });

        cols.push({
            title: "Author",
            type: "text",
            data: "author",
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
        });

        cols.push({
            title: "",
            type: CELL_TYPES.BUTTON,
            data: "action"
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeJob(x));
    };

    this.normalizeJob = function (job_) {
        const job = $.extend(true, {}, job_);

        job.orderExportType = job.orderExportType ? job.orderExportType : 'STANDARD';
        job.status = job.status ? job.status : 'COMPLETE';

        job.action = {
            href: job.url,
            caption: 'Download',
            target: "_blank",
            className: `btn btn-primary position-absolute end-0 ${job.status !== 'COMPLETE' ? 'disabled' : ''}`,
            iconClassname: "bi bi-download"
        };
        job.monthName = regional().monthLongNames[job.month-1];
        return job;
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel orderexportmessages"], title: "FeedbackManager.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "orderexportmessages.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/orderexportmessages", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateServerProgress = function (message) {
        const progress = Math.round(message.indexOrder/ message.count * 100);
        this.select('updatesSelector').find('.progress').show().
            find(".progress-bar").css("width", `${progress}%`).text(`${progress}%`);
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;

        const index = this.data.list.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            this.resetAnimation();
            this.select('updatesSelector').find('.progress').hide().
                        find(".progress-bar").css("width", '0').text('');
            if (index !== -1) {
                this.data.list[index] = message;
            } else {
                this.data.list.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());

    };

    this.onShopClick = function (event) {
        const $target = $(event.target);
        const checked = $target.prop('checked');
        const value = $target.prop('value');
        if (value === "-1" && checked) {
            // all shops is selected - unselect all others
            this.select('shopCheckboxInputSelector').each((i, inp) => {
                if (i) {
                    $(inp).prop('checked', false).trigger('change');
                }
            });
        }
        if (value !== "-1" && checked) {
            // normal shop is selected - unselect all shops
            this.select('allShopsSelector').prop('checked', false).trigger('change');
        }
    };

    this.handleFormSubmit = function (event) {
        const $form = $(event.target);

        if (this.isValid()) {
            this.handleSubmit(event);
            $.post(`/admin/orders/export?${$form.serialize()}`)
                .fail((err) => this.trigger("log", { message: err }));
        } else {
            this.trigger('showFixedMessage', {message: regional().messages[1055]});
        }
        event.stopPropagation();
        event.preventDefault();
    };

    this.isValid = function () {
        let result = false;
        this.select('shopCheckboxInputSelector').each((i, inp) => {
            if ($(inp).prop('checked')) {
                result = true;
            }
        });
        return result;
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));

        this.on('click', {
            shopCheckboxInputSelector: this.onShopClick
        });
        this.on('submit', {
            formSelector: this.handleFormSubmit
        });
    });
}
