import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import OrderStatus from "../../constants/orderStatus";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(PrintessWebhookManager);

function PrintessWebhookManager() {

    this.attributes({
        url: "/admin/api/printess/webhooks",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Order",
            type: "link",
            data: "orderLink"
        });

        cols.push({
            title: "Status",
            type: CELL_TYPES.BADGE,
            data: "statusBadge",
            width: '150px'
        });

        cols.push({
            title: "Template Name",
            type: "text",
            data: "printessTemplateName",
        });

        cols.push({
            title: "Created",
            type: CELL_TYPES.TIMEBOX,
            data: "date",
            defaultSort: true,
            sortOrder: 'desc',
        });

        cols.push({
            title: "Callback Count",
            type: "number",
            data: "itemCallbackCount",
        });

        cols.push({
            title: "Job ID",
            type: "text",
            data: "jobId",
        });

        cols.push({
            title: "File URLs",
            type: "link-list",
            data: "files",
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (webhooks) {
        return webhooks.map(webhook => this.normalizeWebhook(webhook));
    };

    this.normalizeWebhook = function (webhook) {
        const result = $.extend(true, {}, webhook);

        result.orderLink = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber,
            target: "_blank"
        };

        result.statusBadge = {
            label: result.orderStatus,
            colour: OrderStatus.getBadgeColour(result.orderStatusInt)
        };

        result.jobId = result.statusResponse.jobId;

        result.files = [];
        if (result.statusResponse.isSuccess) {
            Object.keys(result.statusResponse.result.r).forEach((key) => {
                const url = result.statusResponse.result.r[key];
                result.files.push({
                    href: url,
                    caption: key,
                    target: "_blank"
                });
            });
        }

        return result;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
            }
        );
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
