import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import MomentZone from "moment-timezone";
import mixam from "../../../boot/mixam";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(CreatorRegistrationDatatable);

function CreatorRegistrationDatatable() {

    this.attributes({
        url: "/api/admin/creator/registration",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Enabled",
            type: "boolean",
            data: "enabled"
        });

        cols.push({
            title: "URL Key",
            type: "text",
            data: "urlKey"
        });

        cols.push({
            title: "Signup Code",
            type: "text",
            data: "signupCode",
        });

        cols.push({
            title: "Signup Count",
            type: "link",
            data: "signupCountLink",
        });

        cols.push({
            title: "Created",
            type: CELL_TYPES.TIMEBOX,
            data: "createdDateTimestamp",
            defaultSort: true,
            sortOrder: 'desc',
        });

        cols.push({
            title: "",
            type: "multiButton",
            width: "180px",
            data: "actions"
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (creatorRegistrations) {
        return creatorRegistrations.map(creatorRegistration => this.normalizeCreatorRegistration(creatorRegistration));
    };

    this.normalizeCreatorRegistration = function (creatorRegistration) {
        const result = $.extend(true, {}, creatorRegistration);
        result.actions = [
            {
                href: `/admin/creator/registration/${creatorRegistration.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                target: "_self"
            }
        ];
        result.signupCountLink = {
            href: `/admin/customers/groups/${creatorRegistration.customerGroupId}/customers`,
            title: 'View Signups',
            caption: result.signupCount,
            target: '_blank'
        };
        result.createdDateTimestamp = MomentZone.utc(result.createdDateTimestamp).tz(mixam.shop.timeZone);
        return result;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
            }
        );
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
