import React from 'react';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import Context from '@ckeditor/ckeditor5-core/src/context.js';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard.js";
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import mixam from "../../../../boot/mixam.js";
var SectionContent = function (_a) {
    var index = _a.index, data = _a.data, handleChange = _a.handleChange;
    return React.createElement("div", null,
        React.createElement("label", { htmlFor: "editor-".concat(index), className: "form-label mt-3" }, "Content"),
        React.createElement(CKEditorContext, { id: "editor-".concat(index), context: Context },
            React.createElement(CKEditor
            // @ts-ignore
            , { 
                // @ts-ignore
                editor: ClassicEditor, config: {
                    plugins: [
                        Paragraph,
                        Bold,
                        Italic,
                        Essentials,
                        AlignmentPlugin,
                        Autoformat,
                        Heading,
                        Link,
                        List,
                        SourceEditing,
                        PasteFromOffice,
                        Clipboard,
                        Image,
                        ImageCaption,
                        ImageStyle,
                        ImageToolbar,
                        ImageUpload,
                        ImageResize,
                        SimpleUploadAdapter
                    ],
                    toolbar: ['heading', 'alignment', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'uploadImage', 'sourceEditing', 'undo', 'redo'],
                    image: {
                        toolbar: [
                            'imageStyle:inline',
                            'imageStyle:block',
                            'imageStyle:side',
                            '|',
                            'toggleImageCaption',
                            'imageTextAlternative'
                        ]
                    },
                    simpleUpload: {
                        uploadUrl: "".concat(mixam.secureDomain, "/admin/api/ckeditor/images"),
                        withCredentials: true
                    }
                }, onChange: function (e, editor) {
                    handleChange(editor.getData());
                }, data: data })));
};
export default SectionContent;
