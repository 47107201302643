import { Client } from '@stomp/stompjs';
import mixam from "../../appes6/boot/mixam";

export class StompListener {
    constructor(channels, callback) {
        this.channels = channels;
        this.callback = callback;
        this.client = new Client();
    }
    connect() {
        this.client.configure({
            brokerURL: mixam.stompServiceUrl,
            connectHeaders: {
                login: mixam.stompWebUser,
                passcode: mixam.stompWebPass,
            },
        });

        this.client.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => subscribeToMultiple(this.channels, this.client, this.callback), 1000);
            }
        };

        this.client.activate();

        this.client.onConnect = () => {
            subscribeToMultiple(this.channels, this.client, this.callback);
        };

        function subscribeToMultiple(channelArr, stompClient, callbackMethod) {
            channelArr.forEach(channel => stompClient.subscribe(channel, (message) => callbackMethod(JSON.parse(message.body))));
        }
    }
}

export const Topics = Object.freeze({
    ARTICLES_QUEUE_TOPIC: String('/topic/articlesallmessages'),
    TASK_QUEUE_TOPIC: String('/topic/tasksallmessages'),
    ALL_CXML_POST_HISTORY_CHANNEL_TOPIC: String('/topic/allcxmlposthistorychanel'),
    ALL_QUEUE_ANALYTICS_TOPIC: String('/topic/shopanalyticsmessages'),
    ALL_QUEUE_REALTIME_TOPIC: String('/topic/shoprealtimemessages'),
    ALL_QUEUE_PAYMENT_TOPIC: String('/topic/shoppaymentmessages'),
    ALL_QUEUE_ODEON_TOPIC: String('/topic/allodeonchannel'),
    ALL_ORDER_EXPORT_TOPIC: String('/topic/orderexportmessages'),
    ALL_QUEUE_TRADEPRINT_WEBHOOK_TOPIC: String('/topic/shoptradeprintwebhookmessages'),
    ALL_SRI_CHANNEL_TOPIC: String('/topic/allsrichanel'),
    ALL_PUBLISHER_CHANNEL_TOPIC: String('/topic/allpublisherchanel'),
    ALL_INVOICING_CHANNEL_TOPIC: String('/topic/allinvoicechanel'),
    ALL_PUBLISHERS_CHANNEL_TOPIC: String('/topic/allipublisherschannel'),
    ALL_REVENUESHARE_CHANNEL_TOPIC: String('/topic/allirevenuesharechannel'),
    ALL_INVOICING_IMPORT_CHANNEL_TOPIC: String('/topic/allinvoiceimportchanel'),
    ALL_MERGE_CHANNEL_TOPIC: String('/topic/allmergechanel'),
    ALL_SAMPLES_CHANNEL_TOPIC: String('/topic/allsampleschanel'),
    ALL_CXML_POST_CHANNEL_TOPIC: String('/topic/allcxmlpostchanel'),
    ALL_TRADEPRINT_API_POST_CHANNEL_TOPIC: String('/topic/alltradeprintapipostchanel'),
    ALL_TAYLORS_API_POST_CHANNEL_TOPIC: String('/topic/alltaylorsapipostchanel'),
    ALL_PRINTED_API_POST_CHANNEL_TOPIC: String('/topic/allprintedapipostchanel'),
    ALL_FEEDBACK_CHANNEL_TOPIC: String('/topic/allfeedbackchanel'),
    ALL_SHARES_CHANNEL_TOPIC: String('/topic/allshareschanel'),
    ALL_DISPUTE_CHANNEL_TOPIC: String('/topic/alldisputechanel'),
    ALL_RESELLER_REQUESTS_TOPIC: String('/topic/allresellerrequest'),
    ALL_REVIEWS_TOPIC: String('/topic/allreviewschanel'),
    ALL_PLAYGROUND_TOPIC: String('/topic/allplaygroundchannel'),
    ALL_FLYERALARM_JOB_CHANNEL_TOPIC: String('/topic/allflyeralarmjobschanel'),
    ALL_QUEUE_TOPIC: String('/topic/shopallmessages'),
    ALL_TAX_EXEMPTION_TOPIC: String('/topic/alltaxexemptionchannel'),
    ALL_CUSTOMER_CREDITS_TOPIC: String('/topic/allcustomercreditschannel'),
    ALL_ADMIN_CHANNEL: String('/topic/alladminchannel'),
    UPLOAD_S3_QUEUE_TOPIC: String('/topic/ups3allmessages'),
    MIXAM_QUERY_RESPONSE: `/topic/${mixam.queryResponseTopicId}`
});
