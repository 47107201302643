import defineComponent from '../../../../components/flight/lib/component';
import Product from '../../constants/products';
import Binding from '../../constants/bindings';
import axios from "axios";

export default defineComponent(MachineMarkupEditor);

function MachineMarkupEditor() {

    this.attributes({
        saveMarkupsButton: '[data-type="save-markups-btn"]',
        deleteMarkupRowButton: '[data-type="delete-markup-row-btn"]',
        addMarkupRowButton: '[data-type="add-markup-row-btn"]',
        markupTableBody: '[data-type="markup-table-body"]',
        disableGlobalMarkup: '[data-type="disable-global-markup"]',
    });

    this.saveMarkups = function(event) {

        const saveUrl = `/admin/catalogue/machines/${this.machineId}/update-overheads`;

        let editor = this;

        if(this.node.checkValidity()) {
            editor.initSaveBtn();

            let tableRows = this.select('markupTableBody').children('tr');

            let markups = [];
            tableRows.each(function(){
                markups.push({
                    from: $(this).find('[data-field="markup-from"]').val(),
                    to: $(this).find('[data-field="markup-to"]').val(),
                    value: $(this).find('[data-field="markup-value"]').val(),
                    productId: $(this).find('[data-field="markup-product"]').val(),
                    bindId: $(this).find('[data-field="markup-binding"]').val(),
                    absolute: $(this).find('[data-field="markup-absolute"]').val()
                });
            });

            const data = {
                overheads: markups,
                disableGlobalMarkup: this.select('disableGlobalMarkup').is(":checked")
            };

            axios.post(saveUrl, data)
                .then(() => editor.resetSaveBtn())
                .catch(() => {
                    editor.resetSaveBtn();
                    alert('Error Saving Data');
                });

            event.preventDefault();
        } else {
            $(this.node).addClass('was-validated');
        }
    };

    this.deleteMarkupRow = function(event) {
        let tableRow = $(event.target).closest('tr');
        tableRow.remove();
        event.preventDefault();
    };

    this.addMarkupRow = function(event) {

        let productSelectOptions = '<option value="" selected>ALL</option>';
        for (const [key, value] of Object.entries(Product)) {
            if(typeof value === 'number')
            {
                productSelectOptions += `<option value="${value}">${key}</option>`;
            }
        }

        let bindingSelectOptions = '<option value="" selected>ALL</option>';
        for (const [key, value] of Object.entries(Binding)) {
            bindingSelectOptions += `<option value="${value}">${key}</option>`;
        }

        let newMarkupRow = `
            <tr>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="markup-from" required />
                </td>
                <td>
                    <input min="0" max="999999999" step="1" class="form-control" type="number" data-field="markup-to" required />
                </td>
                <td>
                    <select data-field="markup-product" class="form-select" th:value="">
                       ${productSelectOptions}
                    </select>
                </td>
                <td>
                    <select data-field="markup-binding" class="form-select" th:value="">
                        ${bindingSelectOptions}      
                    </select>
                </td>
                <td>
                    <input min="0" max="999999999" step="0.01" class="form-control" type="number" data-field="markup-value" required />
                </td>
                <td>
                    <select data-field="markup-absolute" class="form-select" th:value="false" required>
                        <option value="true">Yes</option>
                        <option value="false" selected>No</option>
                    </select>
                </td>
                <td class="text-end">
                    <button class="btn btn-danger" data-type="delete-markup-row-btn">Delete</button>
                </td>
            </tr>
        `;
        
        this.select('markupTableBody').append(newMarkupRow);

        event.preventDefault();
    };

    this.initSaveBtn = function(event) {
        let saveBtn = this.select('saveMarkupsButton');
        setTimeout( function() {
            saveBtn.addClass('disabled');
            saveBtn.find('[data-type="spinner"]').removeClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Saving");
        }, 1);
    };

    this.resetSaveBtn = function(event) {
        let saveBtn = this.select('saveMarkupsButton');
        setTimeout( function() {
            saveBtn.find('[data-type="spinner"]').addClass('d-none');
            saveBtn.find('[data-type="btn-label"]').text("Save");
            saveBtn.removeClass('disabled');
        }, 2000);
    };

    this.after('initialize', function () {
        this.machineId = $(this.node).data('machine-id');
        this.catalogId = $(this.node).data('catalog-id');
        this.on('click', {
            saveMarkupsButton: this.saveMarkups,
            deleteMarkupRowButton: this.deleteMarkupRow,
            addMarkupRowButton: this.addMarkupRow
        });
    });
}
