import mixam from '../../../boot/mixam';
import bindIcons from '../../constants/binding-icons';
import moment from 'moment';

export default function WithArticles() {

    this.error = function (err) {
        if (console) {
            console.log(err);
        }
    };

   this.deliveryIcons = {
       PALLETS: "mx-pallet",
       PARCELS: "mx-dpd"
   };

    this.orientationIcons = [
        "mx-neil-portrait",
        "mx-neil-landscape"
    ];

    this.calcDaysBehind = function (dueDate) {
        const dispatchDate = moment.utc(dueDate);
        const now = moment.tz(new Date(), mixam.shop.timeZone).startOf("day").utc();
        let daysDifference = Math.ceil(moment.duration(now.diff(dispatchDate)).asDays());
        return daysDifference > 0 ? daysDifference : 0;
    };

    this.getThumbUrl = function (item) {
        var map = item.map,
            page = map && map.pages[0],
            side = page && page.side ? "-" + page.side : "";

        if (page && page.file) {
            return {
                thumb: item.thumbnailUrl,
                title: item.name,
                orientation: map.orientation
            };
        }
        return {};
    };

    this.normalizeItem = function (article, index) {
        const result = $.extend(true, {}, article);
        const {thumb, title, orientation} = this.getThumbUrl(article.item);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.override.td.status = "status-" + article.status;

        if (thumb) {
            result.front = {
                src: thumb,
                href: `/orders/${article.orderId}/artwork`,
                title: title,
                target: "_blank"
            };
            result.override.td.front = "orient-" + orientation;
        }

        if (thumb) {
            result.thumb = {
                src: thumb,
                href: '#',
                title: title,
                target: "_blank"
            };
            result.override.td.front = "orient-" + orientation;
        }

        result.deliveryMethodIcon = this.deliveryIcons.PARCELS;

        result.href = {
            href: `/orders/${article.orderId}/artwork`,
            caption: article.orderCaseNumber.toString() + (article.index ? "/" + article.index : ""),
            title: title,
            target: '_blank'
        };

        result.case =  article.orderCaseNumber.toString() + (article.index ? "/" + article.index : "");

        const deliveries = [];
        article.item.fulfillment.distributionDetails.forEach((details, index) => {
            if (details.dispatchKey) {
                deliveries.push({
                    caption: `Delivery #${index + 1}`,
                    href: `/spedition/${details.dispatchKey}`
                });
            }
        });
        if (deliveries.length !== 0) {
            result.deliveries = deliveries; // TODO: [MX-511] update!
        }

        result.delayCount = 0;
        result.pages = article.item.query.pages || 0;
        
        if (article.delays && article.delays.length) {
            result.finalDate = article.delays[article.delays.length - 1].date;
            result.delayCount = article.delays.length;
        }

        if (article.item.query.productId === 1 || article.item.query.productId === 6 || article.item.query.productId === 7 || article.item.query.productId === 15) {
            result.bind = bindIcons[article.item.query.bind || 0];
        }

        result.copies = article.item.query.copies || 0;
        result.size = 'A' + article.item.query.format;
        if (typeof article.item.query.orientation !== 'undefined') {
            result.orientation = this.orientationIcons[article.item.query.orientation];
        }

        result.isDigital = article.item.response.printType === "DIGITAL";
        result.isInkjet = article.item.response.printType === "INKJET";
        result.isLitho = article.item.response.printType === "LITHO";
        result.isWideFormat = article.item.response.printType === "WIDE_FORMAT";
        result.isExpress = article.item.fulfillment.express;
        result.total = article.item.fulfillment.cost;

        result.daysBehind = article.status === "LABELED" ? 0 : this.calcDaysBehind( result.finalDate || article.dateDispatch);
        result.totalDaysBehindDispatchDate = article.status === "LABELED" ? 0 : this.calcDaysBehind(article.dateDispatch);

        if (result.daysBehind) {
            result.override.td.daysBehind = "behind";
        }
        result.supplierName = article.item.fulfillment.supplierName;

        return result;
    };


    this.onStatusChangeCommand = function (event, data) {
        $.post(`/admin/api/article/${data.articleId}/update`, data).
            done((response) => true);
        //console.log('onCommand', event, data);
    };

    this.onDateChangeCommand = function (event, data) {
        $.post(`/admin/api/article/${data.articleId}/date`, data).
            done((response) => true);
        //console.log('onCommand', event, data);
    };


    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel articlesallmessages"], title: "WithArticles.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "WithArticles.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/articlesallmessages", d => {
                const p = JSON.parse(d.body);
                if (onMessage) {
                    onMessage(p);
                }
            });

        }, (err) =>  this.trigger("log", err), '/');

    };

    this.updateArticleLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const article = data.data;
        if (article.shopId !== mixam.shop.id) {
            return;
        }
        const index = this.data.list.map(x => x.id).indexOf(article.id);
        if (article.status === "LABELED" || article.status === "COLLECTED" || article.status === "DELIVERED") {
            verb = "DELETE";
        }

        if (verb === "UPDATE") {
            if (index !== -1) {
                this.data.list[index] = article;
            } else {
                this.data.list.unshift(article);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }
        // console.log('updateArticleLine', data);
        this.paint();
        requestAnimationFrame(() => $("#PK" + article.id).hilightTableLine());
    };

}
