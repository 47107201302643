import defineComponent from '../../../../../components/flight/lib/component';
import DataTable from '../../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import OrderStatus from "../../../constants/orderStatus";
import {CELL_TYPES} from "../../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminDiscountCodesReport);

function AdminDiscountCodesReport() {

    this.attributes({
        reportUrl: `/admin/api/discount-codes/usage`,
        dataTableSelector: '[data-type="data-table"]',
        fromDateSelector: '[data-type="date-from"]',
        toDateSelector: '[data-type="date-to"]',
        dataLoaderSelector: '[data-type="data-loader"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Status",
            type: CELL_TYPES.BADGE,
            data: "statusBadge"
        });

        cols.push({
            title: "Discount Code",
            type: "text",
            data: "discountCode"
        });

        cols.push({
            title: "Discount Code Name",
            type: "text",
            data: "discountCodeName"
        });

        cols.push({
            title: "Date Applied",
            type: "date",
            data: "dateApplied"
        });

        cols.push({
            title: "Order Total",
            type: "number",
            data: "orderTotal",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Total Discount",
            type: "number",
            data: "totalDiscount",
            decimal: 2,
            sum: true,
            currency: true,
        });

        cols.push({
            title: "Discounted Items Count",
            type: "number",
            data: "discountedItemsCount",
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };


    this.getData = function () {

        const fromDate = this.select('fromDateSelector').val();
        const toDate = this.select('toDateSelector').val();
        const discountCodeReport = this;

        discountCodeReport.disableDateSelectors();

        axios.get(this.attr.reportUrl, {params: {fromDate: fromDate, toDate: toDate}})
            .then((response) => {
                this.data = response.data;
                this.paint();
                discountCodeReport.resetDateSelectors();
            }).catch(error => {
                alert(`Failed To Retrieve Data: ${error}`);
                discountCodeReport.resetDateSelectors();
            });
    };

    this.disableDateSelectors = function () {
        this.select('fromDateSelector').addClass('disabled');
        this.select('toDateSelector').addClass('disabled');
        this.select('fromDateSelector').prop( "disabled", true);
        this.select('toDateSelector').prop( "disabled", true);
        this.select('dataLoaderSelector').removeClass('d-none');
    };

    this.resetDateSelectors = function () {
        this.select('fromDateSelector').removeClass('disabled');
        this.select('toDateSelector').removeClass('disabled');
        this.select('fromDateSelector').prop( "disabled", false);
        this.select('toDateSelector').prop( "disabled", false);
        this.select('dataLoaderSelector').addClass('d-none');
    };

    this.normalize = function (list) {
        return list.map(item => this.normalizeItem(item));
    };

    this.normalizeItem = function (item) {
        const result = $.extend(true, {}, item);
        result.discountCode = result.orderDiscount.discountCode;
        result.discountCodeName = result.orderDiscount.name;
        result.dateApplied = result.orderDiscount.dateApplied;
        result.discountedItemsCount = result.itemDiscounts.length;
        result.href = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber,
            title: result.caseNumber,
            target: '_blank'
        };
        result.statusBadge = {
            label: result.orderStatus,
            colour: OrderStatus.getBadgeColour(result.orderStatusInt)
        };
        return result;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        setTimeout(() => this.getData(), 10);

        this.on('change', {
            fromDateSelector: this.getData,
            toDateSelector: this.getData
        });
    });
}
