var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BootstrapConfig } from "@react-awesome-query-builder/bootstrap";
import AwesomeQueryBuilderHelper from "../../AwesomeQueryBuilder/AwesomeQueryBuilderHelper";
var InitialConfig = BootstrapConfig;
var PoliciesConfig = {
    getConfig: function (configData) {
        var config = __assign(__assign({}, InitialConfig), { fields: {
                dimensions: {
                    label: "Dimensions",
                    type: "!struct",
                    subfields: {
                        width: {
                            label: "Width",
                            type: 'number',
                        },
                        height: {
                            label: "Height",
                            type: 'number',
                        }
                    },
                },
                longEdge: {
                    label: "Long Edge",
                    type: "number"
                },
                shortEdge: {
                    label: "Short Edge",
                    type: "number"
                },
                itemSpecification: AwesomeQueryBuilderHelper.getItemSpecificationConfig(configData)
            } });
        return config;
    }
};
export default PoliciesConfig;
