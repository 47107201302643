import React, { useEffect, useState } from 'react';
export var OrderAdminRemarks = function (_a) {
    var initialCustomerRemark = _a.initialCustomerRemark;
    var _b = useState(initialCustomerRemark), customerRemark = _b[0], setCustomerRemark = _b[1];
    useEffect(function () {
        var handleSetData = function (e) {
            setCustomerRemark(e.detail);
        };
        window.addEventListener('orderAdminOwnerRemarkUpdated', handleSetData);
        return function () {
            window.removeEventListener('orderAdminOwnerRemarkUpdated', handleSetData);
        };
    }, []);
    return (React.createElement(React.Fragment, null, customerRemark &&
        React.createElement("div", { className: "alert alert-info", role: "alert" },
            React.createElement("h5", null,
                React.createElement("i", { className: "fa fa-comment" }),
                " Customer Remark"),
            React.createElement("div", { style: { whiteSpace: "pre-line" } }, customerRemark))));
};
