import React, { useEffect, useState } from 'react';
export var OrderInfoCard = function (_a) {
    var statusTitle = _a.statusTitle, statusSubtitle = _a.statusSubtitle, statusLabel = _a.statusLabel, orderStatus = _a.orderStatus, date = _a.date, name = _a.name, phone = _a.phone, email = _a.email, qrcode = _a.qrcode, locale = _a.locale, timeZone = _a.timeZone;
    var _b = useState({ name: name, phone: phone, email: email }), contact = _b[0], setContact = _b[1];
    useEffect(function () {
        var handleSetOrderData = function (e) {
            setContact(e.detail.data.contact);
        };
        window.addEventListener('setOrderData', handleSetOrderData);
        return function () {
            window.removeEventListener('setOrderData', handleSetOrderData);
        };
    }, []);
    var formattedTime = date ? new Date(date).toLocaleString(locale, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timeZone,
        hour12: false
    }) : '';
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: statusSubtitle ? 'stat-pending' : '' },
            statusTitle,
            statusSubtitle && React.createElement("div", { className: "order-future" }, statusSubtitle)),
        contact.name && React.createElement("h2", null, contact.name),
        contact.phone && React.createElement("div", null, contact.phone),
        contact.email && React.createElement("div", { className: "contact-email" }, contact.email),
        date && React.createElement("div", null, formattedTime),
        orderStatus && statusLabel && (React.createElement("div", null,
            "Status: ",
            React.createElement("strong", null, statusLabel))),
        qrcode && (React.createElement("div", { className: "qrcode-holder" },
            React.createElement("img", { className: "qrcode", src: qrcode, alt: "QR Code" })))));
};
