import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';

import postStatus from "../admin/postjob-status";
import {CELL_TYPES} from "../table/data-table-net/DataTableConstants.ts";

export default defineComponent(SiteflowApiJobManager);

function SiteflowApiJobManager() {

    this.attributes({
        url: "/api/admin/supplier/jobs/siteflow",
        dataTableSelector: '[data-type="data-table"]'
    });


    this.createSchema = function () {
        const cols = [];


        cols.push({
            title: '',
            type: CELL_TYPES.THUMBNAIL,
            data:"front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data:"href"
        });

        cols.push({
            title: "Created",
            type: "datetime",
            data:"dateCreated"
        });

        cols.push({
            title: "Type",
            type: "text",
            data:"type"
        });

        cols.push({
            title: "Status",
            type: "text",
            data:"status"
        });

        cols.push({
            title: "SourceAccountId",
            type: "text",
            width: "15%",
            data:"sourceAccountId"
        });

        cols.push({
            title: "Url",
            type: "link",
            data:"onefloeUrl"
        });

        cols.push({
            title: "Error",
            type: "text",
            width: "20%",
            data:"error"
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeMessage(x));
    };

    this.normalizeMessage = function (normalizeMessage) {
        const result = $.extend(true, {}, normalizeMessage);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.front = {
            src: result.thumbnailUrl,
            href: `/orders/${result.orderId}/artwork`,
            title: result.caseNumber,
            target: "_blank"
        };
        result.override.td.front = "orient-" + result.thumbnailOrientaion;
        result.href = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber
        };
        if (result.url) {
            result.onefloeUrl = {
                href: result.url,
                caption: "Oneflow Url"
            };
        }
        result.status = postStatus[result.status];

        return result;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel alloneflowchanel"], title: "CxmlManager.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "CxmlManager.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/alloneflowchanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;
        if (message.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.list.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data.list[index] = message;
            } else {
                this.data.list.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}
