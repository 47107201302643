import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
/*
 * TODO: replace! (old DataTable)
 *
 * NOTE: this code is only attached to a DOM element in tasks.vm, which (at this point) is only in
 * use with PrintMx.
 */
import DataTable from './../table/data-table';
import WithNormalizeShoppingCartItems from './../with-normalize-shopping-items';
import WithCatalogPaperTypes from '../home/with-catalog-paper-types';
import {CELL_TYPES} from "../table/data-table-net/DataTableConstants.ts";

export default defineComponent(WithNormalizeShoppingCartItems, AdminCreditManager, WithCatalogPaperTypes);

const typeIcons = {
    ORDER_ANSWER_REQUEST: "fa fa-comment",
    CONTACT_ANSWER_REQUEST: "fa fa-envelope",
    CONTACT_ANSWER_REQUEST_CHAT: "fa fa-comments",
    ATTENTION_REQUEST: "fa fa-fire-extinguisher",
    FULFILMENT_REQUIRED: "fa fa-archive",
    QUERY_REQUEST_ANSWER: "fa fa-question-circle",
    DISPATCH_DECLINE: "fa fa-thumbs-down",
    DISPATCH_DELAY: "fa fa-calendar-o",
    REDISTIL_NOTIFICATION: "fa fa-glass",
    ARTWORK_ERROR: "fa fa-file-image-o",
    PAID_IDLE: "fa fa-money",
    QUOTE_FOLLOWUP: "fa fa-user-secret",
    SAMPLE_REQUEST: "fa fa-archive",
    PAYMENT_ERROR: "fa fa-money",
    INVOICE_ERROR: "fa fa-exclamation-triangle",
    API_ERROR: "fa mx-saxoprint",
    ORDER_RE_ROUT: "fa fa-handshake-o",
    HELPSCOUT_EVENT: "fa mx-helpscout",
    DELIVERY_EVENT: "fa fa-truck",
    RESELLER_REQUEST: "fa fa-bell",
    SUPPLIER_STILL: "fa fa-assistive-listening-systems",
};

const typeCaptions = {
    ORDER_ANSWER_REQUEST: "Order answer request",
    CONTACT_ANSWER_REQUEST: "Contact answer request",
    ATTENTION_REQUEST: "Attention request",
    FULFILMENT_REQUIRED: "Fulfilment required",
    QUERY_REQUEST_ANSWER: "Quote answer request",
    DISPATCH_DECLINE: "Fulfilment declaine",
    DISPATCH_DELAY: "Fulfilment delayed",
    REDISTIL_NOTIFICATION: "Redistil notifcation",
    ARTWORK_ERROR: "Artwork error",
    PAID_IDLE: "Payment idling",
    QUOTE_FOLLOWUP: "Quote folowup reminder",
    SAMPLE_REQUEST: "Samples request",
    PAYMENT_ERROR: "Payment error",
    INVOICE_ERROR: "Xero invoice error",
    API_ERROR: "Saxo API error",
    ORDER_RE_ROUT: "Order re-route",
    HELPSCOUT_EVENT: "Helpscout event",
    DELIVERY_EVENT: "Invalid Delivery",
    RESELLER_REQUEST: "Reseller request",
    SUPPLIER_STILL: "Supplier still",
};

function AdminCreditManager() {

    this.attributes({
        url: "/admin/api/tasks/list",
        refreshSelector: '[data-action="refresh-indicator"]',
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "From",
            type: "member",
            width: "21%",
            data:"member",
            "sortable": false
        });

        cols.push({
            title: "Message",
            type: "taskMessages",
            width: "27%",
            data:"messages",
            "sortable": false
        });

        cols.push({
            title: "Description",
            type: CELL_TYPES.ORDER_DESCRIPTION,
            width: "27%",
            data:"order",
            "sortable": false
        });

        cols.push({
            title: "Total",
            type: "number",
            decimal: 2,
            width: "13%",
            "prepend": mixam.shop.currency.prefix,
            "append": mixam.shop.currency.postfix,
            data:"total",
            "sortable": false
        });

        cols.push({
            title: "Time",
            type: "snooze",
            width: "12%",
            data:"snooze",
            "sortable": false
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeTask(x));
    };

    this.isMapNotEmpty = function (map) {
        if (map?.cover && map?.cover.some(p => p.file)) { // jshint ignore:line
            return true;
        }
        return map?.pages.some(p => p.file); // jshint ignore:line
    };

    this.normalizeTask = function (task) {
        const result = $.extend(true, {}, task);
        result.order = task.order || {
            caseNumber: 0,
            orderStatus: 0,
            isPaymentVerified: false,
            items: [],
            contact: {name: ""}
        };
        const order = task.order;
        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.override.tr = 'task-' + task.type;
        result.override.td.orderStatus = "label-" + (order ? order.orderStatus : 0);
        result.override.td.settled = "verify-" + (order ? order.isPaymentVerified : 0);

        result.from = task.messages && task.messages[0] && task.messages[0].from || 'N/A';
        result.snooze = true;

        result.typeIcon = {icon: typeIcons[task.type], title: typeCaptions[task.type]};

        if (order && order.items.length) {
            const {thumb, orientation} = this.getThumbUrl(order.items[0]);

            result.front = {
                src: thumb,
                "orientation": orientation,
                href: `/orders/${order.id}/artwork`,
                title: order.caseNumber,
                target: "_blank"
            };
            result.override.td.front = "orient-" + orientation;
            result.total = order.total || 0;
            result.remarks = order.remarks;
            result.orderStatus = order.orderStatus;
            result.isHasExpress = order.isHasExpress;
            result.settled = order.isPaymentSettled;

            result.artwork = [];
            if (result.order) {
                result.order.href = `/orders/${order.id}/artwork`;
                result.order.items.forEach((item, i) => {
                    const href = `/orders/${order.id}/artwork`;
                    const {thumb, orientation} = this.getThumbUrl(order.items[i]);

                    item.index = i + 1;
                    item.front = {
                        src: thumb,
                        "orientation": orientation,
                        href: href,
                        title: order.caseNumber,
                        target: "_blank"
                    };
                    item.santa = this.normalizeItems([item])[0].query;

                    const o = {};
                    /* jshint ignore:start */
                    if (item.map?.ready) {  // jshint ignore:line
                        o.MAP_READY = 1;
                    } else {
                        if (this.isMapNotEmpty(item.map)) {
                            o.MAP_HALF_READY = 1;
                        } else {
                            o.MAP_EMPTY = 1;
                        }
                    }
                    /* jshint ignore:end */
                    result.artwork.push(o);
                    return item;
                });
            }
        }
        return result;
    };

    this.getThumbUrl = function (item) {
        const map = item.map,
            page = map && map.pages[0],
            side = page && page.side ? "-" + page.side : "";

        if (page && page.file) {
            return {
                thumb: map.uri + "/" + page.file.replace(/\.pdf$/, "_thumb_" + page.page + side + ".jpg"),
                title: item.name,
                orientation: map.orientation
            };
        }
        return {};
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-tasks-list',
            "default-sort": null
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific order and a specific item
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {
            message: ["Subscribe to stomp channel tasksallmessages"],
            title: "AdminCreditManager.subscribe"
        });
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "AdminCreditManager.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/tasksallmessages", d => {
                const p = JSON.parse(d.body);
                if (onMessage) {
                    onMessage(p);
                }
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateTaskLine = function (data) {
        if (this.data) {
            const verb = data.type;
            const task = data.data;

            if (task.shopId === mixam.shop.id || (this.isMixamShop(task.shopId) && this.isMixamShop(mixam.shop.id))) {
                const index = this.data.list.map(x => x.id).indexOf(task.id);
                if (verb === "UPDATE") {
                    if (index !== -1) {
                        this.data.list[index] = task;
                    } else {
                        this.data.list.unshift(task);
                    }
                } else if (verb === "INSERT") {
                    this.data.list.unshift(task);
                } else if (verb === "DELETE" && index !== -1) {
                    this.data.list.splice(index, 1);
                }

                if (this.suspend) {
                    this.queue.push(task.id);
                } else {
                    this.paint();
                    requestAnimationFrame(() => $("#PK" + task.id).hilightTableLine());
                }
            }
        }
    };

    this.isMixamShop = function (shopId) {
        return shopId === "56b3c7235e1cc70ad83d3e0e" || shopId === "5a45088e697df11c10709c21";
    };

    this.playQueue = function () {
        const id = this.queue.shift();
        requestAnimationFrame(() => {
            $(`#PK${id}`).hilightTableLine();
            if (this.queue.length) {
                requestAnimationFrame(() => this.playQueue());
            }
        });
    };

    this.onScroll = function (event, data) {
        this.suspend = data.offset > 150;
        if (this.suspend) {
            this.select('refreshSelector').addClass('suspend');
        } else {
            this.select('refreshSelector').removeClass('suspend');
        }

        if (!this.suspend && this.queue.length) {
            this.paint();
            this.playQueue();
        }
    };

    this.after('initialize', function () {
        this.suspend = false;
        this.queue = [];
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.on(document, "uiPageScroll", this.onScroll);

        try {
            this.subscribe(this.updateTaskLine.bind(this));
        } catch (e) {

        }
    });
}
