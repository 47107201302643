var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Formik, Field } from 'formik';
import FormikTableModal from '../FormikTableModal';
import { setupInitialValues } from '../util';
import { submit } from '../api';
import mixam from "../../../../../boot/mixam.js";
import { initDefaultModalFooterButtons } from "../FormikTableModal/FormikTableModal";
var CustomerCreditForm = function (_a) {
    var line = _a.line;
    // @ts-ignore
    var isAllowed = mixam.user.hasRole('ROLE_CREDIT_CONTROL');
    return (React.createElement(Formik, { initialValues: setupInitialValues({
            type: 'type',
            status: 'status',
            amount: 'amount',
            expiryDate: { data: 'expiryDate', type: 'date' },
            internalNotes: 'internalNotes',
        }, line), onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, submit("/admin/api/customer-credits/".concat(line.id, "/update"), values)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); } }, function (_a) {
        var setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
        var modalFooterButtons = initDefaultModalFooterButtons(isAllowed, submitForm);
        return (React.createElement(FormikTableModal, { headerText: "Credit Note Details", footerButtons: modalFooterButtons },
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "type" }, "Credit Type"),
                React.createElement(Field, { className: "form-select", as: "select", name: "type", disabled: !isAllowed, onChange: function (e) {
                        setFieldValue('type', e.target.value);
                    } },
                    React.createElement("option", { value: "COMPENSATION" }, "Compensation"),
                    React.createElement("option", { value: "GOODWILL_GESTURE" }, "Goodwill Gesture"),
                    React.createElement("option", { value: "MARKETING" }, "Marketing"),
                    React.createElement("option", { value: "ORDER_TRANSFER" }, "Order Transfer"))),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "status" }, "Status"),
                React.createElement(Field, { className: "form-select", as: "select", name: "status", disabled: !isAllowed, onChange: function (e) {
                        setFieldValue('status', e.target.value);
                    } },
                    React.createElement("option", { value: "PENDING" }, "Pending"),
                    React.createElement("option", { value: "APPROVED" }, "Approved"),
                    React.createElement("option", { value: "CANCELLED" }, "Cancelled"),
                    React.createElement("option", { value: "EXPIRED" }, "Expired"))),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "amount" }, "Credit Amount"),
                React.createElement(Field, { className: "form-control", type: "number", name: "amount", min: "0", disabled: !isAllowed })),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "expiryDate" }, "Expiry Date"),
                React.createElement(Field, { className: "form-control", type: "date", name: "expiryDate", disabled: !isAllowed })),
            React.createElement("div", { className: "form-group mb-3" },
                React.createElement("label", { htmlFor: "internalNotes" }, "Internal Notes"),
                React.createElement(Field, { type: "text", as: "textarea", name: "internalNotes", disabled: !isAllowed, className: "form-control", rows: "3" }))));
    }));
};
export default CustomerCreditForm;
