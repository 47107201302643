import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import WithNormalizeShoppingCartItems from '../with-normalize-shopping-items';
import WithNormalizeDispatch from '../with-normalize-dispatch';
import Mustache from '../../../components/mustache/mustache';
import itemsDisplayTemplate from 'text!../../../appes6/templates/admin/case-items-display.mustache';
import memberDisplayTemplate from 'text!../../../appes6/templates/admin/case-member-display.mustache';
import singleItemTemplate from 'text!../../../appes6/templates/admin/case-item-display.mustache';
import WithNormalizeMap from '../with-normalize-map';
import WithFolderUrl from '../with-get-folder-url';
import WithCatalogPaperTypes from '../home/with-catalog-paper-types';

import regional from '../../boot/regional';

export default defineComponent(AdminItems, WithNormalizeShoppingCartItems, WithNormalizeMap, WithNormalizeDispatch, WithFolderUrl, WithCatalogPaperTypes);

function AdminItems() {

    this.attributes({
        centersSelector: '[data-type="centers"]',
        centerIdSelector: '[data-type="center-id"]',
        displaySelector: '.items-search-content',
        inputSelector: '.search-box input',
        deliverySelector: '[data-type="delivery"]',
        deliveryIdSelector: '[data-type="delivery-id"]'
    });

    this.render = function () {
        this.model.centerId = this.centerId;

        this.model.order.deliveries // TODO: [MX-511] update!
            .find(delivery => delivery.id === this.model.deliveryId)
            .selected = true;

        return Mustache.render(itemsDisplayTemplate, this.model, {
            singleItemTemplate: singleItemTemplate,
            "memberDisplayTemplate": memberDisplayTemplate
        });
    };

    this.onInput = function (event) {
        const val = this.select('inputSelector').val();

        if (val && val.length >= 5 && !isNaN(val)) {
            this.case = val;
            this.loadOrder();
        }
    };

    this.loadOrder = function (deliveryId) {
        const url = deliveryId ? `/admin/api/cases/${this.case}/${deliveryId}` : `/admin/api/cases/${this.case}`;

        this.$node.addClass("loading");
        if (this.orderTimer) {
            clearTimeout(this.orderTimer);
        }
        this.orderTimer = setTimeout(() => $.getJSON(url).
            done(response => this.setData(response)).
            fail(response =>  this.setError(response)), 100);
    };

    this.setError = function (error) {
        this.orderTimer = null;
        this.$node.removeClass("loading");
        this.trigger("log", {message: error});
        this.trigger('showFixedMessage', {message: regional().messages[1036]});
        this.select('displaySelector').html("");
    };

    this.setData = function (data) {
        this.orderTimer = null;
        this.model = this.normalizeDispatch(data);
        this.model.currency = mixam.shop.currency;
        this.model.deliveryId = data.delivery.id;
        this.paint();
        this.$node.removeClass("loading");
    };

    this.paint = function() {
        if (this.centerId && this.model.suppliers[0].centers) {
            this.model.suppliers[0].centers.map(c => {
                c.selected = null;
                return c;
            }).
                filter(c => c.centerId === this.centerId).
                forEach(c => c.selected = true);
        }

        this.select('displaySelector').html(this.render());

        const $deliverySelect = this.select('deliverySelector');
        $deliverySelect.closest('.select-delivery').removeClass('hidden');
        $deliverySelect.cdDropdown();
        this.on("dropdownChange", this.changeDelivery);

        this.select('inputSelector').focus().val(this.select('inputSelector').val());

        // Activate the matching delivery
        this.$node.find(`[data-delivery-id="${this.model.deliveryId}"]`).addClass('active');

        // Set the deliveryId on the form
        this.select('deliveryIdSelector').val(this.model.deliveryId);

    };

    this.onPopstate = function () {
        const caseNumber = location.pathname.split('/').pop();

        if (caseNumber) {
            this.select('inputSelector').val(caseNumber);
            this.onInput(null);
        }
    };

    this.afterShowItem = function(event) {
        const $target = $(event.target),
            $value = $target.find('[data-type="value"]'),
            $copies = $target.find('.shipment-copies'),
            $button = $(`[data-target=#${$target.attr('id')}]`),
            $h = $button.parent().find('h3');

        $value.data('value', 1);
        $value.val($value.data('item'));
        $copies.prop('disabled', false);
        $button.toggleClass('morphed');
        $h.toggleClass('active');
        const activeItems = this.$node.find('.close-btn.morphed');
        if (activeItems.length < 2) {
            activeItems.prop('disabled', true);
        } else {
            activeItems.prop('disabled', false);
        }
    };

    this.afterHideItem = function(event) {
        const $target = $(event.target),
            $value = $target.find('[data-type="value"]'),
            $copies = $target.find('.shipment-copies'),
            $button = $(`[data-target=#${$target.attr('id')}]`),
            $h = $button.parent().find('h3'),
            $supplierElement = $target.closest('.supplier-list-item');

        let activeItems = $supplierElement.find('[data-type="value"][data-value="1"]'),
            isLast = activeItems.length < 2;

        if (isLast) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            $value.data('value', 0);
            $value.val("");
            $copies.prop('disabled', true);
            $button.toggleClass('morphed');
            $h.toggleClass('active');
        }

        activeItems = $supplierElement.find('.close-btn.morphed');
        if (activeItems.length < 2) {
            activeItems.prop('disabled', true);
        } else {
            activeItems.prop('disabled', false);
        }
    };

    this.centerChange = function (/*event*/) {
        this.centerId = this.select('centersSelector').val();
        this.select('centerIdSelector').val(this.centerId);
        this.getServices(this.model.order, this.model.delivery, this.model.suppliers[0]);
    };

    this.changeDelivery = function (event) {
        const deliveryId = $(event.target).data('value');
        this.loadOrder(deliveryId);
    };

    this.after('initialize', function () {
        this.on("input", {
            inputSelector: this.onInput
        });
        this.on("show.bs.collapse", this.afterShowItem);
        this.on("hide.bs.collapse", this.afterHideItem);
        window.addEventListener('popstate', this.onPopstate.bind(this), false);
        this.on("change", {
            centersSelector: this.centerChange
        });

        this.onInput();
    });
}
