import defineComponent from '../../../components/flight/lib/component';
import DataTable from './../table/data-table-net/data-table-net.tsx';
import mixam from "../../boot/mixam";
import WithSignDataRequest from "../with-sign-data-request";
import regional from "../../boot/regional";
import OrderStatus from "../constants/orderStatus";
import axios from "axios";
import {CELL_TYPES} from "../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminDeliveryManager, WithSignDataRequest);

function AdminDeliveryManager() {

    this.attributes({
        url: `${mixam.reporterOrigin}/reporter/admin/api/shops/${mixam.shop.id}/delivery-groups`,
        fromDateSelector: '[data-type="date-from"]',
        toDateSelector: '[data-type="date-to"]',
        updateDatesSelector: '[data-type="update-dates"]',
        updateDatesSpinnerSelector: '[data-type="update-dates-spinner"]',
        updateDatesTextSelector: '[data-type="update-dates-text"]',
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: "Order",
            type: "link",
            data: "order",
            width: "5rem"
        });
        cols.push({
            title: "Delivery ID",
            type: "text",
            data: "deliveryId",
            width: "10rem"
        });
        cols.push({
            title: "Modified",
            type: CELL_TYPES.TIMEBOX,
            data: "lastModifiedDate",
            width: "5rem"
        });
        cols.push({
            title: "Status",
            type: CELL_TYPES.BADGE,
            data: "statusBadge",
            width: "10rem"
        });
        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: "8rem"
        });
        cols.push({
            title: "Name",
            type: "text",
            data: "contactName",
            width: "8rem"
        });
        cols.push({
            title: "Delivery Address",
            type: "text",
            data: "deliveryAddressText",
            width: "10rem"
        });
        cols.push({
            title: "Weight",
            type: "text",
            data: "weightText",
            width: "5rem"
        });
        cols.push({
            title: "Service",
            type: "text",
            data: "carrierServiceText",
            width: "10rem"
        });
        cols.push({
            title: "Customer Quote",
            type: "number",
            data: "deliveryQuote",
            width: "8rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconcile",
            type: CELL_TYPES.DELIVERY_RECONCILIATION,
            data: "reconcile",
            sortable: false,
            width: "10rem"
        });
        cols.push({
            title: "Reconciled Remarks",
            type: "text",
            data: "reconciledRemark",
            width: "10rem"
        });
        cols.push({
            title: "Reconciled Total",
            type: "number",
            data: "reconciledTotal",
            width: "8rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Delivery",
            type: "number",
            data: "reconciledDelivery",
            width: "10rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Fuel",
            type: "number",
            data: "reconciledFuel",
            width: "8rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Third Party Billing",
            type: "number",
            data: "reconciledThirdPartyBilling",
            width: "14rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Extra Party Collection",
            type: "number",
            data: "reconciledExtraPartyCollection",
            width: "14rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Congestion",
            type: "number",
            data: "reconciledCongestion",
            width: "12rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Carriage",
            type: "number",
            data: "reconciledCarriage",
            width: "10rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled No Comms Handling",
            type: "number",
            data: "reconciledNoCommsHandling",
            width: "14rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Insurance",
            type: "number",
            data: "reconciledInsurance",
            width: "10rem",
            decimal: 2,
            currency: true
        });
        cols.push({
            title: "Reconciled Other Text",
            type: "text",
            data: "reconciledOtherText",
            width: "10rem"
        });
        cols.push({
            title: "Reconciled Other",
            type: "number",
            data: "reconciledOther",
            width: "10rem",
            decimal: 2,
            currency: true
        });

        return cols;
    };

    this.getData = function () {
        requestAnimationFrame(() => this.signDataRequest(token => this.getReportData(token)));
    };

    this.getReportData = function (token) {

        this.disableDateSelectors();

        const $fromDate = this.select('fromDateSelector');
        const $toDate = this.select('toDateSelector');
        const fromDate = $fromDate.val();
        const toDate = $toDate.val();

        axios.get('/admin/api/finance/delivery-charge-types')
            .then(chargeTypes => {
                    this.chargeTypes = chargeTypes.data;

                    axios.get(`${this.attr.url}?fromDate=${fromDate}&toDate=${toDate}`, { headers: {"Authorization" : `Bearer ${token}`} })
                        .then(deliveryGroups => {
                            this.deliveryGroups = deliveryGroups.data;
                            this.paint();
                            this.resetDateSelectors();
                        });
                }
            );
    };

    this.paint = function () {
        const tableOptions = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normaliseDeliveryGroups(this.deliveryGroups),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: tableOptions }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", tableOptions);
        }
    };

    this.normaliseDeliveryGroups = function(list) {
        const data = [];
        list.forEach(deliveryGroup => {
            const element = $.extend(true, {}, deliveryGroup);
            element.order = {
                caption: deliveryGroup.caseNumber,
                href: `/orders/${deliveryGroup.orderId}/artwork`,
                title: deliveryGroup.orderId,
                target: "_blank"
            };
            element.statusBadge = {
                label: deliveryGroup.orderStatus,
                colour: OrderStatus.getBadgeColour(deliveryGroup.orderStatusInt)
            };
            element.supplier = deliveryGroup.supplierName;
            element.contactName = deliveryGroup.contact.name;
            element.weightText = `${deliveryGroup.weight.toFixed(2)}${regional().weightSymbol}`;
            element.deliveryQuote = deliveryGroup.total;
            let label = 'Reconcile';
            let reconciledBool = false;
            if (element.reconciliation.entries.length > 0) {
                label = 'Reconciled';
                reconciledBool = true;
            }
            element.reconcile = {
                label,
                chargeTypes: this.chargeTypes,
                previousEntries: deliveryGroup.reconciliation ? deliveryGroup.reconciliation.entries : [],
                reconciledBool
            };

            //Create Reconcile Elements
            if (element.reconciliation.entries) {
              this.normaliseReconciliationEntries(element);
            }
            data.push(element);
        });
        return data;
    };

    this.normaliseReconciliationEntries = function (element) {
        let reconciliationRemarks = [];
        let reconciliationTotal = 0;
        let reconciliationDelivery = 0;
        let reconciliationResidentialDelivery = 0;
        let reconciliationFuel = 0;
        let reconciliationThirdPartyBilling = 0;
        let reconciliationExtraPartyCollection = 0;
        let reconciliationCongestion = 0;
        let reconciliationCarriage = 0;
        let reconciliationNonCommsHandling = 0;
        let reconciliationInsurance = 0;
        let reconciliationOther = 0;
        let reconciliationOtherText = [];

        const entriesArr = element.reconciliation.entries;
        for (const entry of entriesArr) {
            if (entry.total) {
                reconciliationTotal = reconciliationTotal + entry.total;
            }
            reconciliationRemarks.push(entry.remark);

            const chargesArr = entry.charges;
            for (const charge of chargesArr) {

                // Switch to populate column
                switch(charge.type) {
                    case "DELIVERY":
                        reconciliationDelivery = reconciliationDelivery + charge.sum;
                        break;
                    case "RESIDENTIAL_DELIVERY":
                        reconciliationResidentialDelivery = reconciliationResidentialDelivery + charge.sum;
                        break;
                    case "FUEL":
                        reconciliationFuel = reconciliationFuel + charge.sum;
                        break;
                    case "THIRD_PARTY_BILLING":
                        reconciliationThirdPartyBilling = reconciliationThirdPartyBilling + charge.sum;
                        break;
                    case "EXTRA_PARTY_COLLECTION":
                        reconciliationExtraPartyCollection = reconciliationExtraPartyCollection + charge.sum;
                        break;
                    case "CONGESTION":
                        reconciliationCongestion = reconciliationCongestion + charge.sum;
                        break;
                    case "CARRIAGE":
                        reconciliationCarriage = reconciliationCarriage + charge.sum;
                        break;
                    case "NON_COMS_HANDLING":
                        reconciliationNonCommsHandling = reconciliationNonCommsHandling + charge.sum;
                        break;
                    case "INSURANCE":
                        reconciliationInsurance = reconciliationInsurance + charge.sum;
                        break;
                    case "OTHER":
                        reconciliationOther = reconciliationOther + charge.sum;
                        reconciliationOtherText.push(charge.textValue);
                        break;
                    default:
                        break;
                }
            }
        }

        // Assign accumulator values to table elements
        element.reconciledRemark = reconciliationRemarks.join(', ');
        element.reconciledTotal = reconciliationTotal;
        element.reconciledDelivery = reconciliationDelivery;
        element.reconciledResidentialDelivery = reconciliationResidentialDelivery;
        element.reconciledFuel = reconciliationFuel;
        element.reconciledThirdPartyBilling = reconciliationThirdPartyBilling;
        element.reconciledExtraPartyCollection = reconciliationExtraPartyCollection;
        element.reconciledCongestion = reconciliationCongestion;
        element.reconciledCarriage = reconciliationCarriage;
        element.reconciledNoCommsHandling = reconciliationNonCommsHandling;
        element.reconciledInsurance = reconciliationInsurance;
        element.reconciledOtherText = reconciliationOtherText.join(', ');
        element.reconciledOther = reconciliationOther;
    };

    this.disableDateSelectors = function () {
        this.select('fromDateSelector').addClass('disabled');
        this.select('toDateSelector').addClass('disabled');
        this.select('fromDateSelector').prop('disabled', true);
        this.select('toDateSelector').prop('disabled', true);
        this.select('updateDatesSpinnerSelector').removeClass('d-none');
        this.select('updateDatesTextSelector').addClass('d-none');
        this.select('dataLoaderSelector').removeClass('d-none');
    };

    this.resetDateSelectors = function () {
        this.select('fromDateSelector').removeClass('disabled');
        this.select('toDateSelector').removeClass('disabled');
        this.select('fromDateSelector').prop('disabled', false);
        this.select('toDateSelector').prop('disabled', false);
        this.select('updateDatesSpinnerSelector').addClass('d-none');
        this.select('updateDatesTextSelector').removeClass('d-none');
        this.select('dataLoaderSelector').addClass('d-none');
    };

    this.updateDates = function () {
        this.signDataRequest(token => this.getReportData(token));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        this.on('click', {
            updateDatesSelector: this.updateDates
        });
        window.addEventListener('deliveryReconciliationUpdated', () => this.getData());
        setTimeout(() => this.getData(), 10);
    });
}