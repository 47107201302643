import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import invoiceStatus from './invoice-status';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';
import axios from "axios";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";


export default defineComponent(InvoicesList);

function InvoicesList() {


    this.attributes({
        url: "/admin/api/invoices/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        const canViewInvoices = mixam.user.hasRole('ROLE_MIXAM');

        cols.push({
            title: '',
            type: CELL_TYPES.THUMBNAIL,
            hide: Breakpoints.MD,
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Name",
            type: "text",
            data: "name"
        });

        cols.push({
            title: "Created",
            type: CELL_TYPES.TIMEBOX,
            data: "dateCreated"
        });

        if(canViewInvoices) {
            cols.push({
                title: "Invoice",
                type: "link",
                data: "invoiceLink"
            });

            cols.push({
                title: "Creditnote",
                type: "link",
                data: "creditLink"
            });
        } else {
            cols.push({
                title: "Invoice",
                type: "text",
                data: "invoiceId"
            });

            cols.push({
                title: "Creditnote",
                type: "text",
                data: "creditNoteId"
            });
        }

        cols.push({
            title: "Charge",
            type: "number",
            decimal: 2,
            data: "debit",
            sum: true,
            currency: true,
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "Credit",
            type: "number",
            decimal: 2,
            data: "credit",
            sum: true,
            currency: true,
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Refund",
            type: "number",
            decimal: 2,
            data: "refund",
            sum: true,
            currency: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Error",
            type: "text",
            width: "150px",
            data: "error",
            hide: Breakpoints.SM,
        });

        cols.push({
            title: "Invoice Status",
            type: "text",
            data: "invoiceStatus",
            hide: Breakpoints.SM,
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            hide: Breakpoints.SM,
        });

        cols.push({
            title: "Action",
            type: CELL_TYPES.INVOICE_JOB_REQUEUE,
            data: "requeueAction",
            hide: Breakpoints.SM,
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeInvoice(x));
    };

    this.normalizeInvoice = function (invoice_) {
        const invoice = $.extend(true, {}, invoice_);
        if (invoice.thumbnailUrl) {
            invoice.front = {
                src: invoice.thumbnailUrl,
                href: `/orders/${invoice.orderId}/artwork`,
                title: "",
                target: "_blank"
            };
        }
        invoice.override = invoice.override || {};
        invoice.override.td = invoice.override.td || {};
        invoice.override.td.front = "orient-" + invoice.thumbnailOrientaion;
        invoice.href = {
            href: `/orders/${invoice.orderId}/artwork`,
            caption: 'order'
        };
        if (invoice.caseNumber) {
            invoice.href = {
                href: `/orders/${invoice.orderId}/artwork`,
                caption: `${invoice.caseNumber}`,
                title: ""
            };
        }
        invoice.status = invoiceStatus[invoice.status];
        invoice.invoiceLink = {
            href:  invoice.invoiceUrl,
            caption: invoice.invoiceId,
            target: "_blank"
        };
        invoice.creditLink = {
            href:  invoice.creditNoteUrl,
            caption: invoice.creditNoteId,
            target: "_blank"
        };
        invoice.requeueAction = {
            jobId: invoice.id,
            status: invoice.status
        };
        return invoice;
    };


    this.getData = function () {
        axios.get(this.attr.url)
            .then((response) => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .catch((error) => {
                console.log("Error Retrieving Invoice Data");
            });
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allinvoicechanel"], title: "InvoicesList.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "InvoicesList.subscribe"});
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allinvoicechanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateInvoicesLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const invoice = data.data;
        if (invoice.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.map(x => x.id).indexOf(invoice.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = invoice;
            } else {
                this.data.unshift(invoice);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.splice(index, 1);
        }

        this.paint();
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateInvoicesLine(d));
    });
}
