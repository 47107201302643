var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button, Form as BsForm, Table } from "react-bootstrap";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ConfirmationModal from "./ConfirmationModal";
import { MakeReadyType } from '../types';
import { EditEquipment } from "./EditEquipment";
import mixam from '../../../../../boot/mixam.js';
var defaultValues = {
    name: '',
    effectiveHourlyCost: 0.0,
    purchaseCost: 0,
    usefulLifeMonths: 0,
    monthlyRuntimeHours: 0,
    maintenanceRatio: 0.0,
    makeReadyTimeMinutes: 0,
    labourCosts: [],
    active: true,
    makeReadyType: MakeReadyType.PER_FORM,
    colours: 0,
    platesCostPerColour: 0,
    sheetsPerHour: 0,
};
var OffsetPressesRow = function (_a) {
    var entry = _a.entry, configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(false), showDeleteModal = _c[0], setShowDeleteModal = _c[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    var doDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/offset-presses/").concat(entry.id);
                    return [4 /*yield*/, axios.delete(url)
                            .then(function () {
                            doGetConfiguration();
                            setShowDeleteModal(false);
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Offset Press Removed', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Remove Offset Press: ".concat(error.response.data.error), interval: 30000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex" }, entry.name)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" },
                    mixam.shop.currency.prefix,
                    entry.effectiveHourlyCost.toFixed(2))),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.makeReadyType)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.makeReadyTimeMinutes)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.colours)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.platesCostPerColour)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-center" }, entry.sheetSizes ? entry.sheetSizes.map(function (element) { return React.createElement(React.Fragment, { key: "".concat(element.width, "x").concat(element.height) }, "".concat(element.width, "x").concat(element.height),
                    React.createElement("br", null)); }) : React.createElement("span", null, "ALL"))),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-end" }, entry.sheetsPerHour)),
            React.createElement("td", { className: "align-middle" },
                React.createElement("div", { className: "d-flex justify-content-center" }, entry.active ? 'YES' : 'NO')),
            React.createElement("td", { className: "text-nowrap" },
                React.createElement(Button, { variant: "danger", type: "button", onClick: function () { return setShowDeleteModal(true); } },
                    React.createElement("i", { className: "bi bi-trash" })),
                React.createElement(Button, { className: "ms-2", type: "button", onClick: handleShowModal },
                    React.createElement("i", { className: "bi bi-pencil-square" })))),
        showModal &&
            React.createElement(OffsetPressModal, { offsetPress: entry, showModal: showModal, handleClose: handleCloseModal, configuration: configuration, doGetConfiguration: doGetConfiguration }),
        React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: doDelete, closeButtonText: 'Close', content: 'Are you sure you want to delete this press?', saveButtonText: "Delete", heading: "Delete Press" })));
};
var OffsetPressModal = function (_a) {
    var offsetPress = _a.offsetPress, showModal = _a.showModal, handleClose = _a.handleClose, configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(offsetPress), entry = _b[0], setEntry = _b[1];
    var doCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/offset-presses");
                    return [4 /*yield*/, axios.post(url, entry)
                            .then(function () {
                            doGetConfiguration();
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Offset Press Created', interval: 15000 } } }));
                        }).catch(function (error) {
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Create Offset Press: ".concat(error.response.data.error), interval: 15000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var doUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/api/admin/tco/configurations/".concat(configuration.machineName, "/offset-presses/").concat(entry.id);
                    return [4 /*yield*/, axios.put(url, entry)
                            .then(function () {
                            doGetConfiguration();
                            handleClose();
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'success', body: 'Offset Press Updated', interval: 15000 } } }));
                        }).catch(function (error) {
                            window.dispatchEvent(new CustomEvent('showFixedMessage', { detail: { message: { type: 'error', body: "Failed to Update Offset Press: ".concat(error.response.data.error), interval: 15000 } } }));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSave = function (e) {
        e.preventDefault();
        !!entry.id ? doUpdate() : doCreate();
    };
    var handleOnChangeSheetSizesAll = function (e) {
        if (e.target.checked) {
            var updatedEntry = __assign({}, entry);
            delete updatedEntry.sheetSizes;
            setEntry(updatedEntry);
        }
    };
    var handleOnChangeSheetSizes = function (e) {
        var dimensions = {
            width: Number(e.target.getAttribute('data-width')),
            height: Number(e.target.getAttribute('data-height')),
            sizeFormat: configuration.unitFormat
        };
        if (e.target.checked) {
            // Add the size (if necessary)...
            if (!entry.sheetSizes || !entry.sheetSizes.some(function (size) { return size.width === dimensions.width && size.height === dimensions.height; })) {
                var updatedSheetSizes = entry.sheetSizes ? __spreadArray([], entry.sheetSizes, true) : [];
                updatedSheetSizes.push(dimensions);
                setEntry(__assign(__assign({}, entry), { sheetSizes: updatedSheetSizes }));
            }
        }
        else {
            // Remove the size (if necessary)...
            var updatedSheetSizes = entry.sheetSizes.filter(function (size) { return size.width !== dimensions.width || size.height !== dimensions.height; });
            var updatedEntry = __assign(__assign({}, entry), { sheetSizes: updatedSheetSizes });
            if (updatedSheetSizes.length === 0) {
                // Remove the entire property if that was the last one...
                delete updatedEntry.sheetSizes;
            }
            setEntry(updatedEntry);
        }
    };
    return (React.createElement(Modal, { show: showModal, onHide: handleClose, size: "lg" },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, "Add Offset Press")),
        React.createElement(BsForm, { onSubmit: handleSave },
            React.createElement(Modal.Body, null,
                React.createElement(EditEquipment, { equipment: entry, setEquipment: function (equipment) { return setEntry(equipment); } }),
                React.createElement("div", { className: "card p-2 d-grid gap-2", style: { gridTemplateColumns: '1fr 1fr' } },
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "colours", className: "form-label" },
                            "Colours (",
                            React.createElement("i", null, "e.g."),
                            " 4 or 8)"),
                        React.createElement("input", { id: "colours", name: "colours", type: "number", className: "form-control", value: entry.colours, step: "1", onChange: function (e) { return setEntry(__assign(__assign({}, entry), { colours: Number(e.target.value) })); } })),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "platesCostPerColour", className: "form-label" }, "Plates Cost / Colour"),
                        React.createElement("input", { id: "platesCostPerColour", name: "platesCostPerColour", type: "number", className: "form-control", value: entry.platesCostPerColour, step: "0.0001", onChange: function (e) { return setEntry(__assign(__assign({}, entry), { platesCostPerColour: Number(e.target.value) })); } })),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "sheetsPerHour", className: "form-label" }, "Sheets / Hour"),
                        React.createElement("input", { id: "sheetsPerHour", name: "sheetsPerHour", type: "number", className: "form-control", value: entry.sheetsPerHour, step: "1", onChange: function (e) { return setEntry(__assign(__assign({}, entry), { sheetsPerHour: Number(e.target.value) })); } })),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { htmlFor: "makeReadyType", className: "form-label" }, "Make Ready Type"),
                        React.createElement("select", { name: "makeReadyType", className: "form-select", defaultValue: entry.makeReadyType, onChange: function (event) { return setEntry(__assign(__assign({}, entry), { makeReadyType: MakeReadyType[event.target.value] })); } }, Object.keys(MakeReadyType).map(function (makeReadyType) {
                            return (React.createElement("option", { key: makeReadyType, value: makeReadyType }, makeReadyType));
                        }))),
                    React.createElement("div", { className: "mb-4" },
                        React.createElement("label", { className: "form-label" }, "Sheet Sizes"),
                        React.createElement("div", { className: "p-2 d-grid", style: { gridTemplateColumns: '1fr 1fr' } },
                            React.createElement("div", null,
                                React.createElement("input", { id: "sheetSizesAll", name: "sheetSizesAll", type: "checkbox", className: "form-check-input", value: "", checked: !(entry === null || entry === void 0 ? void 0 : entry.sheetSizes), disabled: !(entry === null || entry === void 0 ? void 0 : entry.sheetSizes), onChange: handleOnChangeSheetSizesAll }),
                                React.createElement("label", { htmlFor: "sheetSizesAll", className: "form-label ms-2" }, "All")),
                            (configuration === null || configuration === void 0 ? void 0 : configuration.pressSheets) && configuration.pressSheets.map(function (pressSheet) {
                                var sizeId = "".concat(pressSheet.width, "x").concat(pressSheet.height);
                                return (React.createElement("div", { key: "sheetSizes".concat(sizeId) },
                                    React.createElement("input", { id: "sheetSizes".concat(sizeId), name: "sheetSizes".concat(sizeId), type: "checkbox", className: "form-check-input", value: sizeId, "data-width": pressSheet.width, "data-height": pressSheet.height, checked: !!entry.sheetSizes && entry.sheetSizes.some(function (value) { return "".concat(value.width, "x").concat(value.height) === sizeId; }), onChange: handleOnChangeSheetSizes }),
                                    React.createElement("label", { htmlFor: "sheetSizes".concat(sizeId), className: "form-label ms-2" }, "".concat(pressSheet.width, " x ").concat(pressSheet.height))));
                            }))))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: handleClose }, "Close"),
                React.createElement(Button, { variant: "primary", type: "submit" }, "Save")))));
};
export function OffsetPresses(_a) {
    var configuration = _a.configuration, doGetConfiguration = _a.doGetConfiguration;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var handleCloseModal = function () { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-column" },
            React.createElement("h4", null, "Offset Presses"),
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Effective Hourly Cost"),
                        React.createElement("th", null, "Make Ready Type"),
                        React.createElement("th", null, "Make Ready Time (min)"),
                        React.createElement("th", null, "Colours"),
                        React.createElement("th", null, "Plates Cost / Colour"),
                        React.createElement("th", null, "Sheets Sizes"),
                        React.createElement("th", null, "Sheets / Hour"),
                        React.createElement("th", null, "Active"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, configuration && configuration.availableEquipment.offsetPresses.map(function (offsetPress) { return (React.createElement(OffsetPressesRow, { key: offsetPress.id, entry: offsetPress, configuration: configuration, doGetConfiguration: doGetConfiguration })); }))),
            configuration && configuration.availableEquipment.offsetPresses.length === 0 && (React.createElement("p", null, "No data.")),
            React.createElement("div", { className: "d-flex flex-row gap-2 justify-content-end" },
                React.createElement("button", { type: "button", className: "align-self-end btn btn-primary", onClick: handleShowModal }, "+ Add Offset Press")),
            showModal &&
                React.createElement(OffsetPressModal, { offsetPress: JSON.parse(JSON.stringify(defaultValues)), showModal: showModal, handleClose: handleCloseModal, configuration: configuration, doGetConfiguration: doGetConfiguration }))));
}
