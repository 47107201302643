export var MakeReadyType;
(function (MakeReadyType) {
    MakeReadyType["PER_COMPONENT"] = "PER_COMPONENT";
    MakeReadyType["PER_FORM"] = "PER_FORM";
})(MakeReadyType || (MakeReadyType = {}));
export var ThroughputUnit;
(function (ThroughputUnit) {
    ThroughputUnit["COPIES_PER_HOUR"] = "COPIES_PER_HOUR";
    ThroughputUnit["SHEETS_PER_HOUR"] = "SHEETS_PER_HOUR";
})(ThroughputUnit || (ThroughputUnit = {}));
export var BinderType;
(function (BinderType) {
    BinderType["STAPLED"] = "STAPLED";
    BinderType["PUR"] = "PUR";
    BinderType["WIRO"] = "WIRO";
    // CALENDAR_WIRO = 'CALENDAR_WIRO', // NOTE: not yet!
    BinderType["COIL"] = "COIL";
    // LAYFLAT = 'LAYFLAT',             // NOTE: special!
    BinderType["MAKE_CASE"] = "MAKE_CASE";
    BinderType["CASING_IN"] = "CASING_IN";
})(BinderType || (BinderType = {}));
export var Capability;
(function (Capability) {
    Capability["PRINTING"] = "PRINTING";
    Capability["LAMINATION"] = "LAMINATION";
    Capability["BINDING"] = "BINDING";
    Capability["FOLDING"] = "FOLDING";
    Capability["HOLE_PUNCHING"] = "HOLE_PUNCHING";
    Capability["CRIMPING"] = "CRIMPING";
})(Capability || (Capability = {}));
export var PrintType;
(function (PrintType) {
    PrintType["LITHO"] = "LITHO";
    PrintType["DIGITAL"] = "DIGITAL";
})(PrintType || (PrintType = {}));
/*
 * There's a similar enum in mixam-types, but it uses strings
 * (where the "real" Java type serializes to numbers).
 */
export var UnitFormat;
(function (UnitFormat) {
    UnitFormat[UnitFormat["METRIC"] = 0] = "METRIC";
    UnitFormat[UnitFormat["IMPERIAL"] = 1] = "IMPERIAL";
})(UnitFormat || (UnitFormat = {}));
