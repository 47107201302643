import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import moment from "moment";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminPublisherSales);

function AdminPublisherSales() {

    this.attributes({
        publisherSalesAggregateUrl: '/admin/print-on-demand/publisher/sales/list',
        aggregateDataUrl: '/admin/print-on-demand/publisher/sales/aggregate',
        aggregateDataBtn: '[data-type="publisher-sales-aggregation-btn"]',
        dataTableSelector: '[data-type="data-table"]',
        aggregateYearSelect: '[data-type="aggregate-year"]',
        aggregateMonthSelect: '[data-type="aggregate-month"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Publisher",
            type: "link",
            data: "publisherLink"
        });

        cols.push({
            title: "Publisher Email",
            type: "text",
            data: "email"
        });

        cols.push({
            title: "Groups",
            type: "list",
            data: "customerGroupsList",
            width: '150px'
        });

        cols.push({
            title: "Stripe Connect Account",
            type: "text",
            data: "stripeConnectAccount"
        });

        cols.push({
            title: "Year",
            type: "number",
            data: "year",
        });

        cols.push({
            title: "Month",
            type: "number",
            data: "month"
        });

        cols.push({
            title: "Sales Qty",
            type: "number",
            data: "itemCount"
        });

        cols.push({
            title: "Copies Total",
            type: "number",
            data: "copiesTotal"
        });

        cols.push({
            title: "Total Sales",
            type: "number",
            data: "total",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Total Cost",
            type: "number",
            data: "ntotal",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Margin",
            type: "number",
            data: "value",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Commission Due",
            type: "number",
            data: "commission",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Commission Transferred",
            type: "number",
            data: "commissionTransferred",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled"
        });

        cols.push({
            title: "Action",
            type: CELL_TYPES.PUBLISHER_SETTLEMENT_MODAL,
            data: "settled",
            sortable: false
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getAggregateData = function (event) {
        const year = Number(this.select('aggregateYearSelect').val());
        const month = Number(this.select('aggregateMonthSelect').val());

        axios.post(this.attr.publisherSalesAggregateUrl, {year: year, month: month})
            .then((response) => {
                this.data = response.data;
                this.paint();
            }).catch(error => {
                alert(`Failed To Aggregate Data: ${error}`);
            });
    };

    this.aggregateData = function (event) {

        this.initAggregateDataBtn();

        axios.post(this.attr.aggregateDataUrl)
            .then(() => {
                this.resetAggregateDataBtn();
                this.getAggregateData();
            }).catch(error => {
                alert(`Failed To Aggregate Data: ${error}`);
                this.resetAggregateDataBtn();
            });
    };

    this.initAggregateDataBtn = function(event) {
        let aggregateDataBtn = this.select('aggregateDataBtn');
        setTimeout( function() {
            aggregateDataBtn.addClass('disabled');
            aggregateDataBtn.find('[data-type="spinner"]').removeClass('d-none');
            aggregateDataBtn.find('[data-type="btn-label"]').text("Processing");
        }, 1);
    };

    this.resetAggregateDataBtn = function(event) {
        let aggregateDataBtn = this.select('aggregateDataBtn');
        setTimeout( function() {
            aggregateDataBtn.find('[data-type="spinner"]').addClass('d-none');
            aggregateDataBtn.find('[data-type="btn-label"]').text("Aggregate Data");
            aggregateDataBtn.removeClass('disabled');
        }, 2000);
    };

    this.normalize = function (list) {
        return list.map(item => this.normalizeItem(item));
    };

    this.normalizeItem = function (item) {
        const result = $.extend(true, {}, item);
        result.itemCount = item.items.length;
        result.publisherLink = {
            href: `/member/${item.publisher.id}`,
            title: item.publisher.name,
            caption: item.publisher.name,
            target: "_blank"
        };
        result.stripeConnectAccount = item.publisher.stripeConnectAccountId ? item.publisher.stripeConnectAccountId : '';
        result.notes = result.notes ? result.notes : '';
        result.settlementDate = result.settlementDate ? moment(result.settlementDate).format(moment.HTML5_FMT.DATE) : moment(new Date()).format(moment.HTML5_FMT.DATE);
        result.email = result.publisher.email ? result.publisher.email : '';
        result.customerGroupsList = item.publisher.customerGroupReferences ?
            item.publisher.customerGroupReferences.map(customerGroup => customerGroup.name) :
            [];
        return result;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        setTimeout(() => this.getAggregateData(), 10);

        this.on('click', {
            aggregateDataBtn: this.aggregateData
        });

        this.on('change', {
            aggregateYearSelect: this.getAggregateData,
            aggregateMonthSelect: this.getAggregateData
        });
    });
}
