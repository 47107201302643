import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import moment from "moment";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(PublishingHouseSales);

function PublishingHouseSales() {

    this.attributes({
        salesAggregateUrl: '/admin/print-on-demand/publishing-house/sales/list',
        dataTableSelector: '[data-type="data-table"]',
        aggregateYearSelect: '[data-type="aggregate-year"]',
        aggregateMonthSelect: '[data-type="aggregate-month"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "House",
            type: "string",
            data: "publishingHouseName"
        });

        cols.push({
            title: "Year",
            type: "number",
            data: "year",
        });

        cols.push({
            title: "Month",
            type: "number",
            data: "month"
        });

        cols.push({
            title: "Sales Qty",
            type: "number",
            data: "itemCount"
        });

        cols.push({
            title: "Copies Total",
            type: "number",
            data: "copiesTotal"
        });

        cols.push({
            title: "Total Sales",
            type: "number",
            data: "total",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Total Cost",
            type: "number",
            data: "ntotal",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Margin",
            type: "number",
            data: "value",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Commission Due",
            type: "number",
            data: "commission",
            decimal: 2,
            sum: true,
            currency: true
        });

        cols.push({
            title: "Settled",
            type: "boolean",
            data: "settled"
        });

        cols.push({
            title: "Action",
            type: CELL_TYPES.PUBLISHING_HOUSE_SETTLEMENT_MODAL,
            data: "settled",
            sortable: false
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getAggregateData = function (event) {
        const year = Number(this.select('aggregateYearSelect').val());
        const month = Number(this.select('aggregateMonthSelect').val());

        axios.post(this.attr.salesAggregateUrl, {year: year, month: month})
            .then((response) => {
                this.data = response.data;
                this.paint();
            }).catch(error => {
                alert(`Failed To Aggregate Data: ${error}`);
            });
    };

    this.normalize = function (list) {
        return list.map(aggregation => this.normalizeItem(aggregation));
    };

    this.normalizeItem = function (aggregation) {
        const result = $.extend(true, {}, aggregation);
        result.publishingHouseName = aggregation.publishingHouse.publishingHouseName;
        result.itemCount = aggregation.items.length;
        result.notes = result.notes ? result.notes : '';
        result.settlementDate = result.settlementDate ? moment(result.settlementDate).format(moment.HTML5_FMT.DATE) : moment(new Date()).format(moment.HTML5_FMT.DATE);
        return result;
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        setTimeout(() => this.getAggregateData(), 10);

        this.on('change', {
            aggregateYearSelect: this.getAggregateData,
            aggregateMonthSelect: this.getAggregateData
        });
    });
}
