import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';

import postStatus from "../../admin/postjob-status";
import postTypes from "../../admin/postjob-types";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(BluetreeJobsManager);

function BluetreeJobsManager() {

    this.attributes({
        url: "/api/admin/supplier/jobs/bluetree",
        dataTableSelector: '[data-type="data-table"]'
    });


    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: '',
            type: CELL_TYPES.THUMBNAIL,
            width: "4%",
            data: "front"
        });
        cols.push({
            title: "Order",
            type: "link",
            width: "9%",
            data: "href"
        });

        cols.push({
            title: "Created",
            type: "datetime",
            width: "10%",
            data: "dateCreated",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Status",
            type: "text",
            width: "10%",
            data: "status"
        });

        cols.push({
            title: "Bluetree Order ID",
            type: "text",
            width: "10%",
            data: "bluetreeOrderNumber"
        });

        cols.push({
            title: "Bluetree Order Status",
            type: "text",
            width: "10%",
            data: "orderStatus"
        });

        cols.push({
            title: "Error",
            type: "text",
            width: "30%",
            data: "error"
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizePost(x));
    };

    this.normalizePost = function (apiPost) {
        const result = $.extend(true, {}, apiPost);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.front = {
            src: result.thumbnailUrl,
            href: `/orders/${result.orderId}/artwork`,
            title: result.caseNumber,
            target: "_blank"
        };
        result.override.td.front = "orient-" + result.thumbnailOrientaion;
        result.href = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber
        };
        result.status = postStatus[result.status];
        result.type = postTypes[result.type];
        return result;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
