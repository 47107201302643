import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '.././../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import ResellerGroup from "../../constants/reseller-groups";
import { Breakpoints } from "../../table/data-table-net/StyledDataTableNet.tsx";
import mixam from "../../../boot/mixam";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdminMembersLocator, WithOrdersItemsMembers);

const UNKNOWN_AVATAR = 'https://s3-eu-west-1.amazonaws.com/static3.mixam.com/members/unnamed.png';

function AdminMembersLocator() {

    this.attributes({
        url: '/admin/api/members/locate',
        emailAddressInputSelector: '[data-type="email-address-search-input"]',
        nameInputSelector: '[data-type="name-search-input"]',
        surnameInputSelector: '[data-type="surname-search-input"]',
        dataTableSelector: '[data-type="data-table"]',
        searchBtnSelector: '[data-type="search-btn"]'
    });

    this.createSchema = function () {
        let cols = [];

        cols.push({
            title: "",
            type: "image",
            data: "photo",
            sortable: false,
        });
        cols.push({
            title: "Name",
            type: "link",
            data: "name"
        });
        cols.push({
            title: "Email",
            type: "link",
            data: "email",
            width: "13rem"
        });
        cols.push({
            title: "Company",
            type: "text",
            data: "company"
        });
        cols.push({
            title: "Role",
            type: CELL_TYPES.ROLES,
            data: "roles"
        });
        cols.push({
            title: "Created",
            type: "date",
            data: "createdDate",
            hide: Breakpoints.MD
        });
        cols.push({
            title: "Modified",
            type: CELL_TYPES.TIMEBOX,
            data: "modifiedDate",
            hide: Breakpoints.MD
        });
        cols.push({
            title: "Seen",
            type: CELL_TYPES.TIMEBOX,
            data: "seenDate",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Groups",
            type: "list",
            data: "groupsList",
            hide: Breakpoints.LG
        });
        cols.push({
            title: "Order #",
            type: "number",
            data: "confirmedOrderCount",
            sum: true,
            hide: Breakpoints.XL
        });

        cols.push({
            title: `Order ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "purchasesCount",
            sum: true,
            currency: true,
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Last Order Date",
            type: CELL_TYPES.TIMEBOX,
            data: "lastOrderDate",
            hide: Breakpoints.XL
        });

        cols.push({
            title: `Credit Limit ${mixam.shop.currency.prefix}`,
            type: "number",
            data: "obligo",
            currency: true,
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Reseller",
            type: "text",
            data: "confirmedReseller",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "",
            type: "multiButton",
            data: "actions"
        });

        return cols;
    };

    this.normalize = function (list) {
        return list.map(member => this.normalizeMember(member));
    };

    this.normalizeMember = function (member) {
        //const result = $.extend(true, {}, item);
        member.actions = [
            {
                href: `member/${member.id}/edit`,
                caption: "Edit",
                className: "btn btn-primary",
                iconClassname: "bi bi-pencil",
                key: "edit",
                target: "_self"
            }
        ];
        /** @namespace member.fullcontactInfo */
        if (!member.photo && member.fullcontactInfo && member.fullcontactInfo.photos.length) {
            member.photo = (member.fullcontactInfo.photos.filter(x => x.isPrimary)[0] || {}).s3url;
        }
        let confirmedOrderCount = 0;
        let purchasesCount = 0;
        /** @namespace member.eternalAccumulator */
        if (member.eternalAccumulator) {
            confirmedOrderCount = member.eternalAccumulator.count;
            purchasesCount = member.eternalAccumulator.total;
        }

        /** @namespace member.lastName */
        return {
            id: member.id,
            obligo: member.obligo || 0,
            photo: {
                href: `/member/${member.id}`,
                src: member.photo || UNKNOWN_AVATAR,
                caption:  (`${member.firstName} ${member.lastName}`).trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            name: {
                href: `/member/${member.id}`,
                caption:   (`${member.firstName} ${member.lastName}`).trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            email: {
                href: `/member/${member.id}`,
                caption:   (member.email || "").trim(),
                title:  `${member.firstName} ${member.lastName}`
            },
            company: member.billingAddress && member.billingAddress.company,
            roles:  member.role.split(" ").map(role => {
                return {role: role.toLowerCase().substr(5).capitalize()};
            }),
            shop: this.shops[member.shopId] && this.shops[member.shopId].icon,
            createdDate: member.dateCreated,
            modifiedDate: member.lastModifiedDate,
            seenDate: member.lastSigninDate,
            groupsList: member.groups.map(customerGroup => customerGroup.name),
            lastOrderDate: confirmedOrderCount > 0 ? member.dateLatestOrder : '',
            purchasesCount: purchasesCount,
            confirmedOrderCount: confirmedOrderCount,
            confirmedReseller: member.reseller ? ResellerGroup[member.reseller] : "",
            actions: member.actions
        };
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: "Search",
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "lastModifiedDate": -1
            }
        };

        this.select('dataTableSelector').show();

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.doSearch = function (event) {
        const emailAddress = (this.select('emailAddressInputSelector').val() || "").trim();
        const name = (this.select('nameInputSelector').val() || "").trim();
        const surname = (this.select('surnameInputSelector').val() || "").trim();
        if (emailAddress || name || surname) {
            this.select('searchBtnSelector').attr('data-loading', '*').attr('disabled', true);
            $.post(this.attr.url, {emailAddress, name, surname}, null, 'json').done((response) => {
                this.select('searchBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.handleResponse(response);
            }).fail(response => {
                this.select('searchBtnSelector').removeAttr('data-loading').attr('disabled', false);
                this.trigger("log", response);
            });
        }
    };

    this.handleResponse = function (list) {
        this.data = list;
        if (list.length) {
            this.paint();
        }
    };

    this.doKeyUp = function (event) {
        if (event.key === 'Enter') {
            this.doSearch(event);
        }
    };

    this.after('initialize', function () {

        DataTable.attachTo(this.select('dataTableSelector'));

        this.on('click', {
            searchBtnSelector: this.doSearch
        });

        this.on('keyup', this.doKeyUp);

        try {
            this.subscribe();
        } catch (e) {

        }
    });
}
