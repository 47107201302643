import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import OrderStatus from "../../constants/orderStatus";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(AdobeExpressOrderSummary);

function AdobeExpressOrderSummary() {

    this.attributes({
        url: "/admin/api/adobe-express/orders",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Order",
            type: "link",
            data: "orderLink"
        });

        cols.push({
            title: "Status",
            type: CELL_TYPES.BADGE,
            data: "statusBadge",
            width: '150px'
        });

        cols.push({
            title: "Created",
            type: CELL_TYPES.TIMEBOX,
            data: "createdDate",
            defaultSort: true,
            sortOrder: 'desc',
        });

        cols.push({
            title: "Adobe Express Artwork ID",
            type: "text",
            data: "adobeArtworkId",
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (adobeExpressOrderSummaries) {
        return adobeExpressOrderSummaries.map(this.normalizeOrderSummary);
    };

    this.normalizeOrderSummary = function (orderSummary) {
        const result = $.extend(true, {}, orderSummary);

        result.orderLink = {
            href: `/orders/${result.orderId}/artwork`,
            caption: result.caseNumber,
            target: "_blank"
        };

        result.statusBadge = {
            label: result.orderStatus,
            colour: OrderStatus.getBadgeColour(result.orderStatusInt)
        };

        return result;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                    this.data = response.data;
                    this.paint();
                }
            );
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
